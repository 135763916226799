
//react 
import React, {useEffect} from "react";
import {AdminAccountInfo, AdminApiFp} from "../client-axios";
import {Row} from "react-bootstrap";
import ThrottleButton from "../control/ThrottleButton";
import {CommonConfiguration} from "../common/CommonSetting";


//common


//local



interface Props {
}

const AdminCommonLogo = (props: Props) => {

    const url = "/admin/menu";
    const getMeAsync = async () =>{
        const conf = CommonConfiguration();
        const d = await AdminApiFp(conf).apiAdminMeGet();
        try {
            const ret = await d();
            if (ret.status === 200) {
                const x: AdminAccountInfo = ret.data;
            } else {
                window.location.href = "/timeout";
            }
        }
        catch(e){
            window.location.href = "/timeout";
        }
    }

    useEffect(() => {
        getMeAsync().then();
    }, [])

    const onSignOutClick = () => {
        window.location.href = "/admin/SignOut";
    }

    return(
        <>
            <header>
                <div className="container-xl topLogo">
                    <a href={url}>
                        <img className="logo" src="/logo.png" alt={"logo"} />
                    </a>
                    <Row className={"account"}>
                        <ThrottleButton className={"account"} onClick={onSignOutClick}>サインアウト</ThrottleButton>
                    </Row>
                </div>
            </header>
        </>
    );

}

export default (AdminCommonLogo);


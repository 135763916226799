//react
import React from "react";
import {useFormContext} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";


//common


//local
import {
    GetOrganizationInfo,


} from "../client-axios";
import RequiredImage from "./RequiredImage";


interface Props {
    value?: GetOrganizationInfo,
    readOnly: boolean,
}

const OrganizationBaseForms = (props: Props) => {
    const {register, watch, formState: {errors}} = useFormContext();

    const corpKindValue = watch("corpKind");


    return (
        <div>


            <Form.Group as={Row}>
                <Form.Label column md={5}>
                    法人番号<RequiredImage /><br/>
                        <span className="subLabel">(半角数字、13桁)</span>
                </Form.Label>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="法人番号" defaultValue={props.value?.corpNo}
                                  className={"form-control"}
                                  {...register("corpNo", {required: true, maxLength: 13, pattern: /^\d{13}$/})}
                                  isInvalid={!!errors.corpNo}/>
                    {errors.corpNo && errors.corpNo.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.corpNo && errors.corpNo.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は13桁までです。
                    </Form.Control.Feedback>
                    }
                    {errors.corpNo && errors.corpNo.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        半角数字、13桁で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md={5}>
                    商号（法人名）<RequiredImage /><br/>
                        <span className="subLabel">(全角、最大25文字)</span>
                </Form.Label>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="商号（法人名）"
                                  defaultValue={props.value?.corpNameKanji}
                                  className={"form-control"}
                                  {...register("corpNameKanji", {required: true, maxLength: 25, pattern: /^[^ -~]+$/})}
                                  isInvalid={!!errors.corpNameKanji}/>
                    {errors.corpNameKanji && errors.corpNameKanji.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.corpNameKanji && errors.corpNameKanji.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は25文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.corpNameKanji && errors.corpNameKanji.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md={5}>
                    商号（法人名）フリガナ<RequiredImage /><br/>
                        <span className="subLabel">(全角カナ、全角空白、全角ハイフン、全角長音、最大60文字)</span>
                </Form.Label>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="商号（法人名）フリガナ"
                                  defaultValue={props.value?.corpNameKana}
                                  className={"form-control"}
                                  {...register("corpNameKana", {required: true, maxLength: 60, pattern: /^[ァ-ヶ‐ー　]+$/})}
                                  isInvalid={!!errors.corpNameKana}/>
                    {errors.corpNameKana && errors.corpNameKana.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.corpNameKana && errors.corpNameKana.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は60文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.corpNameKana && errors.corpNameKana.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角カナ、全角空白、全角ハイフン、全角長音で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md={5}>
                    法人格<RequiredImage />
                </Form.Label>
                <Col md={6}>
                    <Form.Control disabled={props.readOnly} as="select" defaultValue={props.value?.corpKind}
                                  {...register("corpKind", {required: true})}
                                  isInvalid={!!errors.corpKind}>
                        <option value=""/>
                        <option id="B019_001" value="B019_001">株式会社</option>
                        <option id="B019_002" value="B019_002">有限会社</option>
                        <option id="B019_003" value="B019_003">合名会社</option>
                        <option id="B019_004" value="B019_004">合資会社</option>
                        <option id="B019_005" value="B019_005">相互会社</option>
                        <option id="B019_006" value="B019_006">特定目的会社</option>
                        <option id="B019_007" value="B019_007">合同会社</option>
                        <option id="B019_008" value="B019_008">信用金庫</option>
                        <option id="B019_009" value="B019_009">信用組合</option>
                        <option id="B019_010" value="B019_010">森林組合</option>
                        <option id="B019_011" value="B019_011">商工組合</option>
                        <option id="B019_012" value="B019_012">農業協同組合</option>
                        <option id="B019_013" value="B019_013">漁業協同組合</option>
                        <option id="B019_014" value="B019_014">生活協同組合</option>
                        <option id="B019_015" value="B019_015">消費生活協同組合</option>
                        <option id="B019_016" value="B019_016">協同組合</option>
                        <option id="B019_017" value="B019_017">工業組合</option>
                        <option id="B019_018" value="B019_018">企業組合</option>
                        <option id="B019_019" value="B019_019">協業組合</option>
                        <option id="B019_020" value="B019_020">医療法人社団</option>
                        <option id="B019_021" value="B019_021">医療法人財団</option>
                        <option id="B019_022" value="B019_022">医療法人</option>
                        <option id="B019_023" value="B019_023">社会医療法人</option>
                        <option id="B019_024" value="B019_024">財団法人</option>
                        <option id="B019_025" value="B019_025">社団法人</option>
                        <option id="B019_026" value="B019_026">宗教法人</option>
                        <option id="B019_027" value="B019_027">学校法人</option>
                        <option id="B019_028" value="B019_028">社会福祉法人</option>
                        <option id="B019_029" value="B019_029">一般社団法人</option>
                        <option id="B019_030" value="B019_030">公益社団法人</option>
                        <option id="B019_031" value="B019_031">一般財団法人</option>
                        <option id="B019_032" value="B019_032">公益財団法人</option>
                        <option id="B019_033" value="B019_033">農事組合法人</option>
                        <option id="B019_034" value="B019_034">監査法人</option>
                        <option id="B019_035" value="B019_035">有限責任中間法人</option>
                        <option id="B019_036" value="B019_036">無限責任中間法人</option>
                        <option id="B019_037" value="B019_037">税理士法人</option>
                        <option id="B019_038" value="B019_038">弁護士法人</option>
                        <option id="B019_039" value="B019_039">特定非営利活動法人</option>
                        <option id="B019_040" value="B019_040">ＮＰＯ法人</option>
                        <option id="B019_041" value="B019_041">信用金庫連合会</option>
                        <option id="B019_042" value="B019_042">信用組合連合会</option>
                        <option id="B019_043" value="B019_043">森林組合連合会</option>
                        <option id="B019_044" value="B019_044">商業組合連合会</option>
                        <option id="B019_045" value="B019_045">農業協同組合連合会</option>
                        <option id="B019_046" value="B019_046">漁業協同組合連合会</option>
                        <option id="B019_047" value="B019_047">生活協同組合連合会</option>
                        <option id="B019_048" value="B019_048">消費生活協同組合連合会</option>
                        <option id="B019_049" value="B019_049">協同組合連合会</option>
                        <option id="B019_050" value="B019_050">工業組合連合会</option>
                        <option id="B019_051" value="B019_051">環境衛生同業組合</option>
                        <option id="B019_052" value="B019_052">商店街振興組合</option>
                        <option id="B019_053" value="B019_053">商工会議所</option>
                        <option id="B019_054" value="B019_054">厚生年金基金</option>
                        <option id="B019_055" value="B019_055">企業年金基金</option>
                        <option id="B019_056" value="B019_056">健康保険組合</option>
                        <option id="B019_057" value="B019_057">インコーポレイテッド</option>
                        <option id="B019_058" value="B019_058">コーポレーション</option>
                        <option id="B019_059" value="B019_059">リミテッド</option>
                        <option id="B019_060" value="B019_060">カンパニー</option>
                        <option id="B019_061" value="B019_061">その他</option>

                    </Form.Control>
                    {errors.corpKind && errors.corpKind.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }

                </Col>
            </Form.Group>
            {((corpKindValue === undefined && props.value?.corpKind === "B019_061") || (corpKindValue === "B019_061")) &&

            <Form.Group as={Row}>
                <Form.Label column md={5}>
                    法人格がその他の場合の入力欄<RequiredImage /><br/>
                        <span className="subLabel">(最大15文字)</span>
                </Form.Label>
                <Col md={6}>
                    <Form.Control disabled={props.readOnly} required type="text" placeholder="" defaultValue={props.value?.corpKindEtc}
                                  className={"form-control"}
                                  {...register("corpKindEtc", {
                                      required: corpKindValue === "B019_061",
                                      maxLength: 15
                                  })}
                                  isInvalid={!!errors.corpKindEtc}/>
                    {errors.corpKindEtc && errors.corpKindEtc.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.corpKindEtc && errors.corpKindEtc.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は15文字までです。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            }

            <Form.Group as={Row}>
                <Form.Label column md={5}>
                    法人格前後区分<RequiredImage />
                </Form.Label>
                <Col md={1}>
                    {props.readOnly &&
                    <Form.Check disabled={props.readOnly && props.value?.corpKindBA !== "B020_001"} checked={props.value?.corpKindBA === "B020_001"}
                                type={"radio"} {...register("corpKindBA", {required: true})} label={"前"}
                                defaultChecked={props.value?.corpKindBA === "B020_001"} value={"B020_001"}/>}
                    {props.readOnly &&
                    <Form.Check disabled={props.readOnly && props.value?.corpKindBA !== "B020_002"} checked={props.value?.corpKindBA === "B020_002"} type={"radio"} {...register("corpKindBA", {required: true})} label={"後"}
                                defaultChecked={props.value?.corpKindBA === "B020_002"} value={"B020_002"}/>
                    }
                    {!props.readOnly &&
                    <Form.Check type={"radio"} {...register("corpKindBA", {required: true})} label={"前"}
                                defaultChecked={props.value?.corpKindBA === "B020_001"} value={"B020_001"}/>
                    }
                    {!props.readOnly &&
                    <Form.Check type={"radio"} {...register("corpKindBA", {required: true})} label={"後"}
                                defaultChecked={props.value?.corpKindBA === "B020_002"} value={"B020_002"}/>
                    }
                    {errors.corpKindBA && errors.corpKindBA.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>


            <Form.Group as={Row}>
                <Form.Label column md={5}>
                    本店又は主たる事務所の所在地
                </Form.Label>
            </Form.Group>


            <Form.Group as={Row}>
                <Form.Label column md={{span: 4, offset: 1}}>
                    郵便番号<RequiredImage /><br/>
                        <span className="subLabel">(半角数字、7桁 ハイフン無し)</span>
                </Form.Label>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="郵便番号" defaultValue={props.value?.corpYubin}
                                  className={"form-control"}
                                  {...register("corpYubin", {required: true, maxLength: 7, pattern: /\d{7}$/})}
                                  isInvalid={!!errors.corpYubin}/>
                    {errors.corpYubin && errors.corpYubin.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.corpYubin && errors.corpYubin.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は7桁までです。
                    </Form.Control.Feedback>
                    }
                    {errors.corpYubin && errors.corpYubin.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        半角数字、7桁で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md={{span: 10, offset: 1}}>
                        <span style={{fontSize:"smaller", color:"red"}}>※ 「都道府県」「市区町村」「番地以下」「それ以降の住所」は合わせて最大37文字で入力してください。</span>
                </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md={{span: 4, offset: 1}}>
                    都道府県<sup style={{color:"red"}}> ※</sup><RequiredImage />
                </Form.Label>
                <Col md={6}>
                    <Form.Control disabled={props.readOnly} required as="select" placeholder="都道府県" defaultValue={props.value?.corpPref}
                                  {...register("corpPref", {required: true, maxLength: 50})}
                                  isInvalid={!!errors.corpPref}>
                        <option value=""/>

                        <option value="北海道">北海道</option>
                        <option value="青森県">青森県</option>
                        <option value="岩手県">岩手県</option>
                        <option value="宮城県">宮城県</option>
                        <option value="秋田県">秋田県</option>
                        <option value="山形県">山形県</option>
                        <option value="福島県">福島県</option>
                        <option value="茨城県">茨城県</option>
                        <option value="栃木県">栃木県</option>
                        <option value="群馬県">群馬県</option>
                        <option value="埼玉県">埼玉県</option>
                        <option value="千葉県">千葉県</option>
                        <option value="東京都">東京都</option>
                        <option value="神奈川県">神奈川県</option>
                        <option value="新潟県">新潟県</option>
                        <option value="富山県">富山県</option>
                        <option value="石川県">石川県</option>
                        <option value="福井県">福井県</option>
                        <option value="山梨県">山梨県</option>
                        <option value="長野県">長野県</option>
                        <option value="岐阜県">岐阜県</option>
                        <option value="静岡県">静岡県</option>
                        <option value="愛知県">愛知県</option>
                        <option value="三重県">三重県</option>
                        <option value="滋賀県">滋賀県</option>
                        <option value="京都府">京都府</option>
                        <option value="大阪府">大阪府</option>
                        <option value="兵庫県">兵庫県</option>
                        <option value="奈良県">奈良県</option>
                        <option value="和歌山県">和歌山県</option>
                        <option value="鳥取県">鳥取県</option>
                        <option value="島根県">島根県</option>
                        <option value="岡山県">岡山県</option>
                        <option value="広島県">広島県</option>
                        <option value="山口県">山口県</option>
                        <option value="徳島県">徳島県</option>
                        <option value="香川県">香川県</option>
                        <option value="愛媛県">愛媛県</option>
                        <option value="高知県">高知県</option>
                        <option value="福岡県">福岡県</option>
                        <option value="佐賀県">佐賀県</option>
                        <option value="長崎県">長崎県</option>
                        <option value="熊本県">熊本県</option>
                        <option value="大分県">大分県</option>
                        <option value="宮崎県">宮崎県</option>
                        <option value="鹿児島県">鹿児島県</option>
                        <option value="沖縄県">沖縄県</option>
                    </Form.Control>
                    {errors.corpPref && errors.corpPref.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.corpPref && errors.corpPref.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は50文字までです。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>


            <Form.Group as={Row}>
                <Form.Label column md={{span: 4, offset: 1}}>
                    市区町村<sup style={{color:"red"}}> ※</sup>
                        <RequiredImage /><br/>
                        <span className="subLabel">(全角)</span>
                </Form.Label>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="市区町村"
                                  defaultValue={props.value?.corpShikuchouson}
                                  className={"form-control"}
                                  {...register("corpShikuchouson", {required: true, maxLength: 10, pattern: /^[^ -~]+$/})}
                                  isInvalid={!!errors.corpShikuchouson}/>
                    {errors.corpShikuchouson && errors.corpShikuchouson.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.corpShikuchouson && errors.corpShikuchouson.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は10文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.corpShikuchouson && errors.corpShikuchouson.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column md={{span: 4, offset: 1}}>
                    番地以下<sup style={{color:"red"}}> ※</sup>
                        <RequiredImage /><br/>
                        <span className="subLabel">(全角)</span>
                </Form.Label>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder=" 番地以下" defaultValue={props.value?.corpBanchi}
                                  className={"form-control"}
                                  {...register("corpBanchi", {required: true, maxLength: 37, pattern: /^[^ -~]+$/})}
                                  isInvalid={!!errors.corpBanchi}/>
                    {errors.corpBanchi && errors.corpBanchi.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.corpBanchi && errors.corpBanchi.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は37文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.corpBanchi && errors.corpBanchi.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column md={{span: 4, offset: 1}}>
                    それ以降の住所<sup style={{color:"red"}}> ※</sup>
                        <br/>
                        <span className="subLabel">(全角)</span>
                </Form.Label>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="それ以降の住所" defaultValue={props.value?.corpIkou}
                                  className={"form-control"}
                                  {...register("corpIkou", {required: false, maxLength: 37, pattern: /^[^ -~]+$/})}
                                  isInvalid={!!errors.corpIkou}/>
                    {errors.corpIkou && errors.corpIkou.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は37文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.corpIkou && errors.corpIkou.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} style={{alignItems: "center"}}>
                <Form.Label column md={{span: 4, offset: 1}}>
                    電話番号<RequiredImage /><br/>
                        <span className="subLabel">(半角数字、最大5桁 - 最大4桁 - 最大4桁)</span>
                </Form.Label>

                <Col xs={6} style={{display: "flex"}}>
                    <div>
                        <Form.Control readOnly={props.readOnly} required type="text" placeholder="" style={{width: 75}}
                                    defaultValue={props.value?.corpTelNo1}
                                    isInvalid={!!errors.corpTelNo1}
                                    {...register("corpTelNo1", {required: true, maxLength: 5, pattern: /^\d+$/})}
                        />
                        {errors.corpTelNo1 && errors.corpTelNo1.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.corpTelNo1 && errors.corpTelNo1.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.corpTelNo1 && errors.corpTelNo1.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </div>
                    <div style={{width: 105, textAlign: "center", marginTop: 5}}>
                        -
                    </div>
                    <div>
                        <Form.Control readOnly={props.readOnly} required type="text" placeholder="" style={{width: 75}}
                                    defaultValue={props.value?.corpTelNo2}
                                    isInvalid={!!errors.corpTelNo2}
                                    {...register("corpTelNo2", {required: true, maxLength: 4, pattern: /^\d+$/})}
                        />
                        {errors.corpTelNo2 && errors.corpTelNo2.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.corpTelNo2 && errors.corpTelNo2.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は4桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.corpTelNo2 && errors.corpTelNo2.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </div>
                    <div style={{width: 105, textAlign: "center", marginTop: 5}}>
                        -
                    </div>
                    <div>
                        <Form.Control readOnly={props.readOnly} required type="text" placeholder="" style={{width: 75}}
                                    defaultValue={props.value?.corpTelNo3}
                                    isInvalid={!!errors.corpTelNo3}
                                    {...register("corpTelNo3", {required: true, maxLength: 4, pattern: /^\d+$/})}
                        />
                        {errors.corpTelNo3 && errors.corpTelNo3.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.corpTelNo3 && errors.corpTelNo3.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は4桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.corpTelNo3 && errors.corpTelNo3.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </div>
                </Col>
            </Form.Group>


            <Form.Group as={Row}>
                <Col md={5}>
                    <Form.Label>
                        代表者
                    </Form.Label>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md={{span: 10, offset: 1}}>
                        <span style={{fontSize:"smaller", color:"red"}}>※ 「氏」「名」は合わせて最大12文字で入力してください。</span>
                </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        氏<sup style={{color:"red"}}> ※</sup><RequiredImage /><br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="氏"
                                  defaultValue={props.value?.representativeFamilyName}
                                  className={"form-control"}
                                  {...register("representativeFamilyName", {required: true, maxLength: 12, pattern: /^[^ -~]+$/})}
                                  isInvalid={!!errors.representativeFamilyName}/>
                    {errors.representativeFamilyName && errors.representativeFamilyName.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeFamilyName && errors.representativeFamilyName.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は12文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeFamilyName && errors.representativeFamilyName.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        名<sup style={{color:"red"}}> ※</sup><RequiredImage /><br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="名"
                                  defaultValue={props.value?.representativeFirstName}
                                  className={"form-control"}
                                  {...register("representativeFirstName", {required: true, maxLength: 12, pattern: /^[^ -~]+$/})}
                                  isInvalid={!!errors.representativeFirstName}/>
                    {errors.representativeFirstName && errors.representativeFirstName.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeFirstName && errors.representativeFirstName.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は12文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeFirstName && errors.representativeFirstName.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md={{span: 10, offset: 1}}>
                        <span style={{fontSize:"smaller", color:"red"}}>※ 「氏（カナ）」「名（カナ）」は合わせて最大24文字で入力してください。</span>
                </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        氏（カナ）<sup style={{color:"red"}}> ※</sup><RequiredImage /><br/>
                            <span className="subLabel">(全角カナ)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="氏（カナ）"
                                  defaultValue={props.value?.representativeFamilyNameKana}
                                  className={"form-control"}
                                  {...register("representativeFamilyNameKana", {required: true, maxLength: 24, pattern: /^[ァ-ヶ＋‐ー＃￥＆．，：＊０-９Ａ-Ｚａ-ｚ　]+$/})}
                                  isInvalid={!!errors.representativeFamilyNameKana}/>
                    {errors.representativeFamilyNameKana && errors.representativeFamilyNameKana.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeFamilyNameKana && errors.representativeFamilyNameKana.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は24文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeFamilyNameKana && errors.representativeFamilyNameKana.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角カナ、全角英数字で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        名（カナ）<sup style={{color:"red"}}> ※</sup><RequiredImage /><br/>
                            <span className="subLabel">(全角カナ)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="名（カナ）"
                                  defaultValue={props.value?.representativeFirstNameKana}
                                  className={"form-control"}
                                  {...register("representativeFirstNameKana", {required: true, maxLength: 24, pattern: /^[ァ-ヶ＋‐ー＃￥＆．，：＊０-９Ａ-Ｚａ-ｚ　]+$/})}
                                  isInvalid={!!errors.representativeFirstNameKana}/>
                    {errors.representativeFirstNameKana && errors.representativeFirstNameKana.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeFirstNameKana && errors.representativeFirstNameKana.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は24文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeFirstNameKana && errors.representativeFirstNameKana.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角カナ、全角英数字で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>


            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        郵便番号<RequiredImage /><br/>
                            <span className="subLabel">(半角数字、7桁 ハイフン無し)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="郵便番号"
                                  defaultValue={props.value?.representativeYubin}
                                  className={"form-control"}
                                  {...register("representativeYubin", {required: true, maxLength: 7, pattern: /^\d{7}$/})}
                                  isInvalid={!!errors.representativeYubin}/>
                    {errors.representativeYubin && errors.representativeYubin.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeYubin && errors.representativeYubin.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は7桁までです。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeYubin && errors.representativeYubin.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        半角数字、7桁で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md={{span: 10, offset: 1}}>
                        <span style={{fontSize:"smaller", color:"red"}}>※ 「都道府県」「市区町村」「番地以下」「それ以降の住所」は合わせて最大40文字で入力してください。</span>
                </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        都道府県<sup style={{color:"red"}}> ※</sup><RequiredImage />
                    </Form.Label>
                </Col>
                <Col md={6}>

                    <Form.Control disabled={props.readOnly} required as="select" placeholder="都道府県"
                                  defaultValue={props.value?.representativePref}
                                  {...register("representativePref", {required: true, maxLength: 40})}
                                  isInvalid={!!errors.representativePref}>
                        <option value=""/>
                        <option value="北海道">北海道</option>
                        <option value="青森県">青森県</option>
                        <option value="岩手県">岩手県</option>
                        <option value="宮城県">宮城県</option>
                        <option value="秋田県">秋田県</option>
                        <option value="山形県">山形県</option>
                        <option value="福島県">福島県</option>
                        <option value="茨城県">茨城県</option>
                        <option value="栃木県">栃木県</option>
                        <option value="群馬県">群馬県</option>
                        <option value="埼玉県">埼玉県</option>
                        <option value="千葉県">千葉県</option>
                        <option value="東京都">東京都</option>
                        <option value="神奈川県">神奈川県</option>
                        <option value="新潟県">新潟県</option>
                        <option value="富山県">富山県</option>
                        <option value="石川県">石川県</option>
                        <option value="福井県">福井県</option>
                        <option value="山梨県">山梨県</option>
                        <option value="長野県">長野県</option>
                        <option value="岐阜県">岐阜県</option>
                        <option value="静岡県">静岡県</option>
                        <option value="愛知県">愛知県</option>
                        <option value="三重県">三重県</option>
                        <option value="滋賀県">滋賀県</option>
                        <option value="京都府">京都府</option>
                        <option value="大阪府">大阪府</option>
                        <option value="兵庫県">兵庫県</option>
                        <option value="奈良県">奈良県</option>
                        <option value="和歌山県">和歌山県</option>
                        <option value="鳥取県">鳥取県</option>
                        <option value="島根県">島根県</option>
                        <option value="岡山県">岡山県</option>
                        <option value="広島県">広島県</option>
                        <option value="山口県">山口県</option>
                        <option value="徳島県">徳島県</option>
                        <option value="香川県">香川県</option>
                        <option value="愛媛県">愛媛県</option>
                        <option value="高知県">高知県</option>
                        <option value="福岡県">福岡県</option>
                        <option value="佐賀県">佐賀県</option>
                        <option value="長崎県">長崎県</option>
                        <option value="熊本県">熊本県</option>
                        <option value="大分県">大分県</option>
                        <option value="宮崎県">宮崎県</option>
                        <option value="鹿児島県">鹿児島県</option>
                        <option value="沖縄県">沖縄県</option>
                    </Form.Control>
                    {errors.representativePref && errors.representativePref.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.representativePref && errors.representativePref.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は40文字までです。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>


            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        市区町村<sup style={{color:"red"}}> ※</sup>
                            <RequiredImage /><br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="市区町村"
                                  defaultValue={props.value?.representativeShikuchouson}
                                  className={"form-control"}
                                  {...register("representativeShikuchouson", {required: true, maxLength: 40, pattern: /^[^ -~]+$/})}
                                  isInvalid={!!errors.representativeShikuchouson}/>
                    {errors.representativeShikuchouson && errors.representativeShikuchouson.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeShikuchouson && errors.representativeShikuchouson.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は40文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeShikuchouson && errors.representativeShikuchouson.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        番地以下<sup style={{color:"red"}}> ※</sup>
                            <RequiredImage /><br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="番地以下"
                                  defaultValue={props.value?.representativeBanchi}
                                  className={"form-control"}
                                  {...register("representativeBanchi", {required: true, maxLength: 40, pattern: /^[^ -~]+$/})}
                                  isInvalid={!!errors.representativeBanchi}/>
                    {errors.representativeBanchi && errors.representativeBanchi.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeBanchi && errors.representativeBanchi.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は40文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeBanchi && errors.representativeBanchi.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        それ以降の住所<sup style={{color:"red"}}> ※</sup>
                            <br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="それ以降の住所"
                                  defaultValue={props.value?.representativeIkou}
                                  className={"form-control"}
                                  {...register("representativeIkou", {required: false, maxLength: 40, pattern: /^[^ -~]+$/})}
                                  isInvalid={!!errors.representativeIkou}/>
                    {errors.representativeIkou && errors.representativeIkou.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は40文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeIkou && errors.representativeIkou.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} style={{alignItems: "center"}}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        電話番号<RequiredImage /><br/>
                            <span className="subLabel">(半角数字、最大5桁 - 最大4桁 - 最大4桁)</span>
                    </Form.Label>
                </Col>
                <Col xs={6} style={{display: "flex"}}>
                    <div>
                        <Form.Control readOnly={props.readOnly} required type="text" placeholder="" style={{width: 75}}
                                    defaultValue={props.value?.representativeTelNo1}
                                    isInvalid={!!errors.representativeTelNo1}
                                    {...register("representativeTelNo1", {required: true, maxLength: 5, pattern: /^\d+$/})}
                        />
                        {errors.representativeTelNo1 && errors.representativeTelNo1.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.representativeTelNo1 && errors.representativeTelNo1.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.representativeTelNo1 && errors.representativeTelNo1.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </div>
                    <div style={{width: 105, textAlign: "center", marginTop: 5}}>
                        -
                    </div>
                    <div>
                        <Form.Control readOnly={props.readOnly} required type="text" placeholder="" style={{width: 75}}
                                    defaultValue={props.value?.representativeTelNo2}
                                    isInvalid={!!errors.representativeTelNo2}
                                    {...register("representativeTelNo2", {required: true, maxLength: 4, pattern: /^\d+$/})}
                        />
                        {errors.representativeTelNo2 && errors.representativeTelNo2.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.representativeTelNo2 && errors.representativeTelNo2.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は4桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.representativeTelNo2 && errors.representativeTelNo2.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </div>
                    <div style={{width: 105, textAlign: "center", marginTop: 5}}>
                        -
                    </div>
                    <div>
                        <Form.Control readOnly={props.readOnly} required type="text" placeholder="" style={{width: 75}}
                                    defaultValue={props.value?.representativeTelNo3}
                                    isInvalid={!!errors.representativeTelNo3}
                                    {...register("representativeTelNo3", {required: true, maxLength: 4, pattern: /^\d+$/})}
                        />
                        {errors.representativeTelNo3 && errors.representativeTelNo3.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.representativeTelNo3 && errors.representativeTelNo3.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は4桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.representativeTelNo3 && errors.representativeTelNo3.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </div>
                </Col>
            </Form.Group>


            <Form.Group as={Row}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        メールアドレス<RequiredImage /><br/>
                            <span className="subLabel">(最大50文字)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={props.readOnly} required type="text" placeholder="メールアドレス"
                                  defaultValue={props.value?.representativeMailAddress}
                                  className={"form-control"}
                                  {...register("representativeMailAddress", {required: true, maxLength: 50, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/})}
                                  isInvalid={!!errors.representativeMailAddress}/>
                    {errors.representativeMailAddress && errors.representativeMailAddress.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeMailAddress && errors.representativeMailAddress.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は50文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.representativeMailAddress && errors.representativeMailAddress.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        メールアドレスを入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
        </div>
    );
}
export default (OrganizationBaseForms);

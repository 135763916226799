//react 
import React, {useEffect, useState} from "react";
import {Col, Form, FormCheck, FormControl, InputGroup, Row} from "react-bootstrap";
import {RouteComponentProps, withRouter} from "react-router-dom";


//common


//local
import {ApplicationApiFp, GetApplicationRow, PutGuidList, UserApiFp} from "../client-axios";
import ApplicationRow from "./ApplicationRow";
import Paging, {param2query} from "../common/Paging";
import {CommonConfiguration} from "../common/CommonSetting";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import CommonSpinner from "../common/CommonSpinner";
import {isMynaPopupResult} from "../common/CommonComm";
import {MynaPopupResult} from "./UserRouteOidc";
import ThrottleButton from "../control/ThrottleButton";
import {useForm} from "react-hook-form";
import {useDispatch, useStore} from "react-redux";
import {setSearchKey} from "../globalReduxActions";


interface Props extends RouteComponentProps{
    value?: GetApplicationRow[],
    query?: string,

    onDataRefresh: () => void;
}

export interface Params {
    search: string,
    checked: boolean,
    radioChecked: boolean,
    page: number,
}

export const query2params = (query: string): Params => {
    const r: Params = {
        search: "",
        checked: true,
        radioChecked: true,
        page: 1,
    };
    if ( query == null){
        return r;
    }
    const params = query.split('&');
    params.map(param => {
        const x = param.split('=');
        switch (x[0]) {
            case 'search':
                r.search = x[1];
                break;
            case 'display':
                r.checked = x[1] === 'all' ? true : false;
                break;
            case 'status':
                r.radioChecked = x[1] === 'all' ? false : true;
                break;
            case 'page':
                let p = 1;
                p = parseInt(x[1]) <= 0 ? 1 : parseInt(x[1]);
                r.page = p;
                break;
        }
    });
    return r;
}

export const createUrl = (props: Params): string => {
    const search = props.search;
    const display = props.checked ? "all" : "";
    const status = !props.radioChecked ? "all" : "";
    const p = props.page;
    let url: string = "/applicationList";
    if(search !== "" || display !== "" || status !== "" || p !== 1)
    {
        url = url + "?" + "search=" + search + "&display=" + display + "&status=" + status + "&page=" + p;
    }
    return url;
}

export const ApplicationTable = (props: Props) => {
    const methods = useForm();
    const {register, formState: {errors}} = methods;
    
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [displayRows, setDisplayRows] = useState<GetApplicationRow[] | undefined>([]);


    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        props.history.push("/menu");
    }

    //検索用ボタン
    const [searchCondition, setSearchCondition] = useState("");
    const [tempSearchString, setTempSearchString] = useState("");

    const [searchFlag, setSearchFlag] = useState(false);
    const [radioChecked, setRadioChecked] = useState(true);

    const onSearchClick=() =>{
        setSearchFlag(true);
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if ( searchFlag) {
            setSearchCondition(tempSearchString);
            const params: Params = {
                search: tempSearchString,
                checked: checked,
                radioChecked: radioChecked,
                page: page + 1,
            }
            dispatch(setSearchKey(param2query(params)));
            const url: string = createUrl(params);
            props.history.push(url)
            setSearchFlag(false);
        }
    }, [searchFlag]);

    const onSearchChange=(event)=> {
        const value = event.target.value;
        setTempSearchString(value);
    }


    

    //ポップアップ
    const [isAuthenticateRetry, setIsAuthenticateRetry] = useState(false);


    const [checked, setChecked] = useState(false);
    const [ossCreateAt, setOssCreateAt] = useState("");
    const [statusUpdate, setStatusUpdate] = useState(false);
    
    const [saveDeleteConfirm, setSaveDeleteConfirm] = useState("");
    const [saveCompletedConfirm, setSaveCompletedConfirm] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [completedId, setCompletedId] = useState("");

    /**
     * 一時データの削除処理、ApplicationRowから呼び出す
     * @param string id 削除するApplicationId
     * */
    const onDataDelete = ((id:string) =>
    {
        //GetMe().then();
        setDeleteId(id);
        setSaveDeleteConfirm("一時保存を削除しますか？");
    });

    const onCompleted = ((id: string) => {
        //GetMe().then();
        setCompletedId(id);
        setSaveCompletedConfirm("ステータスを終了としますか？");
    });

    
    const onSaveDelete = () =>
    {
        //SetProgressStatus(enumProgressStatus.tempSave_Delete)
        //const data = methods.getValues();
        //const info = onMakeInfo(data);
        //saveTemporary(info).then();
        
        deleteTemporary(deleteId).then();
    };

    const cancelSaveDelete = () =>
    {
        setDeleteId("");
        setSaveDeleteConfirm("");
    }

    const deleteTemporary = async (id:string) =>
    {
        setSaveDeleteConfirm("");
        const conf = CommonConfiguration();
        //SetProgressStatus(enumProgressStatus.tempSave_Delete);
        const apiDelete = await ApplicationApiFp(conf).apiApplicationIdDelete(id);

        
        try
        {
            const ret = await apiDelete();
            if (ret.status === 200)
            {
                
                setDialogMessage("削除しました。")
                
                
            } else if (ret.status === 204)
            {
                setDialogWithClose("削除しました。");
            } else
            {
            }
            props.onDataRefresh();
        }
        catch (e:any)
        {
            
            //更新系例外
            if (e instanceof Error && e.message === "Network Error")
            {
                setDialogMessage("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500)
            {
                setDialogMessage(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422)
            {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404)
            {
                setDialogMessage("削除に失敗しました。");
            } else if (e.response.status === 405)
            {
                window.location.href = "/timeout";
            } else
            {
                setDialogMessage("削除に失敗しました。");
            }
            props.onDataRefresh();
        }
    }

    const onSaveCompleted = () => {
        completedTemporary(completedId).then();
    };

    const cancelSaveCompleted = () => {
        setCompletedId("");
        setSaveCompletedConfirm("");
    }

    const completedTemporary = async (id: string) => {
        setSaveCompletedConfirm("");
        const conf = CommonConfiguration();
        const apiCompleted = await ApplicationApiFp(conf).apiApplicationIdCompletedPut(id);


        try {
            const ret = await apiCompleted();
            if (ret.status === 200) {

                setDialogMessage("ステータスを終了としました。")


            } else if (ret.status === 204) {
                setDialogWithClose("ステータスを終了としました。");
            } else {
            }
            props.onDataRefresh();
        }
        catch (e: any) {

            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogMessage("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogMessage(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogMessage("削除に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogMessage("ステータスを終了に失敗しました。");
            }
            props.onDataRefresh();
        }
    }


    useEffect(() => {
        GetMe().then()
        const handler = event => {
            if ( isMynaPopupResult(event.data)) {
                const result = event.data as MynaPopupResult
                if ( result.result === true ){
                    setIsLoaded(true);
                    setIsAuthenticateRetry(true);
                    return;
                }else{
                    setIsLoaded(true);

                    setDialogWithClose("マイナポータルでの認証に失敗しました。");
                    return;
                }
            }
        };

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    }, []) // empty array => run only once

    
    
    const [isLoaded, setIsLoaded] = useState(true);

    const onPaging = (v: number) => {
        if (v < 0) {
            setPage(0);
        } else if (maxPage < v) {
            setPage(maxPage);
        } else {
            setPage(v);
        }
    }


    const store = useStore()

    const itemsPerPage = 6;
    // const itemsPerPage = 2;
    const FilterData = async () => {
        let query = decodeURI(window.location.search.substr(1));
        const state = store.getState()
        const globalQuery = state.globalSearchKey;
        if(query !== "" && query !== globalQuery)
        {
            dispatch(setSearchKey(query));
            props.history.push(createUrl(query2params(query)));
        }

        let p = 0;
        const params = query.split('&');
        params.map(param => {
            const x = param.split('=')
            switch (x[0]){
                case 'search':
                    setTempSearchString(x[1])
                    setSearchCondition(x[1])
                    break;
                case 'display':
                    x[1] === 'all' ? setChecked(true) : setChecked(false)
                    break;
                case 'status':
                    x[1] === 'all' ? setRadioChecked(false) : setRadioChecked(true)
                    break;
                case 'page':
                    p = (parseInt(x[1]) - 1);
                    p = p <= 0 ? 0 : p;
                    p = p > maxPage ? maxPage : p;
                    setPage(p);
                    break;
            }
        })

        const rows = props.value;
        const rows2 = rows?.filter(x => 
            (((!checked && x.ossCreateAt === ossCreateAt) || checked)
            && ((radioChecked && x.statusKind !== 3 && x.endFlag !== 1) || !radioChecked) && ((searchCondition === "")
                || (searchCondition !== "" && (x.searchTitle?.includes(searchCondition)))
                || (searchCondition !== "" && (x.searchBikou?.includes(searchCondition)))
                || (searchCondition !== "" && (x.tempApplicationNo?.includes(searchCondition)))
                || (searchCondition !== "" && (x.applicationNo?.includes(searchCondition))))));
        if (rows2 == null ) {
            setMaxPage(0);
            setPage(0);
            setDisplayRows(undefined);
            return;
        }
        const rows3 = paginate(rows2, itemsPerPage, page + 1);
        let max = Math.ceil((rows2.length) / itemsPerPage );
        max = max === 0 ? 0 : max - 1;
        // setPage(page > max ? max : page);
        setPage(page > max ? max : p <= 0 ? 0 : p);
        setMaxPage(max);
        setDisplayRows(rows3);
    }

    const paginate = (array, page_size, page_number) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    useEffect( () => {
        setIsLoaded(false);
        FilterData().then();
        
        setIsLoaded(true);
    }, [props.value, searchFlag, ossCreateAt, radioChecked, checked, page])

    //リトライ系機構
    useEffect(() => {
        if ( isAuthenticateRetry ) {
            UpdateStatus(true).then();
        }
        setIsAuthenticateRetry(false);
    }, [isAuthenticateRetry])
    
    
    const UpdateStatus = async (isRetry : boolean) => {
        setIsLoaded(false);
        const conf = CommonConfiguration();

        if (displayRows != null) {
            const guidList = displayRows?.map(x => {
                return {id: x?.id};
            });

            // props.onDataRefresh();
            //
            const parameter: PutGuidList = {items: guidList}
            const apiStatusUpdate = await ApplicationApiFp(conf).apiApplicationStatusupdatePut(parameter);
            try {
                const ret = await apiStatusUpdate();
                if (ret.status === 200) {
                    setDialogMessage("ステータスの更新が終了しました");
                    props.onDataRefresh();
                }
            } catch
                (e) {
                //更新系例外
                if (e instanceof Error && e.message === "Network Error") {
                    setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                } else if (e.response.status === 401 || e.response.status === 403) {
                    if ( isRetry ) {
                        setDialogWithClose("マイナポータル認証に失敗しました。");
                    }else{
                        window.open("/oidc?detail=first", "MynaPopup", "top=100, left=100, width=400, height=400, rel=opener");
                        return;
                    }
                } else if (e.response.status === 400 || e.response.status === 500) {
                    setDialogWithClose("サーバーエラーが発生しました。システム担当者へご連絡ください。");
                } else if (e.response.status === 422) {
                    setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
                } else if (e.response.status === 404) {
                    setDialogWithClose("更新に失敗しました。");
                } else if (e.response.status === 405) {
                    window.location.href = "/timeout";
                } else {
                    setDialogWithClose("更新に失敗しました。");
                }
            }
        }

        setIsLoaded(true);
    }

    useEffect(() => {
        if(statusUpdate) {
            UpdateStatus(false).then();
            setStatusUpdate(false);
        }
    }, [statusUpdate])

    const onStatusUpdate = () => {
        setStatusUpdate(true);
        // UpdateStatus(false).then();
    }

    const radioToggle = () => {
        // await GetMe().then();
        radioChecked ? setRadioChecked(false) : setRadioChecked(true);
        const params: Params = {
            search: tempSearchString,
            checked: checked,
            radioChecked: !radioChecked,
            page: page + 1,
        }
        const url: string = createUrl(params);
        dispatch(setSearchKey(param2query(params)));
        props.history.push(url)
    }

    const displayToggle = async () => {
        // await GetMe().then();
        checked ? setChecked(false) : setChecked(true);
        const params: Params = {
            search: tempSearchString,
            checked: !checked,
            radioChecked: radioChecked,
            page: page + 1,
        }
        const url: string = createUrl(params);
        dispatch(setSearchKey(param2query(params)));
        props.history.push(url)
    }

    const GetMe = async () => {
        const conf = CommonConfiguration();
        const apiGet = await UserApiFp(conf).apiMeReloadGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                ret.data.id === undefined ? setOssCreateAt("") : setOssCreateAt(ret.data.id);
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }
    }

    useEffect(() => {
        const body = document.body;
        if (body != null) {
            body.style.overflow = "";
        }
    }, [saveDeleteConfirm, saveCompletedConfirm])


    const loading = (
        <>
            <CommonSpinner />
        </>
    )
    
    const loaded = (
        <div>

            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            {saveDeleteConfirm !== "" && <CommonDialogBox value={saveDeleteConfirm} style={DialogStyle.yesNo} onClickYes={onSaveDelete} onClickNo={cancelSaveDelete} />}
            {saveCompletedConfirm !== "" && <CommonDialogBox value={saveCompletedConfirm} style={DialogStyle.yesNo} onClickYes={onSaveCompleted} onClickNo={cancelSaveCompleted} />}

            <Row>
                
                <Col xs={5} style={{marginTop: "0.5rem"}}>
                    <ThrottleButton onClick={onStatusUpdate}>
                        <span className={"buttonLabel"}>ステータスの更新</span>
                    </ThrottleButton>
                </Col>
                <Col md={7} style={{display: "flex", marginTop: "0.5rem"}}>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">検索キーワード</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="検索キーワード"
                            aria-label="検索キーワード"
                            aria-describedby="検索キーワード"
                            value={tempSearchString}
                            onChange={onSearchChange}
                            maxLength={50}
                        />

                    </InputGroup>
                    <ThrottleButton variant="primary" onClick={onSearchClick} style={{marginLeft: 15}}>検索</ThrottleButton>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <FormCheck type={"checkbox"} onChange={displayToggle} checked={checked} label={"自分以外も表示"} style={{margin: "0.8rem"}} />
                </Col>
                <Col md={6} style={{display: "flex"}}>
                    <Form.Check type={"radio"} {...register("status")} onChange={radioToggle} checked={radioChecked} label={"ステータス終了以外"} style={{margin: "0.8rem"}} value={1} />
                    <Form.Check type={"radio"} {...register("status")} onChange={radioToggle} checked={!radioChecked} label={"すべてのステータス"} style={{margin: "0.8rem"}} value={2} />
                </Col>
            </Row>

            <table className="table">
                <thead>
                <tr>
                    <th>申請日</th>
                    <th>仮受付番号</th>
                    <th>受付番号</th>
                    <th>ステータス</th>
                    <th>検索用タイトル</th>
                    <th style={{textAlign:"center"}}>最新情報の有無</th>
                    <th style={{textAlign:"center"}}>公文書の有無</th>
                    <th style={{textAlign:"center"}}>編集</th>
                    <th style={{ textAlign: "center" }}>操作</th>
                    <th>申請者</th>
                </tr>
                </thead>
                <tbody>
                {displayRows != null && displayRows.map(x => {
                    return <ApplicationRow key={x.id} value={x} onDataDelete={onDataDelete} onCompleted={onCompleted} />
                })}

                </tbody>
            </table>
            <Paging value={page} onPaging={onPaging} maxPage={maxPage} history={props.history}/>
        </div>
    )

    return (isLoaded ? loaded : loading);

}

export default withRouter(ApplicationTable);


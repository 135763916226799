//react 
import React from "react";
import {generatePath, RouteComponentProps, withRouter} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';

//common
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEdit, faFile, faBell, faTrashAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons";


//local
import {GetApplicationRow} from "../client-axios";
import {FormatDate} from "../common/CommonFormat";


interface Props extends RouteComponentProps {
	onDataDelete: (string) => void;
    onCompleted: (string) => void;
    value: GetApplicationRow,
}


const ApplicationRow = (props: Props) => {

    const FromString2FormatDate = (str, format) => {
        const x = Date.parse(str).toString() === "NaN" ? "作成中" : new Date(str).toString()
        if(x === "作成中") return x;
        
        return FormatDate(new Date(str), format);
    };


    const StatusKindMap = new Map([
        [-1, "作成中"],
        [0, "受付処理中"],
        [1, "送信待ち"],
        [2, "処理中"],
        [3, "終了"],
        [4, "要再申請(補正可)"],
        [5, "要ダウンロード"],
        [6, "取下げ済み"],
        [8, "要再申請(補正不可)"],
        [10, "取下げ済み"],
        [11, "要確認（取下げ不可）"],
        [12, "要確認（取下げ不可）"],
        [13, "取下げ処理中"],
        [14, "取下げ処理中"],

    ]);

    const getStringValueFunc = <K, >(map: Map<K, string>) =>
        (code: K) =>
            (map.has(code)) ? map.get(code) : "";

    const onEdit = () => {
        props.history.push(generatePath("/application/:id", {id: props.value.id}));
    }
    
     const onDelete = () => {
         //setDeleteFlag(true)
         setAnchorEl(null);
          props.onDataDelete(props.value.id);
     }
    
    const onCompleted = () => {
        //setDeleteFlag(true)
        setAnchorEl(null);
        props.onCompleted(props.value.id);
    }

    type FontAwesomeSvgIconProps = {
        icon: any;
    };
    const FontAwesomeSvgIcon = React.forwardRef<SVGSVGElement, FontAwesomeSvgIconProps>(
        (props, ref) => {
            const { icon } = props;

            const {
                icon: [width, height, , , svgPathData],
            } = icon;

            return (
                <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`}>
                    {typeof svgPathData === 'string' ? (
                        <path d={svgPathData} />
                    ) : (
                        svgPathData.map((d: string, i: number) => (
                            <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
                        ))
                    )}
                </SvgIcon>
            );
        },
    );
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuArea = (
        <>
            <IconButton
                aria-controls={open ? 'application-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <FontAwesomeSvgIcon icon={faEllipsisV} />
            </IconButton>
            <Menu
                id="application-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {props.value.statusKind == -1 &&
                    <MenuItem onClick={onDelete}>削除</MenuItem>
                }
                {(props.value.statusKind == 8 || props.value.statusKind == 6 || props.value.statusKind == 12) &&
                    <MenuItem onClick={onCompleted}>終了</MenuItem>
                }
            </Menu>
        </>
    );

    return (
        <tr>
            <td style={{ verticalAlign: "middle" }}>{FromString2FormatDate(props.value.applicationDate, "YYYY/MM/DD")}</td>
            <td style={{ verticalAlign: "middle" }}>{props.value.tempApplicationNo}</td>
            <td style={{ verticalAlign: "middle" }}>{props.value.applicationNo}</td>
            <td style={{ verticalAlign: "middle" }}>{StatusKindMap.get(props.value.statusKind as number)}</td>
            <td style={{ verticalAlign: "middle" }}>{props.value.searchTitle}</td>
            <td style={{ verticalAlign: "middle", textAlign: "center" }}>{props.value.hasNewDocument ? <FontAwesomeIcon icon={faBell} size="lg" /> : ""}</td>
            <td style={{ verticalAlign: "middle", textAlign: "center" }}>{props.value.hasDocument ? <FontAwesomeIcon icon={faFile} size="lg" /> : ""}</td>
            <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                <IconButton
                    onClick={onEdit}
                >
                    <FontAwesomeSvgIcon icon={faEdit} />
                </IconButton>
            </td>
            

            <td style={{ verticalAlign: "middle" }}>
                {((props.value.statusKind == -1 || props.value.statusKind == 8 ||
                    props.value.statusKind == 6 || props.value.statusKind == 12) && props.value.endFlag != 1) && menuArea}
            </td>

            <td>{props.value.ossName}</td>
        </tr>
    );
}


export default withRouter(ApplicationRow);



//react 
import React from "react";


//common


//local



interface Props {
}



export const AdminFooter = (props: Props) => {

    return(
        <span />
            // <footer className={"global-footer"}>
            //     <div className="top">
            //     </div>
            //     <div className="bottom">
            //         Copyright 息 CREO CO.,LTD. All Rights Reserved.
            //     </div>
            // </footer>
);

}

export default (AdminFooter);


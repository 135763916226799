// 添付ファイルのチェックを実施する。
// ・ファイル名：ファイル名は128文字以内にしてください。
// ・許容拡張子：許容されたファイル形式ではありません
// (統一添付入力フォーム.xml内に定義されている
// pdf,xlsx,xls,docx,doc,pptx,ppt,xml,csv,txt,jpeg,jpg,gif,png,tiff,tif)
// ・ファイルサイズ(個別)：4.5MB以上のファイルは送信出来ません。
// ・ファイルサイズ(手続)：トータルで20MB以上となるファイルは送信出来ません。
//


const extensions = ['pdf', 'xlsx', 'xls', 'docx', 'doc', 'pptx', 'ppt', 'xml', 'csv', 'txt', 'jpeg', 'jpg', 'gif', 'png', 'tiff', 'tif'];
const extensionCsv = [ 'csv'];

export const getExtension = (filename) =>
{
    const pos = filename.lastIndexOf('.');
    if (pos === -1) return '';
    return filename.slice(pos + 1);
}
export const checkExtension = (filename) =>
{
    const ext = getExtension(filename).toLowerCase();
    if (extensions.indexOf(ext) === -1) return false;
    return true;
}

export const checkExtensionCsv = (filename) =>
{
    const ext = getExtension(filename).toLowerCase();
    if (extensionCsv.indexOf(ext) === -1) return false;
    return true;
}

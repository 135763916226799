//react 
import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useParams} from "react-router";


//common
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretSquareUp, faPaperPlane, faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";


//local
import {CommonConfiguration} from "../common/CommonSetting";
import {
    ApplicationApiFp,
    GetApplicationInfo,
    GetOrganizationInfo,
    OrganizationApiFp,
    PutGuidList, RefundReason,
    UpdateApplicationInfo, UserApiFp,
} from "../client-axios";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import ApplicationForms from "./ApplicationForms";

import {
    format10DateToHaiphongDate,
    format422DateToHaiphongDate,
    format8DateToHaiphongDate,
    formatHaiphongDateTo8,
    formatHaiphongDateToD,
    formatHaiphongDateToM,
    formatHaiphongDateToY
} from "../common/CommonFormat";
import CommonSpinner from "../common/CommonSpinner";
import ApplicationFilesForm, {ApplicationFileInterface} from "./ApplicationFilesForm";
import ApplicationLogTable from "./ApplicationLogTable";
import {isMynaPopupResult} from "../common/CommonComm";
import {MynaPopupResult} from "./UserRouteOidc";
import RequiredImage from "../control/RequiredImage";
import ThrottleButton from "../control/ThrottleButton";
import {base64ToBin} from "../common/CommonBinary";
import {useStore} from "react-redux";

enum enumProgressStatus {
    isReading,
    readingStatusUpdate1,
    readingStatusUpdate2,
    readingGetApplication,
    readingGetOrganization,
    input,
    tempSave_Save,
    tempSave_Delete,
    saveAndSend_Save,
    saveAndSend_Send1,
    saveAndSend_Send2,
    refund_Send1,
    refund_Send2,
    readDocument_Read1,
    readDocument_Read2,


}

interface Props extends RouteComponentProps {
}

const UserRouteApplicationEdit = (props: Props) => {
    document.title = "申請編集";

    const methods = useForm({mode: "onBlur"});
    const {register, trigger, formState: {errors}, getValues} = methods;

    const [progressStatus, SetProgressStatus] = useState<enumProgressStatus>(enumProgressStatus.isReading);

    //ダイアログ
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogWithGotoTop, setDialogWithGotoTop] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
        setIsLoaded(true);
    }
    const store = useStore()
    const state = store.getState()
    const query: string = state.globalSearchKey;
    const onCloseAndGotoList = () => {
        setDialogMessage("");
        setDialogWithClose("");
        let url = "/applicationList";
        if(query === "")
        {
            props.history.push(url);
        }
        else
        {
            props.history.push(url + "?" + query);
        }
    }

    const onCloseAndGotoMenu = () => {
        setDialogMessage("");
        setDialogWithGotoTop("");
        props.history.push("/menu");
    }

    //画面制御
    const [isLoaded, setIsLoaded] = useState(false);

    const [organizationInfo, setOrganizationInfo] = useState<GetOrganizationInfo | undefined>();
    const [applicationInfo, setApplicationInfo] = useState<GetApplicationInfo | undefined>();
    const [applicationRows, setApplicationRows] = useState<ApplicationFileInterface[] | undefined>();

    const [saveConfirm, setSaveConfirm] = useState("");
    const [saveDeleteConfirm, setSaveDeleteConfirm] = useState("");

    const [sendConfirm, setSendConfirm] = useState("");
    const [refundConfirm, setRefundConfirm] = useState("");
    
    const params: { id: string } = useParams();


    const conf = CommonConfiguration();


    const onStatusUpdate = async (isRetry: boolean) => {

        const parameter: PutGuidList = {items: [{id: params.id}]}
        const apiStatusUpdate = await ApplicationApiFp(conf).apiApplicationStatusupdatePut(parameter);
        try {
            const ret = await apiStatusUpdate();

            if (ret.status === 200) {
                SetProgressStatus(enumProgressStatus.readingGetApplication);
            }
        } catch
            (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 401 || e.response.status === 403) {
                if (isRetry) {
                    setDialogWithClose("マイナポータル認証に失敗しました。");
                } else {
                    window.open("/oidc?detail=first", "MynaPopup", "top=100, left=100, width=400, height=400, rel=opener");
                    return;
                }
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose("サーバーエラーが発生しました。システム担当者へご連絡ください。");
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }

    }


    const getApplication = async (id)  => {
        const apiGet = await ApplicationApiFp(conf).apiApplicationIdGet(id)
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const item: GetApplicationInfo = ret.data;
                setInfo(item);
                SetProgressStatus(enumProgressStatus.readingGetOrganization);
                return true;
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }
        return false;

    };


    const getOrganization = async () : Promise<boolean> => {
        const apiGet = await OrganizationApiFp(conf).apiOrganizationGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const org: GetOrganizationInfo = ret.data;
                setOrganizationInfo(org);
                SetProgressStatus(enumProgressStatus.input);
                setIsLoaded(true);
                return true;
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }
        return false;

    };

    useEffect(() => {
        SetProgressStatus(enumProgressStatus.readingStatusUpdate1);
    }, []);

    //状態遷移コントローラー
    useEffect( () => {
        switch (progressStatus) {
            case enumProgressStatus.isReading:
                break;
            case enumProgressStatus.readingStatusUpdate1:
                onStatusUpdate(false).then();
                break;
            case enumProgressStatus.readingStatusUpdate2:
                onStatusUpdate(true).then();
                break;
            case enumProgressStatus.readingGetApplication:
                getApplication(params.id).then();
                break;
            case enumProgressStatus.readingGetOrganization:
                getOrganization().then();
                break;
            case enumProgressStatus.input:
                break;
            case enumProgressStatus.tempSave_Save:
                //画面からのプッシュ
                break;

            case enumProgressStatus.tempSave_Delete:

                break;

            case enumProgressStatus.saveAndSend_Save:
                //画面からのプッシュ
                break;
            case enumProgressStatus.saveAndSend_Send1:
                onSendApplication(false).then();
                break;
            case enumProgressStatus.saveAndSend_Send2:
                onSendApplication(true).then();
                break;
            case enumProgressStatus.refund_Send1:
                //画面からのプッシュ
                onRefundImplement(false).then();
                break;
            case enumProgressStatus.refund_Send2:
                onRefundImplement(true).then();
                break;
            case enumProgressStatus.readDocument_Read1:
                //画面からのプッシュ
                onReadDocumentImplement(false).then();
                break;
            case enumProgressStatus.readDocument_Read2:
                onReadDocumentImplement(true).then();
                break;
            default:
                break;

        }
    }, [progressStatus]);


    const setInfo = (data) => {
        setApplicationInfo(data);

        methods.setValue("id", data.id);
        methods.setValue("applicationDate", format10DateToHaiphongDate(data.applicationDate));
        methods.setValue("applicationKind", data.applicationKind);
        methods.setValue("statusKind", data.statusKind);
        methods.setValue("rowCount", data.rowCount);
        methods.setValue("tempApplicationNo", data.tempApplicationNo);
        methods.setValue("applicationNo", data.applicationNo);
        methods.setValue("teishutumotoId", data.teishutumotoId);
        methods.setValue("tsuban", data.tsuban);
        methods.setValue("sikibetuJouhouYMD", format422DateToHaiphongDate(data.sikibetuJouhouY, data.sikibetuJouhouM, data.sikibetuJouhouD));
        methods.setValue("jigyousyoSeirikigouPref", data.jigyousyoSeirikigouPref);
        methods.setValue("jigyousyoSeirikigouGunshiku", data.jigyousyoSeirikigouGunshiku);
        methods.setValue("jigyousyoSeirikigouKigou", data.jigyousyoSeirikigouKigou);
        methods.setValue("jigyousyoSeirikigouBangou", data.jigyousyoSeirikigouBangou);
        methods.setValue("kenpoSikakuShutoku", data.kenpoSikakuShutoku);
        methods.setValue("kenpoHihuyousha", data.kenpoHihuyousha);
        methods.setValue("kenpoSikakuSoushitsu", data.kenpoSikakuSoushitsu);
        methods.setValue("kenpoGetsugakuHenkou", data.kenpoGetsugakuHenkou);
        methods.setValue("kenpoSanteiKiso", data.kenpoSanteiKiso);
        methods.setValue("kenpoShouyoShiharai", data.kenpoShouyoShiharai);
        methods.setValue("kenpoGoukei", data.kenpoGoukei);
        methods.setValue("kokuminNenkin3gou", data.kokuminNenkin3gou);
        methods.setValue("kokuminNenkinGoukei", data.kokuminNenkinGoukei);
        methods.setValue("bikou", data.bikou);
        methods.setValue("jigyoushoYubin", data.jigyoushoYubin);
        methods.setValue("jigyoushoPref", data.jigyoushoPref);
        methods.setValue("jigyoushoShikuchouson", data.jigyoushoShikuchouson);
        methods.setValue("jigyoushoBanchi", data.jigyoushoBanchi);
        methods.setValue("jigyoushoIkou", data.jigyoushoIkou);
        methods.setValue("jigyoushoName", data.jigyoushoName);
        methods.setValue("jigyounushiFamilyName", data.jigyounushiFamilyName);
        methods.setValue("jigyounushiFirstName", data.jigyounushiFirstName);
        methods.setValue("telNo1", data.telNo1);
        methods.setValue("telNo2", data.telNo2);
        methods.setValue("telNo3", data.telNo3);
        methods.setValue("applicantsY", data.applicantsY);
        methods.setValue("applicantsM", data.applicantsM);
        methods.setValue("applicantsD", data.applicantsD);
        methods.setValue("applicantsYMD", format422DateToHaiphongDate(data.applicantsY, data.applicantsM, data.applicantsD));
        methods.setValue("sharoushiFamilyName", data.sharoushiFamilyName);
        methods.setValue("sharoushiFirstName", data.sharoushiFirstName);
        methods.setValue("tsuuchisyoKibou", !(data?.tsuuchisyoKibou == null || data?.tsuuchisyoKibou === ""));
        methods.setValue("corpName", data.corpName);
        methods.setValue("destHokenKumiaiNo", data.destHokenKumiaiNo);
        methods.setValue("destHokenKumiaiName", data.destHokenKumiaiName);
        methods.setValue("attachedPost", !(data?.attachedPost == null || data?.attachedPost === ""));
        methods.setValue("attachedE", !(data?.attachedE == null || data?.attachedE === ""));
        methods.setValue("attachedNone", !(data?.attachedNone == null || data?.attachedNone === ""));
        methods.setValue("cancelAvailability", data.cancelAvailability);
        methods.setValue("cancelCauseAvailability", data.cancelCauseAvailability);
        methods.setValue("cancelReason", data.cancelReason);

        methods.setValue("destinationId", data.destinationId);
        methods.setValue("destinationName", data.destinationName);
        methods.setValue("destinationExtra1", data.destinationExtra1);
        methods.setValue("corpNo", data.corpNo);
        methods.setValue("corpNameKanji", data.corpNameKanji);
        methods.setValue("corpNameKana", data.corpNameKana);
        methods.setValue("corpKind", data.corpKind);
        methods.setValue("corpKindEtc", data.corpKind === "B019_061" ? data.corpKindEtc : "");
        methods.setValue("corpKindBA", data.corpKindBA);
        methods.setValue("corpYubin", data.corpYubin);
        methods.setValue("corpPref", data.corpPref);
        methods.setValue("corpShikuchouson", data.corpShikuchouson);
        methods.setValue("corpBanchi", data.corpBanchi);
        methods.setValue("corpIkou", data.corpIkou);
        methods.setValue("representativeFamilyName", data.representativeFamilyName);
        methods.setValue("representativeFirstName", data.representativeFirstName);
        methods.setValue("corpTelNo1", data.corpTelNo1);
        methods.setValue("corpTelNo2", data.corpTelNo2);
        methods.setValue("corpTelNo3", data.corpTelNo3);
        methods.setValue("representativeFamilyNameKana", data.representativeFamilyNameKana);
        methods.setValue("representativeFirstNameKana", data.representativeFirstNameKana);
        methods.setValue("representativeYubin", data.representativeYubin);
        methods.setValue("representativePref", data.representativePref);
        methods.setValue("representativeShikuchouson", data.representativeShikuchouson);
        methods.setValue("representativeBanchi", data.representativeBanchi);
        methods.setValue("representativeIkou", data.representativeIkou);
        methods.setValue("representativeTelNo1", data.representativeTelNo1);
        methods.setValue("representativeTelNo2", data.representativeTelNo2);
        methods.setValue("representativeTelNo3", data.representativeTelNo3);
        methods.setValue("representativeMailAddress", data.representativeMailAddress);
        methods.setValue("ossName", data.ossName);
        methods.setValue("ossAddress", data.ossAddress);
        methods.setValue("ossSex", data.ossSex);
        methods.setValue("ossBirthday", format8DateToHaiphongDate(data.ossBirthday));
        methods.setValue("ossMailAddress", data.ossMailAddress);
        methods.setValue("ossTelNo1", data.ossTelNo1);
        methods.setValue("ossTelNo2", data.ossTelNo2);
        methods.setValue("ossTelNo3", data.ossTelNo3);
        methods.setValue("ossCorpNameKanji", data.ossCorpNameKanji);
        methods.setValue("ossCorpNameKana", data.ossCorpNameKana);
        methods.setValue("SendAttachFile", data.sendAttachFile);
        methods.setValue("cancelReason", data.cancelReason);
        methods.setValue("searchTitle", data.searchTitle);
        methods.setValue("searchBikou", data.searchBikou);
        setApplicationRows(data.rows);
        // methods.setValue("attachFileName", data.attachFileName);
    }
    
    const deleteTemporary = async () =>
    {
        setSaveDeleteConfirm("");

        SetProgressStatus(enumProgressStatus.tempSave_Delete);
        const apiDelete = await ApplicationApiFp(conf).apiApplicationIdDelete(params.id);

        try
        {
            const ret = await apiDelete();
            if (ret.status === 200)
            {
                setDialogWithClose("削除しました。");

            } else if (ret.status === 204)
            {
                setDialogWithClose("削除しました。");
            } else
            {
            }

        }
        catch (e)
        {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error")
            {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500)
            {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422)
            {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404)
            {
                setDialogWithClose("削除に失敗しました。");
            } else if (e.response.status === 405)
            {
                window.location.href = "/timeout";
            } else
            {
                setDialogWithClose("削除に失敗しました。");
            }
        }
    }

    const saveTemporary = async (info: UpdateApplicationInfo) => {
        SetProgressStatus(enumProgressStatus.tempSave_Save);
        const apiPut = await ApplicationApiFp(conf).apiApplicationIdPut(params.id, info);
        try {
            const ret = await apiPut();
            if (ret.status === 200) {
                setDialogWithClose("登録しました。");

            } else if (ret.status === 204) {
                setDialogWithClose("登録しました。");
            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
    };

    const saveTemporaryWithSend = async (info: UpdateApplicationInfo): Promise<boolean | undefined> => {
        const apiPut = await ApplicationApiFp(conf).apiApplicationIdPut(params.id, info);
        try {
            const ret = await apiPut();
            if (ret.status === 200) {
                return true;
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
        return false

    };


    const onMakeInfo = (data): UpdateApplicationInfo => {
        const sendData: UpdateApplicationInfo =
            {
                // sendFlag                                    : data.sendFlag,
                id: params.id,
                tempApplicationNo: data.tempApplicationNo?.substr(0, 20),
                applicationNo: data.applicationNo?.substr(0, 17),
                teishutumotoId: data.teishutumotoId?.substr(0, 9),
                applicationDate: data.applicationDate,
                tsuban: data.tsuban?.substr(0, 3),
                sikibetuJouhouY: formatHaiphongDateToY(data.sikibetuJouhouYMD),
                sikibetuJouhouM: formatHaiphongDateToM(data.sikibetuJouhouYMD),
                sikibetuJouhouD: formatHaiphongDateToD(data.sikibetuJouhouYMD),
                jigyousyoSeirikigouPref: data.jigyousyoSeirikigouPref?.substr(0, 2),
                jigyousyoSeirikigouGunshiku: data.jigyousyoSeirikigouGunshiku?.substr(0, 2),
                jigyousyoSeirikigouKigou: data.jigyousyoSeirikigouKigou?.substr(0, 4),
                jigyousyoSeirikigouBangou: data.jigyousyoSeirikigouBangou?.substr(0, 5),
                kenpoSikakuShutoku: data.kenpoSikakuShutoku?.substr(0, 5),
                kenpoHihuyousha: data.kenpoHihuyousha?.substr(0, 5),
                kenpoSikakuSoushitsu: data.kenpoSikakuSoushitsu?.substr(0, 5),
                kenpoGetsugakuHenkou: data.kenpoGetsugakuHenkou?.substr(0, 5),
                kenpoSanteiKiso: data.kenpoSanteiKiso?.substr(0, 5),
                kenpoShouyoShiharai: data.kenpoShouyoShiharai?.substr(0, 5),
                kenpoGoukei: data.kenpoGoukei?.substr(0, 5),
                kokuminNenkin3gou: data.kokuminNenkin3gou?.substr(0, 5),
                kokuminNenkinGoukei: data.kokuminNenkinGoukei?.substr(0, 5),
                bikou: data.bikou?.substr(0, 117),
                jigyoushoYubin: data.jigyoushoYubin?.substr(0, 7),
                jigyoushoPref: data.jigyoushoPref?.substr(0, 50),
                jigyoushoShikuchouson: data.jigyoushoShikuchouson?.substr(0, 50),
                jigyoushoBanchi: data.jigyoushoBanchi?.substr(0, 50),
                jigyoushoIkou: data.jigyoushoIkou?.substr(0, 50),
                jigyoushoName: data.jigyoushoName?.substr(0, 25),
                jigyounushiFamilyName: data.jigyounushiFamilyName?.substr(0, 25),
                jigyounushiFirstName: data.jigyounushiFirstName?.substr(0, 25),
                telNo1: data.telNo1?.substr(0, 5),
                telNo2: data.telNo2?.substr(0, 4),
                telNo3: data.telNo3?.substr(0, 5),
                applicantsY: formatHaiphongDateToY(data.applicantsYMD),
                applicantsM: formatHaiphongDateToM(data.applicantsYMD),
                applicantsD: formatHaiphongDateToD(data.applicantsYMD),
                sharoushiFamilyName: data.sharoushiFamilyName?.substr(0, 40),
                sharoushiFirstName: data.sharoushiFirstName?.substr(0, 40),
                tsuuchisyoKibou: data.tsuuchisyoKibou != null && data.tsuuchisyoKibou === false ? "" : "ON",
                corpName: data.corpName?.substr(0, 13),
                destHokenKumiaiNo: data.destHokenKumiaiNo?.substr(0, 8),
                destHokenKumiaiName: data.destHokenKumiaiName?.substr(0, 40),
                attachedPost: data.attachedPost != null && data.attachedPost === false ? "" : "ON",
                attachedE: data.attachedE != null && data.attachedE === false ? "" : "ON",
                attachedNone: data.attachedNone != null && data.attachedNone === false ? "" : "ON",
                cancelAvailability: data.cancelAvailability?.substr(0, 2),
                cancelCauseAvailability: data.cancelCauseAvailability?.substr(0, 2),
                cancelReason: data.cancelReason?.substr(0, 768),

                destinationId: data.destinationId?.substr(0, 10),
                destinationName: data.destinationName?.substr(0, 100),
                destinationExtra1: data.destinationExtra1?.substr(0, 100),
                corpNo: data.corpNo?.substr(0, 13),
                corpNameKanji: data.corpNameKanji?.substr(0, 25),
                corpNameKana: data.corpNameKana?.substr(0, 60),
                corpKind: data.corpKind?.substr(0, 8),
                corpKindEtc: data.corpKind === "B019_061" ? data.corpKindEtc?.substr(0, 60) : "",
                corpKindBA: data.corpKindBA?.substr(0, 8),
                corpYubin: data.corpYubin?.substr(0, 7),
                corpPref: data.corpPref?.substr(0, 50),
                corpShikuchouson: data.corpShikuchouson?.substr(0, 50),
                corpBanchi: data.corpBanchi?.substr(0, 50),
                corpIkou: data.corpIkou?.substr(0, 50),
                representativeFamilyName: data.representativeFamilyName?.substr(0, 12),
                representativeFirstName: data.representativeFirstName?.substr(0, 12),
                corpTelNo1: data.corpTelNo1?.substr(0, 5),
                corpTelNo2: data.corpTelNo2?.substr(0, 4),
                corpTelNo3: data.corpTelNo3?.substr(0, 4),
                representativeFamilyNameKana: data.representativeFamilyNameKana?.substr(0, 24),
                representativeFirstNameKana: data.representativeFirstNameKana?.substr(0, 24),
                representativeYubin: data.representativeYubin?.substr(0, 7),
                representativePref: data.representativePref?.substr(0, 40),
                representativeShikuchouson: data.representativeShikuchouson?.substr(0, 40),
                representativeBanchi: data.representativeBanchi?.substr(0, 40),
                representativeIkou: data.representativeIkou?.substr(0, 40),
                representativeTelNo1: data.representativeTelNo1?.substr(0, 5),
                representativeTelNo2: data.representativeTelNo2?.substr(0, 4),
                representativeTelNo3: data.representativeTelNo3?.substr(0, 4),
                representativeMailAddress: data.representativeMailAddress?.substr(0, 50),
                ossName: data.ossName?.substr(0, 50),
                ossAddress: data.ossAddress?.substr(0, 128),
                ossSex: data.ossSex?.substr(0, 1),
                ossBirthday: formatHaiphongDateTo8(data.ossBirthday),
                ossMailAddress: data.ossMailAddress?.substr(0, 50),
                ossTelNo1: data.ossTelNo1?.substr(0, 5),
                ossTelNo2: data.ossTelNo2?.substr(0, 4),
                ossTelNo3: data.ossTelNo3?.substr(0, 4),
                ossCorpNameKanji: data.ossCorpNameKanji?.substr(0, 25),
                ossCorpNameKana: data.ossCorpNameKana?.substr(0, 60),
                sendAttachFile: data.sendAttachFile != null && data.sendAttachFile === true ? 1 : 0,
                searchTitle: data.searchTitle?.substr(0, 100),
                searchBikou: data.searchBikou,
            };

        return sendData;
    }

    const GetMe = async () => {
        const conf = CommonConfiguration();
        const apiGet = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGet();
            if (ret.status !== 200) {
                setDialogWithGotoTop("権限がありません");
                return
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }
    }
    
    const onSaveDialog = () => {
        GetMe().then();
        setSaveConfirm("一時保存しますか？");
    }

    const onSaveDeleteDialog = () =>
    {
        GetMe().then();
        setSaveDeleteConfirm("一時保存を削除しますか？");
    }


    //本当のonSave
    const onSave = () => {
        SetProgressStatus(enumProgressStatus.tempSave_Save)
        const data = methods.getValues();
        const info = onMakeInfo(data);
        saveTemporary(info).then();
    };

    const onSaveDelete = () =>
    {
        

        SetProgressStatus(enumProgressStatus.tempSave_Delete)
        deleteTemporary().then();
    };
    // const onSave = async () => {
    //     SetProgressStatus(enumProgressStatus.saveAndSend_Save)
    //     const data = methods.getValues();
    //     const info = onMakeInfo(data);
    //     const flag = await saveTemporaryWithSend(info);
    //     if (flag) {
    //         SetProgressStatus(enumProgressStatus.saveAndSend_Send1)
    //     }
    // };
    const cancelSave = () => {
        setSaveConfirm("");
    }

    const cancelSaveDelete = () =>
    {
        setSaveDeleteConfirm("");
    }
    
    const onSendDialog = async () => {
        GetMe().then();
        const result = await trigger();
        const errorCount = Object.keys(methods.formState.errors).length;
        if ( errorCount > 0) {

            methods.setFocus(Object.keys(methods.formState.errors)[0]);
            setDialogMessage("項目の入力に誤りがあります。");
            return;
        }
        if(result) setSendConfirm("データ送信しますか？");
    }

    const onSubmit = async () => {
        SetProgressStatus(enumProgressStatus.saveAndSend_Save)
        const data = methods.getValues();
        const info = onMakeInfo(data);
        const flag = await saveTemporaryWithSend(info);
        if (flag) {
            SetProgressStatus(enumProgressStatus.saveAndSend_Send1)
        }
        setSendConfirm("");
    }
    
    const cancelSend = () => {
        setSendConfirm("");
    }

    //ポップアップ
    //リトライ系機構
    const [isPopupFinished, setIsPopupFinished] = useState(false);
    useEffect(() => {
        if (isPopupFinished) {
            switch (progressStatus) {
                case enumProgressStatus.readingStatusUpdate1:
                    SetProgressStatus(enumProgressStatus.readingStatusUpdate2);
                    break;
                case enumProgressStatus.saveAndSend_Send1:
                    SetProgressStatus(enumProgressStatus.saveAndSend_Send2);
                    break;
                case enumProgressStatus.refund_Send1:
                    SetProgressStatus(enumProgressStatus.refund_Send2);
                    break;
                case enumProgressStatus.readDocument_Read1:
                    SetProgressStatus(enumProgressStatus.readDocument_Read2);
                    break;
                default:
                    setDialogWithClose("システムでエラーが発生しました。E001");
                    break;
            }
        }
        setIsPopupFinished(false);
    }, [isPopupFinished])

    const afterPopup = (result: MynaPopupResult) => {
        if (result.result) {
            setIsPopupFinished(true);
            return;
        } else {
            setDialogWithClose("マイナポータルでの認証に失敗しました。");
            return;
        }
    };
    useEffect(() => {


        const handler = event => {
            if (isMynaPopupResult(event.data)) {
                const result = event.data as MynaPopupResult
                afterPopup(result);
            }
        };

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    }, []) // empty array => run only once

    const onSendApplication = async (isRetry: boolean) => {
        setIsLoaded(false);
        if ( applicationRows == null || applicationRows.length == 0 ){
            setDialogMessage("ファイルが添付されていません。");
            return;
        }

        const apiStatusUpdate = await ApplicationApiFp(conf).apiApplicationIdSendPost(params.id, applicationRows);
        try {
            const ret = await apiStatusUpdate();
            if (ret.status === 200) {
                setDialogWithClose("送信が完了しました");
            }
        } catch
            (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 401 || e.response.status === 403) {
                if (isRetry) {
                    setDialogWithClose("マイナポータル認証に失敗しました。");
                } else {
                    window.open("/oidc?detail=first", "MynaPopup", "top=100, left=100, width=400, height=400, rel=opener");
                    return;
                }
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
        SetProgressStatus(enumProgressStatus.input);

        setIsLoaded(true);
    }

    const onRefundDialog = async () => {
        GetMe().then();
        const result = await trigger("cancelReason");
        if ( result == false ) {
            const errorCount = Object.keys(methods.formState.errors).length;
            if (errorCount > 0) {

                methods.setFocus(Object.keys(methods.formState.errors)[0]);
                setDialogMessage("項目の入力に誤りがあります。");
            }
            return;
        }
        setRefundConfirm("取り下げしますか？");

    }
    
    const cancelRefund = () => {
        setRefundConfirm("");
    }
    
    const onRefund = async()=>{
        SetProgressStatus(enumProgressStatus.refund_Send1)
    }

    const onRefundImplement = async (isRetry : boolean) => {
        setRefundConfirm("");
        setIsLoaded(false);
        const reason: RefundReason = {
            reason: getValues("cancelReason"),
        }
        const api = await ApplicationApiFp(conf).apiApplicationIdRefundPut(params.id, reason);
        try {
            const ret = await api();
            if (ret.status === 200) {
                setDialogWithClose("取下げました");
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 401 || e.response.status === 403) {
                if (isRetry) {
                    setDialogWithClose("マイナポータル認証に失敗しました。");
                } else {
                    window.open("/oidc?detail=first", "MynaPopup", "top=100, left=100, width=400, height=400, rel=opener");
                    return;
                }
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
            SetProgressStatus(enumProgressStatus.input);
        }
        setRefundConfirm("");
        setIsLoaded(true);

    };

    const [readDocumentId, setReadDocumentId] = useState("");

    
    const readDocument =  (id? : string):void => {
        if ( id == null ){
            setReadDocumentId("");
        }else{
            setReadDocumentId(id);
            SetProgressStatus(enumProgressStatus.readDocument_Read1)
        }
        
        
    };


    const onReadDocumentImplement = async (isRetry : boolean) => {
        setIsLoaded(false);
        const apiGet = await ApplicationApiFp(conf).apiApplicationIdGetDocumentDocIdGet(params.id, readDocumentId);
        
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const b = base64ToBin(ret.data as any);
                
                const url = window.URL.createObjectURL(b);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `download.zip`,
                );
    
                // Append to html link element page
                document.body.appendChild(link);
    
                // Start download
                link.click();
    
                // Clean up and remove the link
                link.parentNode?.removeChild(link);

                setReadDocumentId("");
                SetProgressStatus(enumProgressStatus.input);
                setDialogMessage("公文書をダウンロードしました");
            } if (ret.status === 204) {
                setReadDocumentId("");
                SetProgressStatus(enumProgressStatus.input);
                setDialogMessage("公文書をダウンロード出来ませんでした。");
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 401 || e.response.status === 403) {
                if (isRetry) {
                    setDialogWithClose("マイナポータル認証に失敗しました。");
                } else {
                    window.open("/oidc?detail=first", "MynaPopup", "top=100, left=100, width=400, height=400, rel=opener");
                    return;
                }
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
        SetProgressStatus(enumProgressStatus.input);
        setIsLoaded(true);

    };
    
    
    const onFilesUpdate = (v: ApplicationFileInterface[]) => {
        setApplicationRows(v);

    }
    
    
    const statusKind = applicationInfo?.statusKind ?? -1
    const cancelAvailability = ((applicationInfo?.cancelAvailability === null) || (applicationInfo?.cancelAvailability === "")) ? -1 : 0;
    const cancelCauseAvailability = ((applicationInfo?.cancelCauseAvailability === null) || (applicationInfo?.cancelCauseAvailability === "")) ? -1 : 0;

    const fileInputDisable = ((statusKind === -1)|| (statusKind === 4)? false : true);
    const buttonTempSaveDisabled = ((statusKind === -1)|| (statusKind === 4)? false : true);
    const buttonTempSaveDeleteDisabled = (statusKind === -1) ? false : true;
    const buttonSendDisabled = ((statusKind === -1)|| (statusKind === 4)? false : true);
    const buttonCancelDisabled = ((((statusKind === 1) && (cancelAvailability !== -1)) || ((statusKind === 2) && (cancelAvailability !== -1)))? false : true);
    
    const cancelReason = (((statusKind === 1) && (cancelAvailability !== -1) && (cancelCauseAvailability !== -1)) || ((statusKind === 2) && (cancelAvailability !== -1) && (cancelCauseAvailability !== -1)) ? false : true);

    return (
        <main role="main" className="flex-shrink-0">
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}
            {dialogWithGotoTop !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoMenu} value={dialogWithGotoTop} style={DialogStyle.okOnly}/>}

            <Container fluid={"xl"}>
                <h1>申請編集</h1>
                <FormProvider {...methods}>
                    <Form noValidate>

                        {!isLoaded && <span><CommonSpinner/></span>}
                        {isLoaded &&
                        <span>
                            <ApplicationForms organization={organizationInfo} value={applicationInfo}/>
                            <ApplicationLogTable value={applicationInfo?.logs} readDocument={readDocument}/>

                            {fileInputDisable !== true && <ApplicationFilesForm disabled={fileInputDisable} onUpdate={onFilesUpdate} value={applicationRows}/>}
                            {fileInputDisable === true && <hr />}
                            
                            <Form.Group controlId="TextArea">
                                {!cancelReason && <div style={{marginBottom: "5px", marginLeft: "-20px"}}><RequiredImage/><br/>
                                    <span className="subLabel" style={{marginLeft: "20px"}}>(最大768文字)</span></div>}
                                <Form.Control readOnly={cancelReason} as="textarea" placeholder="取り下げ理由" className="cancelReason"
                                              {...register("cancelReason", {required: !cancelReason, maxLength: 768})}
                                              isInvalid={!!errors.cancelReason} />
                                {errors.cancelReason && errors.cancelReason.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                                {errors.cancelReason && errors.cancelReason.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は768文字までです。
                                </Form.Control.Feedback>
                                }
                            </Form.Group>
                            </span>

                        }


                        {isLoaded &&
                        <Form.Group as={Row}>
                            <Col md={4} style={{marginTop: 5}}>
                                <ThrottleButton variant="secondary" onClick={onCloseAndGotoList}><span className={"buttonLabel"}>申請一覧に戻る</span></ThrottleButton></Col>
                            <Col md={{span: 8}} style={{textAlign: "right", marginTop: 5}}>
                                <ThrottleButton disabled={buttonTempSaveDisabled} style={{marginLeft: 5, marginRight: 5}} onClick={onSaveDialog}><span className={"buttonLabel"}>一時保存</span></ThrottleButton>
                                    <ThrottleButton disabled={buttonTempSaveDeleteDisabled} style={{ marginLeft: 5, marginRight: 5 }} onClick={onSaveDeleteDialog}><span className={"buttonLabel"}>一時保存の削除</span></ThrottleButton>
                                {/*<Button disabled={buttonSendDisabled} type={"submit"} style={{margin: 5}}><FontAwesomeIcon icon={faPaperPlane}/><span className={"buttonLabel"}>データの送信</span></Button>*/}
                                <ThrottleButton disabled={buttonSendDisabled} onClick={onSendDialog} style={{marginLeft: 5, marginRight: 5}}><span className={"buttonLabel"}>データの送信</span></ThrottleButton>
                                <ThrottleButton disabled={buttonCancelDisabled} onClick={onRefundDialog} ><span className={"buttonLabel"}>取り下げの送信</span></ThrottleButton>
                                {saveConfirm !== "" && <CommonDialogBox value={saveConfirm} style={DialogStyle.yesNo} onClickYes={onSave} onClickNo={cancelSave} />}
                                    {saveDeleteConfirm !== "" && <CommonDialogBox value={saveDeleteConfirm} style={DialogStyle.yesNo} onClickYes={onSaveDelete} onClickNo={cancelSaveDelete} />}
                                {sendConfirm !== "" && <CommonDialogBox value={sendConfirm} style={DialogStyle.yesNo} onClickYes={onSubmit} onClickNo={cancelSend} />}
                                {refundConfirm !== "" && <CommonDialogBox value={refundConfirm} style={DialogStyle.yesNo} onClickYes={onRefund} onClickNo={cancelRefund} />}
                            </Col>
                        </Form.Group>
                        }

                    </Form>
                </FormProvider>
            </Container>
        </main>);

}

export default withRouter(UserRouteApplicationEdit);

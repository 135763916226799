//react 
import React, {useCallback,  useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Alert, Col, Row} from "react-bootstrap";
import {useDropzone} from "react-dropzone";

//common
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileImport} from "@fortawesome/free-solid-svg-icons";


//local
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import CommonSpinner from "../common/CommonSpinner";
import {arrayBufferToBase64} from "../common/CommonBinary";
import {checkExtension, checkExtensionCsv} from "../common/CommonValidation";
import ThrottleButton from "./ThrottleButton";

export enum kindFile{
    SendFile,
    InsuranceFile,
    
}

interface Props extends RouteComponentProps {
    fileName?: string,
    isExistsSetup?: boolean,
    OnSetFileName: (fileName?: string) => void,
    OnSetFileBinary: (binary ?: string) => void,
    kind: kindFile,
    accept: string,
    disabled: boolean,
}


const CommonFileDropRow = (props: Props) => {


    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isFileLoaded, setIsFileLoaded] = useState(false);


    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            props.OnSetFileName("");
            props.OnSetFileBinary("");

            setDialogMessage("複数ファイルは設定出来ません。");
            setIsFileLoading(false);
            return;
        }
        acceptedFiles.forEach((file) => {
            setIsFileLoading(true);
            props.OnSetFileName("");
            props.OnSetFileBinary("");

            //validation
            if ( props.kind === kindFile.SendFile){
                if (!checkExtension(file.name)) {
                    props.OnSetFileName("");
                    props.OnSetFileBinary("");
                    setDialogMessage("指定されたファイル種類以外は設定出来ません。");
                    setIsFileLoading(false);
                    return;
                }
                if (file.name.length > 128) {
                    props.OnSetFileName("");
                    props.OnSetFileBinary("");
                    setDialogMessage("ファイル名は128文字を超えることが出来ません。");
                    setIsFileLoading(false);
                    return;
                }
            }else{
                if (!checkExtensionCsv(file.name)) {
                    props.OnSetFileName("");
                    props.OnSetFileBinary("");
                    setDialogMessage("指定されたファイル種類以外は設定出来ません。");
                    setIsFileLoading(false);
                    return;
                }
                
            }
            if (props.OnSetFileName != null) {
                props.OnSetFileName(file.name);
            }
            const reader = new FileReader();

            reader.onload = () => {
                const binaryStr = reader.result
                if ( props.kind === kindFile.SendFile) {
                    if ((binaryStr as ArrayBuffer).byteLength > 4718592)
                    {
                        props.OnSetFileName("");
                        props.OnSetFileBinary("");

                        setIsFileLoading(false);
                        setIsFileLoaded(true);
                        
                        setDialogMessage("ファイルは4.5MB以上を設定することが出来ません");
                        return;
                    }
                }
                const base64String = arrayBufferToBase64(binaryStr);
                props.OnSetFileBinary(base64String);
                setIsFileLoading(false);
                setIsFileLoaded(true);

            }
            reader.readAsArrayBuffer(file)

        })

    }, [])
    const accept = props.accept;
    const {getRootProps,getInputProps, open} = useDropzone({accept, onDrop})



    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        props.history.push("/menu");
    }

    const cursor = props.disabled ? {cursor: "default"} : {cursor: "pointer"};

    const Loading = (<CommonSpinner/>)

    const Loaded = (

        <Row >
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            {/*{props.isExistsSetup && <span>すでに過去にデータを保存しています</span>}*/}
            <Col md={{span: 9, order: 0, offset: 1}}>

                <Row  {...getRootProps()}>
                    <Alert variant={"info"} style={{width: "100%", textAlign: "center"}}>
                        {
                            isFileLoading ?
                                <span><CommonSpinner/></span> :
                                props.fileName == null || props.fileName === "" ?
                                    <span><FontAwesomeIcon icon={faFileImport} style={cursor}/></span>
                                    : props.isExistsSetup 
                                        ?<span>{(props.fileName + "が設定されています")}</span>
                                        : <span>{(props.fileName + "が読み込まれました")}</span>

                        }
                    </Alert>
                </Row>

            </Col>
            <Col md={2} >
                <input disabled={props.disabled} {...getInputProps()} multiple={false} />
                <ThrottleButton variant="success" onClick={open}
                        disabled={props.disabled || isFileLoading}>選択</ThrottleButton>
            </Col>
        </Row>


    );
    return (!isFileLoading ? Loaded : Loading);

}

export default withRouter(CommonFileDropRow);


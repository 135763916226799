//react
import React, {useEffect, useState} from "react";
import {Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import {useParams} from "react-router";
import {RouteComponentProps, withRouter} from "react-router-dom";

//common
import {right} from "@popperjs/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusSquare} from "@fortawesome/free-solid-svg-icons";

//local
import CommonAdminAccountEditBox from "../common/CommonAdminAccountEditBox";
import CommonDialogBox from "../common/CommonDialogBox";
import AdminAccountTable from "./AdminAccountTable";
import {faCaretSquareUp} from "@fortawesome/free-solid-svg-icons/faCaretSquareUp";
import ThrottleButton from "../control/ThrottleButton";

interface Props extends RouteComponentProps {
    value: string
}

const AdminRouteMemberList = (props: Props) => {
    document.title = "アカウント一覧";

    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }


    //検索用ボタン
    const [searchCondition, setSearchCondition] = useState("");
    const [tempSearchString, setTempSearchString] = useState("");

    const [searchFlag, setSearchFlag] = useState(false);
    const onSearchClick=() =>{
        setSearchFlag(true);
    }
    useEffect(() => {
        if ( searchFlag) {
            setSearchCondition(tempSearchString);
            setSearchFlag(false);
        }
    }, [searchFlag]);

    const onSearchChange=(event)=> {
        const value = event.target.value;
        setTempSearchString(value);
    }


    const [oid, setOid] = useState("");
    const [updateFlag, setUpdateFlag] = useState(false);

    const [isOpenNewWindow, setIsOpenNewWindow] = useState(false);

    const temp: { id: string } = useParams();



    const onCreate = async () => {
        setIsOpenNewWindow(true);
    };


    const init = () => {
        setOid(temp.id);
    }

    function onReturnMenu() {
        props.history.push("/admin/organizationlist");
    }

    const onUpdate = () => {
        setIsOpenNewWindow(false);
        setUpdateFlag(!updateFlag);
    }

    useEffect(() => {
        init();
    }, [updateFlag]);

    return (
        <main role="main" className="flex-shrink-0">
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            
            
            
            <Container fluid={"xl"}>
                <h1>アカウント一覧</h1>
                <Row>
                    <Col xs={12} style={{textAlign: right}}>
                        <ThrottleButton variant="primary" onClick={onCreate}>
                            新規登録</ThrottleButton>
                    </Col>
                </Row>
                <div style={{marginTop: "0.5rem"}}>
                </div>
                <Row>
                    <Col xs={9}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">検索キーワード</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="検索キーワード"
                                aria-label="検索キーワード"
                                aria-describedby="検索キーワード"
                                value={tempSearchString}
                                onChange={onSearchChange}
                                maxLength={50}
                            />

                        </InputGroup>
                    </Col>
                    <Col xs={3}>
                        <ThrottleButton variant="primary" onClick={onSearchClick}>検索</ThrottleButton>
                    </Col>
                </Row>
                <div style={{marginTop: "0.5rem"}}><AdminAccountTable searchCondition={searchCondition} oid={oid} updateFlag={updateFlag}/>
                </div>
                {/*{isOpenNewWindow && oid !== "" && <AdminAccountCreateBox oid={oid} onClose={onUpdate}/>}*/}
                {isOpenNewWindow && oid !== "" && <CommonAdminAccountEditBox value={undefined} method={"POST"} readonly={false} title={"アカウント登録"} organizationId={oid} refresh={true} onUpdate={onUpdate}/>}

                <Row>
                    <Col xs={12}><ThrottleButton variant="secondary" onClick={onReturnMenu}>企業一覧に戻る</ThrottleButton></Col>
                </Row>
            </Container>
        </main>
    );
}

export default withRouter(AdminRouteMemberList);

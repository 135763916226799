//react 
import React, {useEffect, useState} from "react";
import {withRouter, RouteComponentProps} from "react-router-dom";
import { Card, CardGroup, Container} from "react-bootstrap";


//common
import parse from 'html-react-parser';

//local
import {CommonConfiguration} from "../common/CommonSetting";
import {GetAccountInfo, UserApiFp, GetSystemMessageList, SystemMessageApiFp} from "../client-axios";
import {levelKindEnumManager, levelKindEnumNotSet} from "../common/CommonDefine";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import {isMynaPopupResult} from "../common/CommonComm";
import {MynaPopupResult} from "./UserRouteOidc";
import DebounceButton from "../control/ThrottleButton";
import ThrottleButton from "../control/ThrottleButton";


interface Props extends RouteComponentProps {
}


const UserRouteMenu = (props: Props) => {
    document.title = "メニュー";

    const [account, setAccount] = useState<GetAccountInfo | undefined>(undefined);
    const [message, setMessage] = useState<GetSystemMessageList | undefined>(undefined);
    const conf = CommonConfiguration();

    //ダイアログ
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogMessage("");
        setDialogWithClose("");
        window.location.href = "/timeout";
    }
    
    const getMeAsync = async () => {
        
        const apiGet = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                setAccount(ret.data);
            }
        } catch (e:any) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }

    }

    const getSystemMessageAsync = async () => {

        const apiGet = await SystemMessageApiFp(conf).apiSystemMessageGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                setMessage(ret.data);
            }
        } catch (e:any) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }

    }

    useEffect(() => {
        Promise.all([
            getMeAsync(),
            getSystemMessageAsync()
        ]);
    }, [])


    
    const onGotoApplicationList = () => {
        props.history.push("/applicationList");
    }
    const onGotoMyAccount = () => {
        props.history.push("/myaccount");
    }
    const onGotoMember = () => {
        props.history.push("/member");
    }
    const onGotoOrganization = () => {
        props.history.push("/organization");
    }

    //ポップアップ
    useEffect(() => {
        const handler = event => {
            if ( isMynaPopupResult(event.data)) {
                const result = event.data as MynaPopupResult
            }
        };

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    }, []) // empty array => run only once
    
    const systemMessage = (
        message != null && message?.items?.map((item) => {
            return (item.viewCode == "004") &&
                    <div key={item.viewCode}>{parse(item.message ?? "")}</div>
        }));
    

    return (

        <main role="main" className="flex-shrink-0">

            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            
            <Container fluid={"xl"}>
                {systemMessage}
                <h1>メニュー</h1>
                <CardGroup>

                    
                    <Card className={"mr-3"} style={{width: '18rem'}}>
                        <Card.Title className={"card-title bg-primary"}>申請管理</Card.Title>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <Card.Text>
                                申請一覧より、申請書の検索、新規作成、編集を実施します。
                            </Card.Text>
                            <div style={{textAlign: 'center'}}>
                                <ThrottleButton variant={"primary"} onClick={onGotoApplicationList}>一覧へ</ThrottleButton>
                            </div>
                        </Card.Body>
                    </Card>


                    <Card className={"mr-3"} style={{width: '18rem'}}>
                        <Card.Title className={"card-title bg-primary"}>マイアカウント</Card.Title>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <Card.Text>
                                マイアカウントの編集を実施します。
                            </Card.Text>
                            <div style={{textAlign: 'center'}}>
                                <ThrottleButton variant={"primary"} onClick={onGotoMyAccount}>編集へ</ThrottleButton>
                            </div>
                        </Card.Body>
                    </Card>

                    {(account?.levelKind ?? levelKindEnumNotSet) >= levelKindEnumManager &&
                    <Card className={"mr-3"} style={{width: '18rem'}}>
                        <Card.Title className={"card-title bg-primary"}>アカウント管理</Card.Title>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <Card.Text>
                                アカウント一覧より、アカウントの検索、新規作成、編集、削除を実施します。
                            </Card.Text>
                            <div style={{textAlign: 'center'}}>
                                <ThrottleButton variant={"primary"} onClick={onGotoMember}>一覧へ</ThrottleButton>
                            </div>
                        </Card.Body>
                    </Card>

                    }

                    {(account?.levelKind ?? levelKindEnumNotSet) >= levelKindEnumManager &&
                    <Card className={"mr-3"} style={{width: '18rem'}}>
                        <Card.Title className={"card-title bg-primary"}>企業情報</Card.Title>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <Card.Text>
                                企業情報の編集を実施します。
                            </Card.Text>
                            <div style={{textAlign: 'center'}}>
                                <ThrottleButton variant={"primary"} onClick={onGotoOrganization}>編集へ</ThrottleButton>
                            </div>
                        </Card.Body>
                    </Card>
                    }

                </CardGroup>
            </Container>
        </main>


    );

}

export default withRouter(UserRouteMenu);


import {MynaPopupResult} from "../user/UserRouteOidc";





export const isMynaPopupResult = (value: unknown): value is MynaPopupResult => typeof value === 'object' &&
    value !== null &&
    typeof (value as MynaPopupResult).source === 'string' &&
    typeof (value as MynaPopupResult).result === 'boolean' &&
    typeof (value as MynaPopupResult).access_key === 'string';





//react
import React from "react"
import {Col, Row} from "react-bootstrap";
import {GetApplicationLog} from "../client-axios";
import {formatHaiphongDateToSlashYMD} from "../common/CommonFormat";
import ThrottleButton from "../control/ThrottleButton";

//common

//local

interface Props {
    value: GetApplicationLog
    readDocument: (logMessageURL? : string) => void;
}

const ApplicationLogRow = (props: Props) => {
    const onClick=()=>{
        props.readDocument(props.value.logMessageURL);
    }    
    return (
        <Row className="alert-info">
            <Col className="alert-info " md={2}>{formatHaiphongDateToSlashYMD(props.value.receiveDate)}</Col>
            <Col className="alert-info  break-word" md={2}>{props.value.logMessage}</Col>
            <Col className="alert-info  break-word" md={6}>{props.value.logMessageFull}</Col>
            { (props.value.logMessageURL == null || props.value.logMessageURL === "") ?
                <Col className="alert-info  break-word" md={2}></Col>
                : <Col className="alert-info  break-word" md={2}><ThrottleButton variant={"success"} onClick={onClick} className={"document"}>公文書ダウンロード</ThrottleButton></Col>}
        </Row>
    )
}
;

export default ApplicationLogRow;

//react 
import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container } from "react-bootstrap";


//common
import parse from 'html-react-parser';

//local
import { CommonConfiguration } from "../common/CommonSetting";
import { GetSystemMessageList, SystemMessageApiFp } from "../client-axios";
import CommonDialogBox, { DialogStyle } from "../common/CommonDialogBox";


interface Props extends RouteComponentProps {
}


const UserRouteMaintenance = (props: Props) => {
    document.title = "メンテナンス";

    const [message, setMessage] = useState<GetSystemMessageList | undefined>(undefined);
    const conf = CommonConfiguration();

    //ダイアログ
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }

    const onCloseAndGotoList = () => {
        setDialogMessage("");
        setDialogWithClose("");
        window.location.href = "/timeout";
    }

    const getSystemMessageAsync = async () => {

        const apiGet = await SystemMessageApiFp(conf).apiSystemMessageGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                setMessage(ret.data);
            }
        } catch (e: any) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }

    }

    useEffect(() => {
        Promise.all([
            getSystemMessageAsync()
        ]);
    }, [])

    const systemMessage = (
        message != null && message?.items?.map((item) => {
            return (item.viewCode == "001") &&
                <div key={item.viewCode}>{parse(item.message ?? "")}</div>
        }));


    return (
        <>
            <header>
                <div className="container-xl topLogo">
                    <img className="logo" src="/logo.png" alt={"logo"} />
                </div>
            </header>

            <main role="main" className="flex-shrink-0">
                {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk} />}
                {dialogWithClose !== "" &&
                    <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly} />}


                <Container fluid={"xl"}>
                    {systemMessage}
                </Container>
            </main>
        </>




    );

}

export default withRouter(UserRouteMaintenance);


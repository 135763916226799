//react 
import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";

import {FormProvider, useForm} from "react-hook-form";
//common
//local
import {AdminApiFp, GetOrganizationInfo, InsuranceItem, PutAdminOrganizationInfo} from "../client-axios";
import {CommonConfiguration} from "../common/CommonSetting";
import OrganizationEdit from "./OrganizationEdit";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import CommonSpinner from "../common/CommonSpinner";
import ThrottleButton from "../control/ThrottleButton";


interface Props extends RouteComponentProps {
    value: string,
    onUpdate: () => void
}

const OrganizationEditBox = (props: Props) => {
    const methods = useForm({mode: 'onBlur'});
    const {trigger} = methods;

    //ダイアログ
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");

    const [insuranceItems, setInsuranceItems] = useState<InsuranceItem[] | undefined>();
    const [loadData, setLoadData] = useState<GetOrganizationInfo | undefined>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState("");

    
    //初期イベント
    const GetData = async () => {
        const conf = CommonConfiguration();
        const result = await AdminApiFp(conf).apiAdminOrganizationIdGet(props.value);
        try {
            const ret = await result();
            if (ret.status === 200) {
                const resultData: GetOrganizationInfo = ret.data;
                setLoadData(resultData);
                setInsuranceItems(resultData.insuranceList);
                setIsLoaded(true);
            } else {
                setDialogWithClose("エラー : " + ret.status);
                setIsLoaded(true);
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                setIsLoaded(true);
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
                setIsLoaded(true);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
                setIsLoaded(true);
            } else if (e.response.status === 404) {
                setIsLoaded(true);
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                setLoadData(undefined);
                setIsLoaded(true);
            }
        }

    };

    useEffect(() => {
        GetData().then();
    }, []);


    const onSubmit = async () => {

        const data = methods.getValues();
        const conf = CommonConfiguration();
        const sendData: PutAdminOrganizationInfo =
            {
                id: loadData!.id,
                corpNo: data.corpNo,
                corpNameKanji: data.corpNameKanji,
                corpNameKana: data.corpNameKana,
                corpKind: data.corpKind,
                corpKindEtc: data.corpKind === "B019_061" ? data.corpKindEtc : "",
                corpKindBA: data.corpKindBA,
                provider: data.provider,
                clientId: data.clientId,
                signInUrl: data.signInUrl,
                corpYubin: data.corpYubin,
                corpPref: data.corpPref,
                corpShikuchouson: data.corpShikuchouson,
                corpBanchi: data.corpBanchi,
                corpIkou: data.corpIkou,
                representativeFamilyName: data.representativeFamilyName,
                representativeFirstName: data.representativeFirstName,
                corpTelNo1: data.corpTelNo1,
                corpTelNo2: data.corpTelNo2,
                corpTelNo3: data.corpTelNo3,
                representativeFamilyNameKana: data.representativeFamilyNameKana,
                representativeFirstNameKana: data.representativeFirstNameKana,
                representativeYubin: data.representativeYubin,
                representativePref: data.representativePref,
                representativeShikuchouson: data.representativeShikuchouson,
                representativeBanchi: data.representativeBanchi,
                representativeIkou: data.representativeIkou,
                representativeTelNo1: data.representativeTelNo1,
                representativeTelNo2: data.representativeTelNo2,
                representativeTelNo3: data.representativeTelNo3,
                representativeMailAddress: data.representativeMailAddress,
                insuranceList: insuranceItems
            };
        const apiPut = await AdminApiFp(conf).apiAdminOrganizationIdPut(loadData!.id, sendData);
        try {
            const ret = await apiPut();
            if (ret.status === 200) {
                setDialogWithClose("登録しました。");

            } else if (ret.status === 204) {
                setDialogWithClose("登録しました。");
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("登録に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("登録に失敗しました。");
            }
        }
        setConfirmDialog("");
    };

    const onSaveDialog = async () => {
        const result = await trigger();
        const errorCount = Object.keys(methods.formState.errors).length;
        if ( errorCount > 0) {

            methods.setFocus(Object.keys(methods.formState.errors)[0]);
            setDialogMessage("項目の入力に誤りがあります。");
            return;
        }
        if(result) setConfirmDialog("保存しますか？");
    }

    const onCancel = () => {
        setConfirmDialog("");
    }
    
    function onReturnMenu() {
        props.onUpdate();
    }

    const onDialogOk = () => {
        setDialogMessage("");
    }
    const Loading = (<CommonSpinner/>)

    const loaded = <FormProvider {...methods}>
            <Form noValidate>

                <Modal.Header>
                    <Modal.Title>企業情報編集</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dialogStyle">

                    <div className="bodyStyle"
                         style={{height: "80%", maxHeight: "600px", overflowY: "scroll", overflowX: "hidden"}}>
                        <OrganizationEdit value={loadData} isHiddenDisplayProvider={false}
                                          onGotoMenu={onReturnMenu}
                                          insuranceItems={insuranceItems}
                                          setInsuranceItems={setInsuranceItems}
                        />

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <footer className="footStyle">
                        <Row>
                            <Col xs={12}>
                                <ThrottleButton variant={"secondary"}
                                        onClick={props.onUpdate}>戻る</ThrottleButton>
                                <ThrottleButton style={{position: "absolute", right: 0}} variant={"primary"} onClick={onSaveDialog}>OK</ThrottleButton>
                            </Col>
                        </Row>
                    </footer>
                </Modal.Footer>
            </Form>
        {confirmDialog !== "" && <CommonDialogBox value={confirmDialog} style={DialogStyle.yesNo} onClickYes={onSubmit} onClickNo={onCancel} />}
        </FormProvider>
    ;
    return (
        <Modal size={"xl"} show={true} className={"CommonDialogBox"}>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onReturnMenu} value={dialogWithClose} style={DialogStyle.okOnly}/>}
            
            <div>
                {(isLoaded ? loaded : Loading)}
            </div>
        </Modal>
    );
}


export default withRouter(OrganizationEditBox);



//react 
import React from "react";


//common


//local
import {InsuranceItem} from "../client-axios";
import OrganizationInsuranceRow from "./OrganizationInsuranceRow";



interface Props {
    value ?: Array<InsuranceItem>,
    onDeleted: (id) => void,
    onEdited: (item : InsuranceItem) => void,
}



 const OrganizationInsuranceTable =  (props: Props) => {
    return(
        <table className="table">
            <thead>
            <tr>
                <th>
                    提出先
                </th>
                <th>
                    名称
                </th>
                <th>
                    事業所都道府県
                </th>
                <th>
                    群市区
                </th>
                <th>
                    記号
                </th>
                <th>
                    番号
                </th>
                <th>
                    編集
                </th>
                <th>
                    削除
                </th>
            </tr>
            </thead>
            <tbody>
            {props.value != null && props.value.map(x=>{
                return <OrganizationInsuranceRow key={x.id} value={x} onDeleted={props.onDeleted} onEdited={props.onEdited} />
            })}
            </tbody>
        </table>
    );
}


export default (OrganizationInsuranceTable);


//react 
import React, {useEffect, useState} from "react";
import { withRouter, RouteComponentProps} from "react-router-dom";
import {Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";

//common
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import {right} from "@popperjs/core";


//local
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import UserMemberTable from "./UserMemberTable";
import CommonAccountEditBox from "../common/CommonAccountEditBox";
import {CommonConfiguration} from "../common/CommonSetting";
import {GetAccountInfo, UserApiFp} from "../client-axios";
import CommonSpinner from "../common/CommonSpinner";
import {levelKindEnumManager, levelKindEnumNotSet} from "../common/CommonDefine";
import ThrottleButton from "../control/ThrottleButton";


interface Props extends RouteComponentProps {
}


const UserRouteMemberList = (props: Props) => {
    document.title = "アカウント一覧";

    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogWithGotoMenu, setDialogWithGotoMenu] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        props.history.push("/menu");
    }
    const onCloseAndGotoMenu = () => {
        setDialogWithGotoMenu("");
        props.history.push("/menu");
    }

    //検索用ボタン
    const [searchCondition, setSearchCondition] = useState("");
    const [tempSearchString, setTempSearchString] = useState("");

    const [searchFlag, setSearchFlag] = useState(false);
    const onSearchClick = async () =>{
        const b = await CheckAuth()
        if ( b ) {
            setSearchFlag(true);
        }
    }
    useEffect(() => {
        if ( searchFlag) {
            setSearchCondition(tempSearchString);
            setSearchFlag(false);
        }
    }, [searchFlag]);

    const onSearchChange=(event)=> {
        const value = event.target.value;
        setTempSearchString(value);
    }


    
    
    //ローディング
    const [isLoaded, setIsLoaded] = useState(false);


    const [updateFlag, setUpdateFlag] = useState(false);

    const [isOpenNewWindow, setIsOpenNewWindow] = useState(false);
    const [prevLevelKind, setPrevLevelKind] = useState<number|undefined>(undefined);
    const [nowLevelKind, setNowLevelKind] = useState<number|undefined>(undefined);
    const [gotoMenu, setGotoMenu] = useState<boolean>(false);

    const onCreate = async () => {
        const b = await CheckAuth()
        if ( b ) {
            setIsOpenNewWindow(true);
        }
    };


    function onReturnMenu() {
        props.history.push("/menu");
    }

    const onUpdate = () => {
        setIsOpenNewWindow(false);
        setUpdateFlag(!updateFlag);
    };

    const LoadData = async () => {
        const conf = CommonConfiguration();
        const apiGet = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const x : GetAccountInfo = ret.data;
                if (x !== undefined) {
                    setPrevLevelKind(ret.data.levelKind);
                    //種類ガード
                    if ( (x.levelKind ?? levelKindEnumNotSet) < levelKindEnumManager ) {
                        props.history.push("/menu");
                    }
                }
            }
            setIsLoaded(true);
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
            setIsLoaded(true);
        }

    }

    const CheckAuth = async () : Promise<boolean> => {
        const conf = CommonConfiguration();
        const apiGet = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const x: GetAccountInfo = ret.data;
                if(x !== undefined) {
                    setNowLevelKind(x.levelKind);
                    if (prevLevelKind === 2 && x.levelKind === 1) {
                        setDialogWithClose("権限がありません");
                        setGotoMenu(true);
                        return false;
                    }
                }
            }
            return true;
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("権限がありません");
                setGotoMenu(true);
                return false;
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
            setIsLoaded(true);
            return false;
        }
        return true;
    }

    useEffect(() => {
        LoadData().then();
    }, [])

    const Loading = (<CommonSpinner />)
    const loaded = (
        <main role="main" className="flex-shrink-0">
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}
            {dialogWithGotoMenu !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoMenu} value={dialogWithGotoMenu} style={DialogStyle.okOnly}/>}

            <Container fluid={"xl"}>
                <h1>アカウント一覧</h1>
                <Row>
                    <Col xs={12} style={{textAlign: right}}>
                        <ThrottleButton variant="primary" onClick={onCreate}>
                            新規登録</ThrottleButton>
                    </Col>
                </Row>
                <div style={{marginTop: "0.5rem"}}>
                </div>
                <Row>
                    <Col xs={9}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">検索キーワード</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="検索キーワード"
                                aria-label="検索キーワード"
                                aria-describedby="検索キーワード"
                                value={tempSearchString}
                                onChange={onSearchChange}
                                maxLength={50}
                            />

                        </InputGroup>
                    </Col>
                    <Col xs={3}>
                        <ThrottleButton variant="primary" onClick={onSearchClick}>検索</ThrottleButton>
                    </Col>
                </Row>
                <div style={{marginTop: "0.5rem"}}><UserMemberTable searchCondition={searchCondition} updateFlag={updateFlag}/>
                </div>
                {/*{isOpenNewWindow && oid !== "" && <AdminAccountCreateBox oid={oid} onClose={onUpdate}/>}*/}
                {isOpenNewWindow && <CommonAccountEditBox value={undefined} method={"POST"} readonly={false} title={"アカウント登録"} refresh={true} onUpdate={onUpdate}/>}

                <Row>
                    <Col xs={12}><ThrottleButton variant="secondary" onClick={onReturnMenu}>メニューに戻る</ThrottleButton></Col>
                </Row>
            </Container>
        </main>
    );
    return (isLoaded ? loaded : Loading);
}



export default withRouter(UserRouteMemberList);


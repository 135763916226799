//react 
import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";

//common

//local
import {GetAccountInfo, UserApiFp} from "../client-axios";
import {CommonConfiguration} from "../common/CommonSetting";
import Paging from "../common/Paging";
import UserMemberRow from "./UserMemberRow";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import CommonSpinner from "../common/CommonSpinner";


interface Props {
    searchCondition: string,//searchCondition
    updateFlag?: boolean
}

const UserMemberTable = (props: Props) => {
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [rows, setRows] = useState<GetAccountInfo[] | undefined>([]);
    const [displayRows, setDisplayRows] = useState<GetAccountInfo[] | undefined>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [updateFlag, setUpdateFlag] = useState(props.updateFlag);

    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
    }


    const onPaging = (v: number) => {
        if (v < 0) {
            setPage(0);
        } else if (maxPage < v) {
            setPage(maxPage);
        } else {
            setPage(v);
        }
    }

    const paginate = (array, page_size, page_number) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }
    const itemsPerPage = 10;

    const FilterData = async () => {
        if (rows != null) {
            const rows2 = rows.filter(x => (x.ossMailAddress != null && x.ossMailAddress.includes(props.searchCondition))
                                            || x.ossName != null && x.ossName.includes(props.searchCondition))
            if (rows2 != null) {
                let max = Math.ceil((rows2.length) / itemsPerPage );
                max = max === 0 ? 0 : max - 1;
                setPage(page > max ? max : page);
                setMaxPage(max);
            } else {
                setPage(0);
                setMaxPage(0);
            }
            const rows3 = paginate(rows2, itemsPerPage, page + 1);
            setDisplayRows(rows3);
        } else {
            setDisplayRows(undefined);
        }
    }

    const LoadData = async () => {
        setIsLoaded(false);
        const conf = CommonConfiguration();
        const apiGetMember = await UserApiFp(conf).apiMemberGet();
        try {
            const ret = await apiGetMember();
            if (ret.status === 200) {
                const x = ret.data;
                if (x !== undefined) {
                    setRows(x);
                }
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 403) {
                window.location.href = "/menu"
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
            setRows(undefined);
        }
        setIsLoaded(true);
    }

    const onUpdate = () => {
        setUpdateFlag(!updateFlag);
    }

    useEffect(() => {
        LoadData().then();
    }, [updateFlag, props.updateFlag]);

    useEffect(() => {
        FilterData().then();
    }, [rows, props.searchCondition, page])


    const Loading = (<CommonSpinner/>)

    const Loaded = (
        <div>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}
            <Row>
                <table className="table">
                    <thead>
                    <tr>
                        <th>名前</th>
                        <th>メールアドレス</th>
                        <th>種類</th>
                        <th>編集</th>
                        <th>削除</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        displayRows !== undefined && displayRows.map(v => {
                            return <UserMemberRow key={v.id} value={v} onUpdate={onUpdate}/>
                        })}
                    </tbody>
                </table>
            </Row>
            <Paging value={page} onPaging={onPaging} maxPage={maxPage}/>
        </div>
    );
    return (isLoaded ? Loaded : Loading);

}

export default (UserMemberTable)

//react
import React from "react"
import {Col, Row} from "react-bootstrap";
import {GetApplicationLog} from "../client-axios";
import ApplicationLogRow from "./ApplicationLogRow";

//common

//local

interface Props {
    value: GetApplicationLog[] | undefined
    readDocument: (id? : string) => void;

}

const ApplicationLogTable = (props: Props) => {

    return (
        <div>
            <h3>ログ</h3>
            <div className="logtable alart alart-info" style={{minHeight: "40px"}}>
                <Row className="applicationLogRow">
                    {/*<Col xs={2}>送信日時</Col>*/}
                    {/*<Col xs={4}>メッセージ</Col>*/}
                    {/*<Col xs={6}>ファイルURL</Col>*/}
                </Row>
                {props.value !== undefined && props?.value.map(x => <ApplicationLogRow key={x.id} value={x} readDocument={props.readDocument}/>)}
            </div>
        </div>
    )
}

export default ApplicationLogTable;

//react 
import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {RouteComponentProps, withRouter} from "react-router-dom";


//common
import {faHome, faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


//local
import {CommonConfiguration} from "../common/CommonSetting";
import {InsuranceMasterApiFp} from "../client-axios";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import CommonFileDropRow, {kindFile} from "../control/CommonFileDropRow";
import ThrottleButton from "../control/ThrottleButton";

interface Props extends RouteComponentProps {
}


const AdminRouteInsuranceMaster = (props: Props) => {
    document.title = "保険組合マスター管理";

    const [binary, setBinary] = useState<string | undefined>("");
    const [fileName, setFileName] = useState<string | undefined>("");
    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        props.history.push("/admin/menu");
    }

    const [confirmDialog, setConfirmDialog] = useState("");

    const onYesClick = async () => {
        if (binary == null || binary === "") {
            setDialogMessage("ファイルを選択して下さい");
        } else {
            const conf = CommonConfiguration();
            const apiPost = await InsuranceMasterApiFp(conf).apiAdminInsuranceMasterPost({value: binary})
            try {
                const ret = await apiPost();
                if (ret.status === 200) {
                    setDialogMessage("登録しました。");
                } else if (ret.status === 204) {
                    setDialogMessage("登録しました。");
                } else {
                }
            } catch (e) {
                //更新系例外
                if (e instanceof Error && e.message === "Network Error") {
                    setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                } else if (e.response.status === 400 || e.response.status === 500) {
                    setDialogMessage(`サーバーエラーが発生しました。システム担当者へご連絡ください。`);
                } else if (e.response.status === 422) {
                    setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
                } else if (e.response.status === 404) {
                    setDialogWithClose("登録に失敗しました。");
                } else if (e.response.status === 405) {
                    window.location.href = "/timeout";
                } else {
                    setDialogWithClose("登録に失敗しました。");
                }
            }
        }
        setConfirmDialog("");
    }

    const onNoClick = async () => {
        setConfirmDialog("");
    }
    
    
    const onReturnMenu = () => {
        props.history.push("/admin/menu");

    }

    const onSubmit = () => {
        setConfirmDialog("保険組合マスターを保存しますか？");
    }

    return (
        <main role="main" className="flex-shrink-0">
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            {confirmDialog !== "" && <CommonDialogBox value={confirmDialog} style={DialogStyle.yesNo} onClickYes={onYesClick} onClickNo={onNoClick} />}


            <Container fluid={"xl"}>
                <h1>保険組合マスター管理</h1>
                <Row>
                    <Col><p>保険組合マスターCSVファイルを下のボックスにドラッグアンドドロップして下さい。</p></Col>
                </Row>
                <Row>
                    <Col><p>ボタンを押して選択することも可能です。</p></Col>
                </Row>
                <CommonFileDropRow disabled={false} fileName={fileName} accept={".csv"} kind={kindFile.InsuranceFile} OnSetFileBinary={setBinary} OnSetFileName={setFileName}/>


                <Row style={{textAlign: "center"}}>
                    <Col xs={12}><ThrottleButton variant="success" disabled={(binary == null || binary === "")} onClick={onSubmit}>
                        保存</ThrottleButton></Col>
                </Row>


                <Row>
                    <Col xs={12}><ThrottleButton variant="secondary" onClick={onReturnMenu}>
                        メニューに戻る</ThrottleButton></Col>
                </Row>


            </Container>
        </main>
    );

}

export default withRouter(AdminRouteInsuranceMaster);


//react 
import React, {useEffect, useState} from "react";
import {Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import {RouteComponentProps, withRouter} from "react-router-dom";


//common
import {faHome, faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


//local
import OrganizationTable from "./OrganizationTable";
import {right} from "@popperjs/core";
import { GetOrganizationInfo} from "../client-axios";
import ThrottleButton from "../control/ThrottleButton";

interface Props extends RouteComponentProps {
    value: GetOrganizationInfo
}


const AdminRouteOrganizationList = (props: Props) => {
    document.title = "企業一覧";

    //検索用ボタン
    const [searchCondition, setSearchCondition] = useState("");
    const [tempSearchString, setTempSearchString] = useState("");

    const [searchFlag, setSearchFlag] = useState(false);
    const onSearchClick=() =>{
        setSearchFlag(true);
    }
    useEffect(() => {
        if ( searchFlag) {
            setSearchCondition(tempSearchString);
            setSearchFlag(false);
        }
    }, [searchFlag]);

    const onSearchChange=(event)=> {
        const value = event.target.value;
        setTempSearchString(value);
    }


    

    function onReturnMenu() {
        props.history.push("/admin/menu");
    }


    const onCreate = () => {

        props.history.push("/admin/createOrganization");
    }

    
    const adminOrganizationList = (
        <main role="main" className="flex-shrink-0">
            {/*{dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}*/}
            {/*{dialogWithClose !== "" &&*/}
            {/*<CommonDialogBox onClickOK={onDialogCloseOk} value={dialogWithClose} style={DialogStyle.okOnly}/>}*/}


            <Container fluid={"xl"}>
                <h1>企業一覧</h1>
                <Row>
                    <Col xs={12} style={{textAlign: right}}>
                        <ThrottleButton variant="primary" onClick={onCreate}>新規作成</ThrottleButton>
                    </Col>
                </Row>
                <div style={{marginTop: "0.5rem"}}>
                </div>
                <Row>
                    <Col xs={9}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">検索キーワード</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="検索キーワード"
                                aria-label="検索キーワード"
                                aria-describedby="検索キーワード"
                                value={tempSearchString}
                                onChange={onSearchChange}
                                maxLength={50}
                            />

                        </InputGroup>
                    </Col>
                    <Col xs={3}>
                        <ThrottleButton variant="primary" onClick={onSearchClick}>検索</ThrottleButton>
                    </Col>
                </Row>
                <div style={{marginTop: "0.5rem"}}>
                    <OrganizationTable value={searchCondition}/>
                </div>

                <Row>
                    <Col xs={12}><ThrottleButton variant="secondary" onClick={onReturnMenu}>メニューに戻る</ThrottleButton></Col>
                </Row>

            </Container>
        </main>
    );


    return (adminOrganizationList);
}

export default withRouter(AdminRouteOrganizationList);


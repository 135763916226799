//react 
import React from "react";
import { useFormContext} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";

//common


//local
import {
    GetAccountInfo,
} from "../client-axios";
import RequiredImage from "./RequiredImage";
import {format10DateToHaiphongDate} from "../common/CommonFormat";

interface Props  {
    value?: GetAccountInfo,
    readOnly: boolean,
    isNotossDispName: boolean,
}


const AccountForms = (props: Props) => {
    const {register,  formState: {errors}} = useFormContext();

    return (
        <div>
            <Form.Group as={Row}>
                <Col md={5}>
                    <Form.Label>
                        OSS申請者
                    </Form.Label>
                </Col>
            </Form.Group>
            <Form.Group as={Row}  controlId="ossMailAddress">
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>メールアドレス</Form.Label></Col>

                <Col md={6}><Form.Control type="text" readOnly={true} placeholder="メールアドレス" defaultValue={props.value?.ossMailAddress}
                                   {...register("ossMailAddress", {required: true, maxLength: 128, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/})}
                                   isInvalid={!!errors.ossMailAddress}
                />
                    {errors.ossMailAddress && errors.ossMailAddress.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.ossMailAddress && errors.ossMailAddress.type === "maxlength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は30文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.ossMailAddress && errors.ossMailAddress.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        メールアドレスを入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            
            {/*<Form.Group as={Row}  controlId="levelKind">*/}
            {/*    <Col xs={{span: 4, offset: 1}}><Form.Label>種類<RequiredImage /></Form.Label></Col>*/}
            {/*    <Col xs={6}><Form.Control type="text" readOnly={true} placeholder="種類" defaultValue={props.value?.levelKind === 2 ? "管理者" : "ユーザー"}*/}
            {/*                       {...register("levelKind", {required: true, maxLength: 128})}*/}
            {/*                       isInvalid={!!errors.levelKind}*/}
            {/*    />*/}
            {/*        {errors.levelKind && errors.levelKind.type === "required" &&*/}
            {/*        <Form.Control.Feedback type="invalid">*/}
            {/*            この項目は必須です。*/}
            {/*        </Form.Control.Feedback>*/}
            {/*        }*/}
            {/*        {errors.levelKind && errors.levelKind.type === "maxlength" &&*/}
            {/*        <Form.Control.Feedback type="invalid">*/}
            {/*            この項目は128桁までです。*/}
            {/*        </Form.Control.Feedback>*/}
            {/*        }*/}
            {/*    </Col>*/}
            {/*</Form.Group>*/}

            {!props.isNotossDispName &&
            <Form.Group as={Row} controlId="ossDispName">
                <Col md={{ span: 4, offset: 1 }}><Form.Label>所属等(表示用名称)<br />
                    <span className="subLabel">(全角、最大25文字)</span>
                </Form.Label></Col>
                <Col md={6}><Form.Control readOnly={props.readOnly} type="text" placeholder="所属等(表示用名称)" defaultValue={props.value?.ossDispName}
                    {...register("ossDispName", { required: false, maxLength: 25, pattern: /^[^ -~]+$/ })}
                    isInvalid={!!errors.ossDispName}
                />
                    {errors.ossDispName && errors.ossDispName.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は25文字までです。
                        </Form.Control.Feedback>
                    }
                    {errors.ossDispName && errors.ossDispName.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            全角で入力してください。
                        </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            }

            <Form.Group as={Row} controlId="ossName">
                <Col md={{span: 4, offset: 1}}><Form.Label>名前<RequiredImage /><br/>
                    <span className="subLabel">(全角、最大30文字)</span>
                    </Form.Label></Col>
                <Col md={6}><Form.Control readOnly={props.readOnly} type="text" placeholder="名前" defaultValue={props.value?.ossName}
                                   {...register("ossName", {required: true, maxLength: 30, pattern: /^[^ -~]+$/})}
                                   isInvalid={!!errors.ossName}
                />
                    {errors.ossName && errors.ossName.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.ossName && errors.ossName.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は30文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.ossName && errors.ossName.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>


            <Form.Group as={Row} controlId="ossAddress">
                <Col md={{span: 4, offset: 1}}><Form.Label>申請者住所<RequiredImage /><br/>
                    <span className="subLabel">(全角、最大128文字)</span></Form.Label></Col>
                <Col md={6}><Form.Control readOnly={props.readOnly} type="text" placeholder="申請者住所" defaultValue={props.value?.ossAddress}
                                   {...register("ossAddress", {required: true, maxLength: 128, pattern: /^[^ -~]+$/})}
                                   isInvalid={!!errors.ossAddress}
                />
                    {errors.ossAddress && errors.ossAddress.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.ossAddress && errors.ossAddress.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は128文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.ossAddress && errors.ossAddress.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="ossSex">
                <Col md={{span: 4, offset: 1}}><Form.Label>申請者情報 性別<RequiredImage /></Form.Label></Col>
                
                <Col md={6}>
                    {props.readOnly && <Form.Check checked={props.value?.ossSex === "1"} disabled={props.value?.ossSex !== "1"} type={"radio"} {...register("ossSex", {required: true})} label={"男性"} defaultChecked={props.value?.ossSex==="1"} value={"1"} />}
                    {props.readOnly && <Form.Check checked={props.value?.ossSex === "2"} disabled={props.value?.ossSex !== "2"} type={"radio"} {...register("ossSex", {required: true})} label={"女性"} defaultChecked={props.value?.ossSex==="2"} value={"2"} />}
                    {props.readOnly && <Form.Check checked={props.value?.ossSex === "3"} disabled={props.value?.ossSex !== "3"} type={"radio"} {...register("ossSex", {required: true})} label={"その他"} defaultChecked={props.value?.ossSex==="3"} value={"3"} />}
                    {!props.readOnly && <Form.Check type={"radio"} {...register("ossSex", {required: true})} label={"男性"} defaultChecked={props.value?.ossSex==="1"} value={"1"} />}
                    {!props.readOnly && <Form.Check type={"radio"} {...register("ossSex", {required: true})} label={"女性"} defaultChecked={props.value?.ossSex==="2"} value={"2"} />}
                    {!props.readOnly && <Form.Check type={"radio"} {...register("ossSex", {required: true})} label={"その他"} defaultChecked={props.value?.ossSex==="3"} value={"3"} />}
                    {errors.ossSex && errors.ossSex.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>


            <Form.Group as={Row} controlId="formGridCity">
                <Col md={{span: 4, offset: 1}}><Form.Label>生年月日<RequiredImage /></Form.Label></Col>
                <Col md={6}><Form.Control readOnly={props.readOnly} type="date" placeholder="生年月日" max={"2500-12-31"}
                                   {...register("ossBirthday", {required: true, maxLength: 13})}
                                   isInvalid={!!errors.ossBirthday} defaultValue={format10DateToHaiphongDate(props.value?.ossBirthday)}
                />
                    {errors.ossBirthday && errors.ossBirthday.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>


            <Form.Group as={Row} controlId="ossTelNo1">
                <Form.Label column md={{span: 4, offset: 1}}>
                    電話番号<RequiredImage /><br/>
                        <span className="subLabel">(半角数字、最大5桁 - 最大4桁 - 最大4桁)</span>
                </Form.Label>

                <Col xs={6} style={{display: "flex"}}>
                    <div>
                        <Form.Control readOnly={props.readOnly} type="text" placeholder="市外局番" style={{width: 75}} defaultValue={props.value?.ossTelNo1}
                                    {...register("ossTelNo1", {required: true, maxLength: 5, pattern: /^\d+$/})}
                                    isInvalid={!!errors.ossTelNo1}/>
                        {errors.ossTelNo1 && errors.ossTelNo1.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.ossTelNo1 && errors.ossTelNo1.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.ossTelNo1 && errors.ossTelNo1.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </div>
                    <div style={{width: 105, textAlign: "center", marginTop: 5}}>
                        -
                    </div>
                    <div>
                        <Form.Control readOnly={props.readOnly} type="text" placeholder="市内局番" style={{width: 75}} defaultValue={props.value?.ossTelNo2}
                                    {...register("ossTelNo2", {required: true, maxLength: 4, pattern: /^\d+$/})}
                                    isInvalid={!!errors.ossTelNo2}/>
                        {errors.ossTelNo2 && errors.ossTelNo2.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.ossTelNo2 && errors.ossTelNo2.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は4桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.ossTelNo2 && errors.ossTelNo2.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </div>
                    <div style={{width: 105, textAlign: "center", marginTop: 5}}>
                        -
                    </div>
                    <div>
                        <Form.Control readOnly={props.readOnly} type="text" placeholder="加入者番号" style={{width: 75}} defaultValue={props.value?.ossTelNo3}
                                    {...register("ossTelNo3", {required: true, maxLength: 4, pattern: /^\d+$/})}
                                    isInvalid={!!errors.ossTelNo3}/>
                        {errors.ossTelNo3 && errors.ossTelNo3.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.ossTelNo3 && errors.ossTelNo3.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は4桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.ossTelNo3 && errors.ossTelNo3.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </div>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="ossCorpNameKanji">
                <Col md={{span: 4, offset: 1}}><Form.Label>申請者商号（法人名）<br/>
                    <span className="subLabel">(全角、最大25文字)</span></Form.Label></Col>
                <Col md={6}><Form.Control readOnly={props.readOnly} type="text" placeholder="申請者商号（法人名）" defaultValue={props.value?.ossCorpNameKanji}
                                          {...register("ossCorpNameKanji", {
                                              required: false,
                                              maxLength: 25, pattern: /^[^ -~]+$/
                                          })} isInvalid={!!errors.ossCorpNameKanji}
                />
                    {errors.ossCorpNameKanji && errors.ossCorpNameKanji.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は25文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.ossCorpNameKanji && errors.ossCorpNameKanji.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="ossCorpNameKana">
                <Col md={{span: 4, offset: 1}}><Form.Label>申請者商号（法人名）フリガナ<br/>
                    <span className="subLabel">(全角カナ、全角空白、全角ハイフン、全角長音、最大60文字)</span></Form.Label></Col>
                <Col md={6}><Form.Control readOnly={props.readOnly} type="text" placeholder="申請者商号（法人名）フリガナ"
                                          defaultValue={props.value?.ossCorpNameKana}
                                          {...register("ossCorpNameKana", {required: false, maxLength: 60, pattern: /^[ァ-ヶ‐ー　]+$/})}
                                          isInvalid={!!errors.ossCorpNameKana}
                />
                    {errors.ossCorpNameKana && errors.ossCorpNameKana.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は60文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.ossCorpNameKana && errors.ossCorpNameKana.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        全角カナ、全角空白、全角ハイフン、全角長音で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
        </div>
    );
}

export default (AccountForms);


//react
import React, {useEffect, useState} from "react";

//common
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

//local
import {GetAccountInfo, UserApiFp} from "../client-axios";

import {CommonConfiguration} from "../common/CommonSetting";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import CommonAccountEditBox from "../common/CommonAccountEditBox";
import {levelKindEnumManager, levelKindEnumNotSet} from "../common/CommonDefine";
import CommonSpinner from "../common/CommonSpinner";


interface Props {
    value: GetAccountInfo,
    onUpdate: () => void,
}

const UserMemberRow = (props: Props) => {
    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogWithGotoMenu, setDialogWithGotoMenu] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        props.onUpdate();
    }

    const onCloseAndGotoMenu = () => {
        window.location.href = "/menu";
    }
    
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [prevLevelKind, setPrevLevelKind] = useState<number|undefined>(undefined);
    const [nowLevelKind, setNowLevelKind] = useState<number|undefined>(undefined);
    const [isLoaded, setIsLoaded] = useState(false);
    const [gotoMenu, setGotoMenu] = useState<boolean>(false);

    const onEdit = async() => {
        const b = await CheckAuth();
        if( b ){
            setIsEdit(true);
        }
    };

    const onEditUpdated = () => {
        setIsEdit(false);
        props.onUpdate();
    };

    const onDelete = async () => {
        const b = await CheckAuth();
        if( b ) {
            setIsDelete(true);
            setDialogMessage("削除しますか？");
        }
    }

    const onDeleteYes = async() => {
        const conf = CommonConfiguration();
        const apiUser = await UserApiFp(conf).apiAccountIdGet(props.value.id);
        let user: GetAccountInfo;
        let levelKind : number = 1;
        try {
            const ret = await apiUser();
            if (ret.status === 200) {
                user = ret.data;
                if(user !== undefined && user.levelKind !== undefined) {
                    levelKind = user.levelKind;
                } else {
                    levelKind = 1;
                }
            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
        
        
        const apiMember = await UserApiFp(conf).apiMemberGet();
        let manager: number = 0;
        try {
            const ret = await apiMember();
            if (ret.status === 200) {
                ret.data.map((x) => x.levelKind == 2 ? manager++ : manager += 0 )
                if(manager === 1 && levelKind == 2) {
                    setDialogWithClose("管理者を0人にすることはできません");
                    return
                }
            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
        
        const apiDelete = await UserApiFp(conf).apiAccountIdDelete(props.value.id);
        try {
            const ret = await apiDelete();
            if (ret.status === 200) {
                setDialogWithClose("削除しました。");
            } else if (ret.status === 204) {
                setDialogWithClose("削除しました。");
            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
        
        setIsDelete(false);
        setDialogMessage("");
    }

    const onDeleteNo = () => {
        setIsDelete(false);
        setDialogMessage("");
    }

    const onClickOK = () => {
        setDialogMessage("");
    }

    const Load = async () => {
        const conf = CommonConfiguration();
        const apiGet = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const x: GetAccountInfo = ret.data;
                if (x !== undefined) {
                    setPrevLevelKind(x.levelKind);
                    //種類ガード
                    if ( (x.levelKind ?? levelKindEnumNotSet) < levelKindEnumManager ) {
                        window.location.href = "/menu";
                    }
                }
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 403) {
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }
    }

    const CheckAuth = async () :Promise<boolean> => {
        const conf = CommonConfiguration();
        const apiGet = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const x: GetAccountInfo = ret.data;
                if (x !== undefined) {
                    setNowLevelKind(x.levelKind);
                    if (prevLevelKind === 2 && x.levelKind === 1) {
                        setDialogWithGotoMenu("権限がありません");
                        return false;
                    }
                }
            }
            return true
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 403) {
            } else if (e.response.status === 404) {
                setDialogWithClose("権限がありません");
                setGotoMenu(true);
                return false;
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
            return false;
        }
        return true;
    }
    

    useEffect(() => {
        Load().then();
        setIsLoaded(true);
    }, [])

    const Loading = (<CommonSpinner/>)

    const Loaded = (
        <tr key={props.value.id}>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}
            {dialogWithGotoMenu !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoMenu} value={dialogWithGotoMenu} style={DialogStyle.okOnly}/>}

            <td>{props.value.ossName}</td>
            <td>{props.value.ossMailAddress}</td>
            <td>{props.value.levelKind === 1 ? "ユーザー" : "管理者"}</td>
            <td><FontAwesomeIcon icon={faEdit} onClick={onEdit} style={{cursor: "pointer"}} /></td>
            <td><FontAwesomeIcon icon={faTrashAlt} onClick={onDelete} style={{cursor: "pointer"}} /></td>
            {isEdit && <CommonAccountEditBox value={props.value} method={"PUT"} readonly={true} title={"アカウント編集"} refresh={true} onUpdate={onEditUpdated} />}
            {isDelete && dialogMessage !== "" && <CommonDialogBox value={dialogMessage}  style={DialogStyle.yesNo} onClickYes={onDeleteYes} onClickNo={onDeleteNo} />}
            {!isDelete && dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onClickOK} />}
        </tr>
    )
    return (isLoaded ? Loaded : Loading);
}

export default (UserMemberRow)

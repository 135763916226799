


import {Configuration} from "../client-axios";


export const basePath= "https://mynap.shinsei.zeem.jp"

export const CommonConfiguration = () =>
{
    const conf: Configuration = new Configuration({
        basePath: "https://mynap.shinsei.zeem.jp"
    });
    conf.isJsonMime("application/json; charset=UTF8");
    conf.baseOptions={headers:{"X-Requested-With" : "XMLHttpRequest "}};

    return conf;
}





//react
import React, {useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";


//common
import {v4 as uuid} from "uuid";

//local
import {
    GetOrganizationInfo,
    InsuranceItem,


} from "../client-axios";
import OrganizationInsuranceTable from "../user/OrganizationInsuranceTable";
import InsuranceEditBox from "../user/InsuranceEditBox";
import OrganizationBaseForms from "../control/OrganizationBaseForms";
import RequiredImage from "../control/RequiredImage";
import {ProviderEnumAad, ProviderEnumAadString, ProviderEnumZeem, ProviderEnumZeemString} from "../common/CommonDefine";
import ThrottleButton from "../control/ThrottleButton";


interface Props {
    value?: GetOrganizationInfo,
    isHiddenDisplayProvider: boolean,
    onGotoMenu: () => void,
    insuranceItems?: InsuranceItem[],
    setInsuranceItems: (i ?: InsuranceItem[]) => void,
}

const OrganizationEdit = (props: Props) => {
    const {register, formState: {errors}} = useFormContext();


    const [insuranceScreen, setInsuranceScreen] = useState(false);

    const providerValue = useWatch({
        name: 'provider', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        defaultValue: props.value?.provider as number
    });


    //保険組合のボタン
    const onInsuranceAddButton = () => setInsuranceScreen(true);

    const insuranceOnUpdate = (item: InsuranceItem) => {
        let items = props.insuranceItems;
        if (items == null) {
            items = new Array<InsuranceItem>();
        }
        items.push(item);
        props.setInsuranceItems(items);
        setInsuranceScreen(false);
    };

    function onInsuranceEdit(item: InsuranceItem) {
        let items = props.insuranceItems;
        if (items == null) {
            items = new Array<InsuranceItem>();
        }
        const itemIndex = items.findIndex(x => x.id === item.id);
        if (itemIndex === -1) {
            setInsuranceScreen(false);
            return
        }
        item.id = uuid();
        const newItems = items.slice();
        newItems.splice(itemIndex, 1, item);
        props.setInsuranceItems(newItems);
        setInsuranceScreen(false);
    }

    function onInsuranceDelete(id) {
        let items = props.insuranceItems;
        if (items == null) {
            items = new Array<InsuranceItem>();
        }
        const newItems = items.filter(x => x.id !== id);
        props.setInsuranceItems(newItems);
        setInsuranceScreen(false);
    }

    const insuranceOnClose = () => setInsuranceScreen(false);


    return (
        <div>
            {insuranceScreen && <InsuranceEditBox onUpdate={insuranceOnUpdate} onClose={insuranceOnClose}/>}
            {/*{dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}*/}
            {/*{dialogWithClose !== "" &&*/}
            {/*<CommonDialogBox onClickOK={onDialogCloseOk} value={dialogWithClose} style={DialogStyle.okOnly}/>}*/}

            <OrganizationBaseForms value={props.value} readOnly={false}/>

            <Form.Group as={Row} hidden={props.isHiddenDisplayProvider}>
                <Form.Label column md={5}>
                    プロバイダー<RequiredImage />
                </Form.Label>
                <Col md={3}>
                    <Form.Check type={"radio"} {...register("provider", {required: true})} label={"Azure AD"}
                                defaultChecked={props.value?.provider === ProviderEnumAad} value={ProviderEnumAad}/>
                </Col>
                <Col md={3}>
                    <Form.Check type={"radio"} {...register("provider", {required: true})} label={"統合認証"}
                                defaultChecked={props.value?.provider === ProviderEnumZeem} value={ProviderEnumZeem}/>
                    {errors.provider && errors.provider.type === "required"  &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} hidden={props.isHiddenDisplayProvider}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        統合認証サインインUrl<RequiredImage /><br/>
                            <span className="subLabel">(半角英数字URL、最大200文字)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control required type="text" placeholder="サインインUrl"
                                  defaultValue={props.value?.signInUrl}
                                  className={"form-control"} disabled={providerValue === undefined ||  providerValue === ProviderEnumAad || providerValue === ProviderEnumAadString}
                                  {...register("signInUrl", {required: (providerValue === ProviderEnumZeem || providerValue === ProviderEnumZeemString), maxLength: 200, pattern: /[\w:/@\\]/})}
                                  isInvalid={errors.signInUrl}/>
                    {errors.signInUrl && errors.signInUrl.type === "required" && (providerValue === ProviderEnumZeem || providerValue === ProviderEnumZeemString) &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.signInUrl && errors.signInUrl.type === "maxLength" && (providerValue === ProviderEnumZeem || providerValue === ProviderEnumZeemString) &&
                    <Form.Control.Feedback type="invalid">
                        この項目は200文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.signInUrl && errors.signInUrl.type === "pattern" && (providerValue === ProviderEnumZeem || providerValue === ProviderEnumZeemString) &&
                    <Form.Control.Feedback type="invalid">
                        半角英数字URLで入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} hidden={props.isHiddenDisplayProvider}>
                <Col md={{span: 4, offset: 1}}>
                    <Form.Label>
                        クライアントID<RequiredImage /><br/>
                        <span className="subLabel">(16進数32桁)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control required type="text" placeholder="クライアントID"
                                  defaultValue={props.value?.clientId}
                                  className={"form-control"} disabled={providerValue === undefined ||  providerValue === ProviderEnumAad || providerValue === ProviderEnumAadString}
                                  {...register("clientId", {required: (providerValue === ProviderEnumZeem || providerValue === ProviderEnumZeemString), minLength: 32, maxLength: 32, pattern: /^[0-9a-fA-F]+$/})}
                                  isInvalid={errors.clientId}/>
                    {errors.clientId && errors.clientId.type === "required" && (providerValue === ProviderEnumZeem || providerValue === ProviderEnumZeemString) &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.clientId && errors.clientId.type === "minLength" && (providerValue === ProviderEnumZeem || providerValue === ProviderEnumZeemString) &&
                    <Form.Control.Feedback type="invalid">
                        この項目は32桁です。
                    </Form.Control.Feedback>
                    }
                    {errors.clientId && errors.clientId.type === "maxLength" && (providerValue === ProviderEnumZeem || providerValue === ProviderEnumZeemString) &&
                    <Form.Control.Feedback type="invalid">
                        この項目は32桁です。
                    </Form.Control.Feedback>
                    }
                    {errors.clientId && errors.clientId.type === "pattern" && (providerValue === ProviderEnumZeem || providerValue === ProviderEnumZeemString) &&
                    <Form.Control.Feedback type="invalid">
                        16進数で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>


            <Form.Group as={Row}>
                <ThrottleButton onClick={onInsuranceAddButton}>提出先保険組合選択</ThrottleButton>
            </Form.Group>
            <Form.Group as={Row}>
                <OrganizationInsuranceTable value={props?.insuranceItems} onEdited={onInsuranceEdit}
                                            onDeleted={onInsuranceDelete}/>
            </Form.Group>
        </div>
    );
}
export default (OrganizationEdit);

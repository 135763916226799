
export const levelKindEnumNotSet = 0;
export const levelKindEnumUser = 1;
export const levelKindEnumManager = 2;



export const ProviderEnumNotSet = 0;
export const ProviderEnumAad = 1;
export const ProviderEnumZeem = 2;
export const ProviderEnumAadString = "1";
export const ProviderEnumZeemString = "2";


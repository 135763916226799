
// react
import React from "react";
import ReactDOM from "react-dom";
// router
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Router} from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
// redux
import {Provider, connect} from "react-redux";
import {createStore} from "redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistReducer, persistStore} from "redux-persist";
import { mapDispatchToProps, mapStateToProps} from "./globalReduxActions";
import storage from "redux-persist/lib/storage";

//common
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { createBrowserHistory as createHistory } from 'history'
import reportWebVitals from './reportWebVitals';

//local

import AdminRouteMenu from "./admin/AdminRouteMenu";
import UserRouteMenu from "./user/UserRouteMenu";
import UserRouteApplicationList from "./user/UserRouteApplicationList";
import UserRouteOrganization from "./user/UserRouteOrganization";

import AdminFooter from "./admin/AdminFooter";
import AdminRouteInsuranceMaster from "./admin/AdminRouteInsuranceMaster";
import AdminRouteOrganizationList from "./admin/AdminRouteOrganizationList";
import UserRouteAccount from "./user/UserRouteAccount";
import AdminRouteMemberList from "./admin/AdminRouteMemberList";
import UserRouteMemberList from "./user/UserRouteMemberList";
import AdminRouteCreateOrganization from "./admin/AdminRouteCreateOrganization";
import UserRouteApplicationCreate from "./user/UserRouteApplicationCreate";
import UserRouteApplicationEdit from "./user/UserRouteApplicationEdit";
import UserRouteOidc from "./user/UserRouteOidc";
import UserRouteMaintenance from "./user/UserRouteMaintenance";
import AdminCommonLogo from "./admin/AdminCommonLogo";
import UserCommonLogo from "./user/UserCommonLogo";


export const msalConfig = {
    auth: {
        clientId: "38f90f52-5766-41d6-a06d-8f29965910d1",
        authority: "https://login.microsoftonline.com/",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
}
    
    
// reduxのstore
const config = {
    key: "root",
    storage,
    // blacklist: ["globalAceessToken", "globalAccount","globalAuthorizationLevel"]

};

// Reducer
export interface Actions {
    type : string
    value : string
}

const myReducer = (state = 0, action: Actions) => {
    switch (action.type) {
        case "SET_LANGUAGE":
            return Object.assign({}, state, {
                globalLanguage: action.value
            });
        case "SET_ACCESSTOKEN":
            return Object.assign({}, state, {
                globalAceessToken: action.value
            });

        case "SET_AUTHORIZATION_LEVEL":
            return Object.assign({}, state, {
                globalAuthorizationLevel: action.value
            });
            
        case "SET_SEARCHKEY":
            return Object.assign({}, state, {
                globalSearchKey: action.value
            });

        default:
            return state;
    }
};


const reducer = persistReducer(config, myReducer);

const enhancer = (window as any)["devToolsExtension"] ? (window as any)["devToolsExtension"]()(createStore) : createStore;
const store = enhancer(reducer);
const persistor = persistStore(store);

// @ts-ignore
const history : History.History = createHistory()

const App = () =>{
    return (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history} >
                <AppRoute2  />
            </Router>
        </PersistGate>
    </Provider>); 
}

const AppRoute = (props:any) => (
        <BrowserRouter>
            <div>
                <Switch>
                    <Route exact path='/maintenance' component={UserMaintenance} />
                    <Route exact path='/oidc' component={UserOidc} />
                    <Route exact path='/menu' component={UserMenu} />
                    <Route exact path='/applicationList' component={Applications} />
                    <Route exact path='/application/:id' render={({ match }) => <EditApplication {...props} match={match}/> } />
                    <Route exact path='/application' component={NewApplication} />
                    <Route exact path='/myaccount' component={MyAccount} />
                    <Route exact path='/member' component={UserMember} />
                    <Route exact path='/organization' component={Organization} />
                    <Route exact path='/admin/menu' component={AdminMenu} />
                    {/*<Route exact path='/admin/list' component={AdminList} />*/}
                    <Route exact path='/admin/organizationlist' component={AdminOrganizationList} />
                    <Route exact path='/admin/createOrganization' component={AdminCreateOrganization} />
                    <Route exact path='/admin/member/:id'  render={({ match }) => <AdminMember {...props} match={match}/> } />
                    <Route exact path='/admin/InsuranceMaster' component={AdminInsuranceMaster} />
                </Switch>
            </div>
        </BrowserRouter>

);



const AppRoute2 = connect(mapStateToProps, mapDispatchToProps)(AppRoute);

//admin
const AdminMenu = () => (
    <div>
        <AdminCommonLogo />
        <AdminRouteMenu />
        <AdminFooter />
    </div>
);

const AdminOrganizationList = (props: any) => (
    <div>
        <AdminCommonLogo />
        <AdminRouteOrganizationList value={props.value}/>
        <AdminFooter />
    </div>
);
const AdminCreateOrganization = (props: any) => (
    <div>
        <AdminCommonLogo />
        <AdminRouteCreateOrganization />
        <AdminFooter />
    </div>
);

const AdminInsuranceMaster = () => (
    <div>
        <AdminCommonLogo />
        <AdminRouteInsuranceMaster />
        <AdminFooter />
    </div>
);

const AdminMember = (props) => (
    <div>
        <AdminCommonLogo />
        <AdminRouteMemberList {...props} />
        <AdminFooter />
    </div>
);


// User
const UserMaintenance = () => (
    <div>
        <UserRouteMaintenance />
    </div>
);

const UserOidc = () => (
    <div>
        <UserRouteOidc />
    </div>
);

const UserMenu = () => (
    <div>
        <UserCommonLogo />
        <UserRouteMenu />
    </div>
);

const Applications= () => (
    <div>
        <UserCommonLogo />
        <UserRouteApplicationList query={""}/>
    </div>
);

const MyAccount = () => (
    <div>
        <UserCommonLogo />
        <UserRouteAccount />
    </div>
);
const UserMember = () => (
    <div>
        <UserCommonLogo />
        <UserRouteMemberList />
    </div>
);


const Organization = () => (
    <div>
        <UserCommonLogo />
        <UserRouteOrganization />
    </div>    
);


const NewApplication = (props) => (
    <div>
        <UserCommonLogo />
        <UserRouteApplicationCreate {...props} />
    </div>   
);
const EditApplication = (props) => (
    <div>
        <UserCommonLogo />
        <UserRouteApplicationEdit {...props} />
    </div>
);



ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();


// export default AppRoute2;


//react 
import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {Col, Container, Form, Row} from "react-bootstrap";

//common
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faSave} from "@fortawesome/free-solid-svg-icons";


//local
import {CommonConfiguration} from "../common/CommonSetting";
import {GetAccountInfo, PutAccountInfo, UserApiFp} from "../client-axios";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import AccountForms from "../control/AccountForms";
import CommonSpinner from "../common/CommonSpinner";
import CommonFileDropRow, {kindFile} from "../control/CommonFileDropRow";
import ThrottleButton from "../control/ThrottleButton";


interface Props extends RouteComponentProps {
}


const UserRouteAccount = (props: Props) => {
    document.title = "マイアカウント編集";

    const methods = useForm({mode: "onBlur"});
    const {trigger} = methods;
    
    const [isConfirmSave, setIsConfirmSave] = useState(false);
    const [saveDialogConfirm, setSaveDialogConfirm] = useState("");

    const [loadData, setLoadData] = useState<GetAccountInfo | undefined>(undefined);
    const [isFileLoaded, setIsFileLoaded] = useState(false);
    const [filename, setFileName] = useState<string | undefined>("");
    const [binary, setBinary] = useState<string | undefined>("");

    const [attachFileName, setAttachFileName] = useState<string | undefined>("");
    const [registered, setRegitered] = useState(false);
    
    const conf = CommonConfiguration();

    const onSubmit2 = async (data) => {

        const putBody: PutAccountInfo =
            {
                ossMailAddress: data.ossMailAddress,
                levelKind: 0,
                ossDispName: data.ossDispName,
                ossName: data.ossName,
                attachFile: binary,
                ossAddress: data.ossAddress,
                ossSex: data.ossSex == null ? "3" : data.ossSex,
                ossBirthday: data.ossBirthday,
                ossTelNo1: data.ossTelNo1,
                ossTelNo2: data.ossTelNo2,
                ossTelNo3: data.ossTelNo3,
                ossCorpNameKanji: data.ossCorpNameKanji,
                ossCorpNameKana: data.ossCorpNameKana,
                attachFileName: filename,
                
            };

        // const date = data.ossBirthday
        // putBody.ossBirthday = (new Date()).getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)

        const putApi = await UserApiFp(conf).apiAccountPut(putBody);
        try {
            const ret = await putApi();
            if (ret.status === 200) {
                setDialogWithClose("保存しました。");

            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
    };


    function onReturnMenu() {
        props.history.push("/menu");

    }

    const getData = async () :Promise<boolean> => {
        const apiGet = await UserApiFp(conf).apiMeReloadGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const data: GetAccountInfo = ret.data;
                setLoadData(data);
                setIsFileLoaded(true);
                setAttachFileName(ret.data.attachFileName === "" ? "" : ret.data.attachFileName);
                setRegitered(ret.data.attachFileName !== "");
            }
        } catch (e) {

            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithGotoMenu("権限がありません");
                setSaveDialogConfirm("");
                return false;
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }

            setIsFileLoaded(true);
        }
        return true;
    };


    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogWithGotoMenu, setDialogWithGotoMenu] = useState("");

    useEffect(() => {
        getData().then();
    }, [dialogWithClose, dialogWithGotoMenu]);


    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        props.history.push("/menu");
    }
    const onCloseAndGotoMenu = () => {
        setDialogMessage("");
        setDialogWithGotoMenu("");
        props.history.push("/menu");
    }

    const onSaveYes = async () => {

        setIsConfirmSave(false);
        const data = methods.getValues();
        await onSubmit2(data).then();
    }
    const onSaveNo = () => {
        setSaveDialogConfirm("");
    }
    
    const onSaveDialog = async () => {
        const result = await trigger();
        const errorCount = Object.keys(methods.formState.errors).length;
        if ( errorCount > 0) {

            methods.setFocus(Object.keys(methods.formState.errors)[0]);
            setDialogMessage("項目の入力に誤りがあります。");
            return;
        }
        if(result) setSaveDialogConfirm("保存しますか？");
    }
    
    const Loading = (<CommonSpinner />)
    const isExistsSetup = binary === "" && loadData?.attachFileName != null;
    const displayFileName = binary === "" ? loadData?.attachFileName : filename;
    const Loaded = (
        <main role="main" className="flex-shrink-0">
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}
            {dialogWithGotoMenu !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoMenu} value={dialogWithGotoMenu} style={DialogStyle.okOnly}/>}
            {saveDialogConfirm !== "" && <CommonDialogBox value={saveDialogConfirm} style={DialogStyle.yesNo} onClickNo={onSaveNo} onClickYes={onSaveYes}/>}

            <Container fluid={"xl"}>
                <h1>マイアカウント編集</h1>

                <div>
                    <FormProvider {...methods}>

                        <Form>

                            <AccountForms value={loadData} readOnly={false} isNotossDispName={false} />

                            <Row>
                                <Col><p>提出代行証明書ファイルを下のボックスにドラッグアンドドロップして下さい。</p></Col>
                            </Row>
                            <Row>
                                <Col><p>ボタンを押して選択することも可能です。</p></Col>
                            </Row>
                            
                            <CommonFileDropRow disabled={false} fileName={displayFileName } accept={".pdf,.xlsx,.xls,.docx,.doc,.pptx,.ppt,.xml,.csv,.txt,.jpeg,.jpg,.gif,.png,.tiff,.tif"} kind={kindFile.SendFile} OnSetFileName={setFileName}
                                               OnSetFileBinary={setBinary} isExistsSetup={isExistsSetup}  />



                            
                            
                            <div style={{marginTop: "0.5rem"}}>
                            </div>
                            <Row>
                                <Col xs={12}>
                                    <ThrottleButton variant="secondary" onClick={onReturnMenu}>メニューに戻る</ThrottleButton>
                                    <ThrottleButton style={{position: "absolute", right: 0}} onClick={onSaveDialog}>保存</ThrottleButton>
                                </Col>
                                {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} style={DialogStyle.okOnly} onClickOK={onDialogOk} />}
                            </Row>


                        </Form>
                    </FormProvider>
                </div>
            </Container>
        </main>
    );
    return (isFileLoaded ? Loaded : Loading);

}

export default withRouter(UserRouteAccount);


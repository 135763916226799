//react 
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";


//common


//local
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import CommonSpinner from "../common/CommonSpinner";
import {CommonConfiguration} from "../common/CommonSetting";
import {ApplicationApiFp} from "../client-axios";


interface Props {
}


export interface MynaPopupResult {
    source: string;
    result: boolean;
    access_key: string;
}

const UserRouteMenu = (props: Props) => {


    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const param = {
        access_key: query.get('access_key'),
        title: query.get('title'),
        detail: query.get('detail'),
        error_code: query.get('error_code'),
        error_message: query.get('error_message'),
    };


    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        window.opener.postMessage({source: "MynaPopup", result: false, access_key: ""} as MynaPopupResult)
        window.close();
    }

    const conf = CommonConfiguration();

    const onStartup = async () => {
        if (param.detail === "正常発行されました。") {
            if (param.access_key == null) {
                if(param.error_code != null && param.error_code === "E0902"){
                    setDialogMessage("GビジネスIDに登録した法人番号と一致していません。");
                } else {
                    setDialogMessage("マイナポータルへのアクセスに失敗しました。");
                }
                return;
            } else {
                const apiPost = await ApplicationApiFp(conf).apiMnpPostAuthenticateUserPost({accesskey: param.access_key});
                try {
                    const ret = await apiPost();
                    if (ret.status === 200) {
                        window.opener.postMessage({
                            source: "MynaPopup",
                            result: true,
                            access_key: param.access_key
                        } as MynaPopupResult)
                        window.close();
                    }
                } catch (e) {
                    //読み取り系例外
                    if (e instanceof Error && e.message === "Network Error") {
                        setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                    } else if (e.response.status === 400 || e.response.status === 422 || e.response.status === 500) {
                        setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
                    } else if (e.response.status === 404) {
                    } else if (e.response.status === 405) {
                        window.location.href = "/timeout";
                    } else {
                        setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                    }
                }


            }
        } else if (param.detail === "first") {
            const apiGet = await ApplicationApiFp(conf).apiMnpAuthenticateGet();
            try {
                const ret = await apiGet();
                if (ret.status === 200) {
                    window.location.href = ret.data.accessUrl as string
                }
            } catch (e) {
                //読み取り系例外
                if (e instanceof Error && e.message === "Network Error") {
                    setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                } else if (e.response.status === 400 || e.response.status === 422 || e.response.status === 500) {
                    setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
                } else if (e.response.status === 404) {
                } else if (e.response.status === 405) {
                    window.location.href = "/timeout";
                } else {
                    setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                }
            }

        } else {
            if(param.error_code != null && param.error_code === "E0902"){
                setDialogWithClose("GビジネスIDに登録した法人番号と一致していません。");
            } else {
                setDialogWithClose("マイナポータルへのアクセスに失敗しました。");
            }
            return;

        }

    }

    useEffect(() => {
        onStartup().then();
    }, [])


    return (
        <main role="main" className="flex-shrink-0">
            <CommonSpinner/>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}


            <div>

            </div>
        </main>

    );

}

export default (UserRouteMenu);


//react 
import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";


//common


//local
import {AdminApiFp, GetOrganizationInfo} from "../client-axios";
import Paging from "../common/Paging";
import {CommonConfiguration} from "../common/CommonSetting";
import OrganizationRow from "./OrganizationRow";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import CommonSpinner from "../common/CommonSpinner";
import {RouteComponentProps, withRouter} from "react-router-dom";


interface Props extends RouteComponentProps{
    value: string
    // items : ProductApplicationInfo[] | undefined,
    // onPaging : (number)=>void,
}


const OrganizationTable = (props: Props) => {
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [rows, setRows] = useState<GetOrganizationInfo[] | undefined>([]);
    const [displayRows, setDisplayRows] = useState<GetOrganizationInfo[] | undefined>([]);
    const [isLoaded, setIsLoaded] = useState(false);

    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        props.history.push("/admin/menu");
    }

    const onPaging = (v: number) => {
        if (v < 0) {
            setPage(0);
        } else if (maxPage < v) {
            setPage(maxPage);
        } else {
            setPage(v);
        }
    }


    const LoadData = async () => {
        const conf = CommonConfiguration();
        const d = await AdminApiFp(conf).apiAdminOrganizationGet();
        try {
            const ret = await d();
            if (ret.status === 200) {
                const x = ret.data.items;
                if (x !== undefined) {
                    setRows(x);
                }

            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }

            setRows(undefined);
        }
        setIsLoaded(true);


    }

    const itemsPerPage = 10;

    const FilterData = async () => {
        if (rows != null) {
            const rows2 = rows.filter(x => (x.corpNameKanji != null && x.corpNameKanji.includes(props.value))
                || (x.corpNameKanji != null && x.corpNameKanji.includes(props.value)))
            if (rows2 != null) {
                let max = Math.ceil((rows2.length) / itemsPerPage );
                max = max === 0 ? 0 : max - 1;
                setPage(page > max ? max : page);
                setMaxPage(max);
            } else {
                setPage(0);
                setMaxPage(0);
            }

            const rows3 = paginate(rows2, itemsPerPage, page + 1);
            setDisplayRows(rows3);
        } else {
            setDisplayRows(undefined);
        }
    }

    const paginate = (array, page_size, page_number) => {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    useEffect(() => {
        FilterData().then()
    }, [rows, props.value, page])

    useEffect(() => {
        LoadData().then();
    }, [])

    const onUpdate = () => {
        LoadData().then();
    }

    const Loading = (<CommonSpinner />)

    const Loaded = (
        <div>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            <Row>
                <table className="table">
                    <thead>
                    <tr>
                        <th>会社名</th>
                        <th>会社名カナ</th>
                        <th>URLのコピー</th>
                        <th>編集</th>
                        <th>アカウント</th>
                        <th>削除</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        displayRows != null && displayRows.map(v => {
                            return <OrganizationRow key={v.id} value={v} onUpdate={onUpdate}/>

                        })}
                    </tbody>
                </table>
            </Row>
            <Paging value={page} onPaging={onPaging} maxPage={maxPage}/>
        </div>
    )


    return (isLoaded ? Loaded : Loading);
}

export default withRouter(OrganizationTable);


//react 
import React from "react";

//common


//local
const RequiredImage = () => {


    return (<span className={"RequiredImage"}><img src="/required.png" alt="required"/></span>);

}

export default (RequiredImage);


//react 
import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";


//common
import {faSearch} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


//local
import {
    GetInsuranceMasterInfo,
    GetInsuranceMasterList,
    InsuranceMasterApiFp,
} from "../client-axios";
import {CommonConfiguration} from "../common/CommonSetting";
import InsuranceSelectRow from "./InsuranceSelectRow";
import CommonSpinner from "../common/CommonSpinner";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import ThrottleButton from "../control/ThrottleButton";


interface Props {
    onSelected: (row: GetInsuranceMasterInfo | undefined) => void;
}


const InsuranceSelectBox = (props: Props) => {

    const [items, setItems] = useState<Array<GetInsuranceMasterInfo>>();
    const [displayRows, setDisplayRows] = useState<Array<GetInsuranceMasterInfo>>();
    const [searchTextbox, setSearchTextbox] = useState("");
    const [searchString, setSearchString] = useState("");
    const [selectedRowId, setSelectedRowId] = useState<string>("");

    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [loadWait, setLoadWait] = useState(true);
    
    const [searchFlag, setSearchFlag] = useState(false);
    
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
    }
    
    const GetData = async () => {
        const conf = CommonConfiguration();
        const result = await InsuranceMasterApiFp(conf).apiInsuranceMasterGet();
        try {
            const ret = await result();
            if (ret.status === 200) {
                const resultData: GetInsuranceMasterList = ret.data;
                setItems(resultData.items);
                setDisplayRows(resultData.items);
            } else {
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
            setItems(undefined);

        }

    };

    useEffect(() => {
        if ( searchFlag) {
            setSearchString(searchTextbox);
            setSearchFlag(false);
        }
    }, [searchFlag]);

    useEffect(() => {
        GetData().then();
        setLoadWait(false);
    }, []);

    useEffect(() => {
        FilterData().then();
        setLoadWait(false);
        setSearchFlag(false)
    }, [searchFlag]);

    const onSearchTextChange = (event) => setSearchTextbox(event.target.value);
    const onSearchButtonClick = (event) => {
        setSearchFlag(true);
        setSearchString(searchTextbox);
    }

    const FilterData = async () => {
        if (items != null) {
            const rows2 = items.filter(x => (x.destinationId != null && x.destinationId.includes(searchString))
                || (x.destinationName != null && x.destinationName.includes(searchString)))
            setDisplayRows(rows2);
        } else {
            setDisplayRows(undefined);
        }
    }


    const onRowSelected = (id) => {
        if (items != null) {
            const row = items.find(x => x.id === id);
            if ( row != null ){
                setSelectedRowId(row.id);
                props.onSelected(row);
            }else{
                setSelectedRowId("");
                props.onSelected(undefined);
            }
        } else {
            setSelectedRowId("");
            props.onSelected(undefined);
        }

    }

    const Loading = (<CommonSpinner />)
    
    const Loaded = (
        <div>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}
    
            <Row>
                <div>提出先保険組合を検索　</div>
                <div><Form.Control type="string" placeholder="" maxLength={50} onChange={onSearchTextChange}/></div>
                <ThrottleButton variant={"primary"} style={{marginLeft: "0.2rem"}} onClick={onSearchButtonClick}>検索</ThrottleButton>
            </Row>
    
            <Row>
                <p>提出先保険組合を選択してください。</p>
            </Row>
            <Row style={{width: "100%", height:"15em", overflowY: "scroll"}}>
                <Col xs={12} >
            <table className="table FixHeaderTable" style={{borderCollapse: "separate"}} >
                <colgroup>
                    <col style={{width: "20%"}} />
                    <col style={{width: "80%"}} />
                </colgroup>
                <thead >
                <tr>
                    <th>
                        提出先
                    </th>
                    <th>
                        名称
                    </th>
    
                </tr>
                </thead>
                <tbody style={{}}>
                {displayRows != null && displayRows.map(x => {
                    return <InsuranceSelectRow key={x.id} value={x} selectedId={selectedRowId} onClick={onRowSelected}/>
                })}
                </tbody>
            </table>
                </Col>
            </Row>
        </div>
    )

    return (
        loadWait ? Loading : Loaded
    )
}


export default (InsuranceSelectBox);


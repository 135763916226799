//react 
import React, {useEffect, useState} from "react";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {Col, Row, Container} from "react-bootstrap";

//common

//local
import ApplicationTable from "./ApplicationTable";
import {
    ApplicationApiFp,
    ApplicationList, UserApiFp,
} from "../client-axios";
import {CommonConfiguration} from "../common/CommonSetting";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import CommonSpinner from "../common/CommonSpinner";
import ThrottleButton from "../control/ThrottleButton";
import {useDispatch, useStore} from "react-redux";
import {setSearchKey} from "../globalReduxActions";

interface Props extends RouteComponentProps {
    query: string,
}


const UserRouteApplicationList = (props: Props) => {

    const [readData, setReadData] = useState<ApplicationList | undefined>();

    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogWithGotoMenu, setDialogWithGotoMenu] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
    }

    const dispatch = useDispatch();

    const onCloseAndGotoMenu = () => {
        setDialogWithGotoMenu("");
        dispatch(setSearchKey(""));
        props.history.push("/menu");
    }

    const [isLoaded, setIsLoaded] = useState(false);

    
    const GetData = async () :Promise<boolean> => {
        const conf = CommonConfiguration();
        const apiGetMe = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGetMe();
            if (ret.status !== 200) {
                setDialogWithClose("権限がありません");
                return false;
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("権限がありません");
                return false;
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }


        const apiGet = await ApplicationApiFp(conf).apiApplicationGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const org: ApplicationList = ret.data;
                setReadData(org);

                setIsLoaded(true);
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
            setIsLoaded(true);
            setReadData(undefined);

        }
        return true;
    };

    const store = useStore()
    const state = store.getState()
    const query: string = state.globalSearchKey;
    
    useEffect(() => {
        GetData().then();
        if(props.query === "")
        {
            dispatch(setSearchKey(props.query))
        }
    }, []);

    const onDataRefresh = (() => {
        GetData().then();
    });

    const PageTitle = "申請一覧";
    useEffect(() => {
        document.title = PageTitle;
    }, [])

    const onGotoNewApplication = async () :Promise<boolean> => {
        const conf = CommonConfiguration();
        const apiGetMe = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGetMe();
            if (ret.status !== 200) {
                setDialogWithClose("権限がありません");
                return false;
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithGotoMenu("権限がありません");
                return false;
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }
        props.history.push("/application/");
        return true;
    }


    const GotoMenu = () => {
        dispatch(setSearchKey(""));
        props.history.push("/menu");
    }
    
    const loading = (<CommonSpinner />)
    const loaded = (
        <main role="main" className="flex-shrink-0">
            <Container fluid={"xl"}>

                {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
                {dialogWithClose !== "" &&
                <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}
                {dialogWithGotoMenu !== "" &&
                <CommonDialogBox onClickOK={onCloseAndGotoMenu} value={dialogWithGotoMenu} style={DialogStyle.okOnly}/>}

                <Row>
                    <Col xs={12}>
                        <h1>申請一覧</h1>
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign:"right"}} xs={12}>
                        <ThrottleButton onClick={onGotoNewApplication}>
                            <span className={"buttonLabel"}>申請の新規登録</span></ThrottleButton>
                    </Col>
                </Row>
                
                <ApplicationTable value={readData?.items} onDataRefresh={onDataRefresh} query={query}/>

                <Row>
                    <Col xs={12}>
                        <ThrottleButton variant="secondary" onClick={GotoMenu}><span className={"buttonLabel"}>メニューに戻る</span></ThrottleButton>
                    </Col>

                </Row>
            </Container>
        </main>
    );
    return (isLoaded ? loaded : loading);
}

export default withRouter(UserRouteApplicationList);


//react 
import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";


//common
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";


//local
import {ApplicationFileInterface} from "./ApplicationFilesForm";
import {getExtension} from "../common/CommonValidation";


interface Props extends RouteComponentProps {
    value: ApplicationFileInterface,
    onDelete: (id:string) => void,
}


const ApplicationFileRow = (props: Props) => {

    
    // const onEdit = () => {
    //     props.onEdit();
    // }
    
    const onDelete = () => {
        props.onDelete(props.value.id);
    }


    return (
        <tr>
            <td>{props.value.fileName}</td>
            <td>{getExtension(props.value.fileName)}</td>
            {/*<td><FontAwesomeIcon icon={faEdit} onClick={onEdit} style={{cursor: "pointer"}}/></td>*/}
            <td><FontAwesomeIcon icon={faTrashAlt} onClick={onDelete} style={{cursor: "pointer"}}/></td>
        </tr>
    );
}


export default withRouter(ApplicationFileRow);



//react 
import React from "react";


//common


//local
import {GetInsuranceMasterInfo} from "../client-axios";



interface Props {
    value : GetInsuranceMasterInfo
    selectedId : string
    onClick:(id: string)=> void;
}



const InsuranceSelectRow =  (props: Props) => {
    
    const onClick=()=>{
        props.onClick(props.value.id);
    }
    const style= props.value.id === props.selectedId ? {background : "yellow"} : {} ;
    return(

        <tr key={props.value.id} onClick={onClick} style={style}>
            <td>
                {props.value.destinationId}
            </td>
            <td>
                {props.value.destinationName}
            </td>

        </tr>

    );
}


export default (InsuranceSelectRow);


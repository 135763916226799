//react 
import React, {useEffect, useState} from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";

//common
import {CommonConfiguration} from "./CommonSetting";
import {
    AdminApiFp,
    GetAccountInfo, PostAdminAccountInfo, PutAdminAccountInfo
} from "../client-axios";
import CommonDialogBox, {DialogStyle} from "./CommonDialogBox";
import {useForm} from "react-hook-form";
import CommonSpinner from "./CommonSpinner";
import RequiredImage from "../control/RequiredImage";
import {format8DateToHaiphongDate} from "./CommonFormat";
import ThrottleButton from "../control/ThrottleButton";

//local


interface Props {
    value?: GetAccountInfo,
    title: string,
    readonly: boolean,
    method: string,
    organizationId: string,
    onUpdate: () => void,
    refresh: boolean,
}

const CommonAdminAccountEditBox = (props: Props) => {
    const methods = useForm({mode: "onBlur"});
    const {register, trigger, handleSubmit, formState: {errors}} = methods;
    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        props.onUpdate();
    }
    const [loadData, setLoadData] = useState<GetAccountInfo | undefined>();
    const [dataLoaded, setDataLoaded] = useState(!props.refresh);


    const GetData = async () => {
        if ( props.value == null) {
            setDataLoaded(true);
            return;}
        if (props.method !== "POST") {
            //値が入っていればGet
            if (props.organizationId != null) {
                const conf = CommonConfiguration();
                const apiGet = await AdminApiFp(conf).apiAdminOrganizationOidAccountIdGet(props.organizationId!, props.value.id)
                try {
                    const ret = await apiGet();
                    if (ret.status === 200) {
                        const v: GetAccountInfo = ret.data;
                        setLoadData(v);
                    } else {
                        setDialogWithClose("エラー : " + ret.status);
                    }
                } catch (e) {
                    //読み取り系例外
                    if (e instanceof Error && e.message === "Network Error") {
                        setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                    } else if (e.response.status === 400 || e.response.status === 500) {
                        setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
                    } else if (e.response.status === 422) {
                        setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
                    } else if (e.response.status === 404) {
                    } else if (e.response.status === 405) {
                        window.location.href = "/timeout";
                    } else {
                        setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                    }
                    setLoadData(undefined);
                }
                setDataLoaded(true);
            }
        }
        setDataLoaded(true);
    }


    useEffect(() => {
        GetData().then();
    }, []);

    const SendPost = async (data) => {
        const conf = CommonConfiguration();
        const sendData: PostAdminAccountInfo =
            {
                organizationId: data.organizationId,
                ossMailAddress: data.ossMailAddress,
                levelKind: data.levelKind,
                ossDispName: data.ossDispName,
                ossName: data.ossName,
                ossAddress: data.ossAddress,
                ossSex: data.ossSex,
                ossBirthday: data.ossBirthday,
                ossTelNo1: data.ossTelNo1,
                ossTelNo2: data.ossTelNo2,
                ossTelNo3: data.ossTelNo3,
                ossCorpNameKanji: data.ossCorpNameKanji,
                ossCorpNameKana: data.ossCorpNameKana,
                attachFileName: data.attachFileName,
                token: "data.token",
                attachFile: data.attachFile,
            };
        const apiPost = await AdminApiFp(conf).apiAdminOrganizationOidAccountPost(props.organizationId!, sendData);
        try {
            const ret = await apiPost();
            if (ret.status === 200) {
                setDialogWithClose("登録しました。");

            } else if (ret.status === 204) {
                setDialogWithClose("登録しました。");
            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("登録に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("登録に失敗しました。");
            }
        }
    };

    const SendPut = async (data) => {
        if ( props.value == null ){return }
        const conf = CommonConfiguration();
        const sendData: PutAdminAccountInfo =
            {
                ossMailAddress: loadData?.ossMailAddress,
                levelKind: data.levelKind,
                ossDispName: data.ossDispName,
                ossName: data.ossName,
                ossAddress: data.ossAddress,
                ossSex: data.ossSex,
                ossBirthday: data.ossBirthday,
                ossTelNo1: data.ossTelNo1,
                ossTelNo2: data.ossTelNo2,
                ossTelNo3: data.ossTelNo3,
                ossCorpNameKanji: data.ossCorpNameKanji,
                ossCorpNameKana: data.ossCorpNameKana,
                attachFileName: data.attachFileName,

                token: "data.token",
                attachFile: data.attachFile,
            };
        const apiPut = await AdminApiFp(conf).apiAdminOrganizationOidAccountIdPut(props.organizationId!, props.value.id, sendData);
        try {
            const ret = await apiPut();
            if (ret.status === 200) {
                setDialogWithClose("更新しました。");

            } else if (ret.status === 204) {
                setDialogWithClose("更新しました。");
            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
    };

    const onSaveDialog = async () => {
        const result = await trigger();
        if(result){
            const data = methods.getValues();
            onSubmit(data);
        }else{
            const errorCount = Object.keys(methods.formState.errors).length;
            methods.setFocus(Object.keys(methods.formState.errors)[0]);

            if ( errorCount > 0) {
                setDialogMessage("項目の入力に誤りがあります。");
                return;
            }
        }
    }
    
    const onSubmit = (data) => {
        if (props.method === "POST") {
            SendPost(data).then();
        } else {
            SendPut(data).then();
        }
    }

    const Loading = (<CommonSpinner />)
    const Loaded = (
        <Modal size={"xl"} show={true} onHide={props.onUpdate} className={"CommonDialogBox"}>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            <Form noValidate>
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dialogStyle">
                    <div className="bodyStyle" style={{height: "80%", maxHeight: "600px", overflowY: "scroll", overflowX: "hidden"}}>

                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                OSSメールアドレス<RequiredImage /><br/>
                                    <span className="subLabel">(最大50文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control required type="text" readOnly={props.readonly} placeholder=""
                                              defaultValue={loadData?.ossMailAddress}
                                              className={"form-control"}
                                              {...register("ossMailAddress", {
                                                  required: !props.readonly,
                                                  maxLength: 50, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                              })}
                                              isInvalid={!!errors.ossMailAddress}/>
                                {errors.ossMailAddress && errors.ossMailAddress.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                                {errors.ossMailAddress && errors.ossMailAddress.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は50文字までです。
                                </Form.Control.Feedback>
                                }
                                {errors.ossMailAddress && errors.ossMailAddress.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    メールアドレスを入れてください。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                種類<RequiredImage />
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control required as="select" placeholder="種類"
                                              defaultValue={loadData?.levelKind}
                                              {...register("levelKind", {required: true})}
                                              isInvalid={!!errors.levelKind}>
                                    <option id="1" value="1">ユーザー</option>
                                    <option id="2" value="2">管理者</option>
                                </Form.Control>
                                {errors.levelKind && errors.levelKind.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                所属等(表示用名称)<br />
                                <span className="subLabel">(全角、最大25文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control type="text" placeholder="" defaultValue={loadData?.ossDispName}
                                    className={"form-control"}
                                    {...register("ossDispName", { required: false, maxLength: 25, pattern: /^[^ -~]+$/ })}
                                    isInvalid={!!errors.ossDispName} />
                                {errors.ossDispName && errors.ossDispName.type === "maxLength" &&
                                    <Form.Control.Feedback type="invalid">
                                        この項目は25文字までです。
                                    </Form.Control.Feedback>
                                }
                                {errors.ossDispName && errors.ossDispName.type === "pattern" &&
                                    <Form.Control.Feedback type="invalid">
                                        全角で入力してください。
                                    </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                OSS申請者氏名
                                    <RequiredImage/><br/>
                                    <span className="subLabel">(全角、最大30文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control type="text" placeholder="" defaultValue={loadData?.ossName}
                                              className={"form-control"}
                                              {...register("ossName", {required: true, maxLength: 30, pattern: /^[^ -~]+$/})}
                                              isInvalid={!!errors.ossName}/>
                                {errors.ossName && errors.ossName.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                                {errors.ossName && errors.ossName.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は30文字までです。
                                </Form.Control.Feedback>
                                }
                                {errors.ossName && errors.ossName.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    全角で入力してください。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                OSS申請者住所
                                    <RequiredImage/><br/>
                                    <span className="subLabel">(全角、最大128文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control type="text" placeholder="" defaultValue={loadData?.ossAddress}
                                              className={"form-control"}
                                              {...register("ossAddress", {required: true, maxLength: 128, pattern: /^[^ -~]+$/})}
                                              isInvalid={!!errors.ossAddress}/>
                                {errors.ossAddress && errors.ossAddress.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                                {errors.ossAddress && errors.ossAddress.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は128文字までです。
                                </Form.Control.Feedback>
                                }
                                {errors.ossAddress && errors.ossAddress.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    全角で入力してください。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                OSS申請者情報 性別
                                <RequiredImage/><br />
                            </Form.Label>
                            <Col md={6}>
                                <Form.Check type={"radio"} {...register("ossSex", {required: true})} label={"男性"} defaultChecked={props.value?.ossSex==="1" || props.value?.ossSex === undefined} value={"1"} />
                                <Form.Check type={"radio"} {...register("ossSex", {required: true})} label={"女性"} defaultChecked={props.value?.ossSex==="2"} value={"2"} />
                                <Form.Check type={"radio"} {...register("ossSex", {required: true})} label={"その他"} defaultChecked={props.value?.ossSex==="3"} value={"3"} />
                                {errors.ossSex && errors.ossSex.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                OSS申請者情報 生年月日
                                    <RequiredImage/><br/>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control type="date" placeholder="" defaultValue={format8DateToHaiphongDate(loadData?.ossBirthday)}
                                              className={"form-control"} max={"2500-12-31"}
                                              {...register("ossBirthday", {required: true})}
                                              isInvalid={!!errors.ossBirthday}/>
                                {errors.ossBirthday && errors.ossBirthday.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                OSS申請者情報 電話番号
                                    <RequiredImage/><br/>
                                    <span className="subLabel">(半角数字、最大5桁 - 最大4桁 - 最大4桁)</span>
                            </Form.Label>

                            <Col xs={6} style={{display: "flex"}}>
                                <div>
                                    <Form.Control type="text" placeholder="" style={{width: 75}}
                                                defaultValue={loadData?.ossTelNo1}
                                                isInvalid={!!errors.ossTelNo1}
                                                {...register("ossTelNo1", {required: true, maxLength: 5, pattern: /^\d+$/})}
                                    />
                                    {errors.ossTelNo1 && errors.ossTelNo1.type === "required" &&
                                    <Form.Control.Feedback type="invalid">
                                        この項目は必須です。
                                    </Form.Control.Feedback>
                                    }
                                    {errors.ossTelNo1 && errors.ossTelNo1.type === "maxLength" &&
                                    <Form.Control.Feedback type="invalid">
                                        この項目は5桁までです。
                                    </Form.Control.Feedback>
                                    }
                                    {errors.ossTelNo1 && errors.ossTelNo1.type === "pattern" &&
                                    <Form.Control.Feedback type="invalid">
                                        半角数字で入力してください。
                                    </Form.Control.Feedback>
                                    }
                                </div>
                                <div style={{width: 105, textAlign: "center", marginTop: 5}}>
                                    -
                                </div>
                                <div>
                                    <Form.Control type="text" placeholder="" style={{width: 75}}
                                                defaultValue={loadData?.ossTelNo2}
                                                isInvalid={!!errors.ossTelNo2}
                                                {...register("ossTelNo2", {required: true, maxLength: 4, pattern: /^\d+$/})}
                                    />
                                    {errors.ossTelNo2 && errors.ossTelNo2.type === "required" &&
                                    <Form.Control.Feedback type="invalid">
                                        この項目は必須です。
                                    </Form.Control.Feedback>
                                    }
                                    {errors.ossTelNo2 && errors.ossTelNo2.type === "maxLength" &&
                                    <Form.Control.Feedback type="invalid">
                                        この項目は4桁までです。
                                    </Form.Control.Feedback>
                                    }
                                    {errors.ossTelNo2 && errors.ossTelNo2.type === "pattern" &&
                                    <Form.Control.Feedback type="invalid">
                                        半角数字で入力してください。
                                    </Form.Control.Feedback>
                                    }
                                </div>
                                <div style={{width: 105, textAlign: "center", marginTop: 5}}>
                                    -
                                </div>
                                <div>
                                    <Form.Control type="text" placeholder="" style={{width: 75}}
                                                defaultValue={loadData?.ossTelNo3}
                                                isInvalid={!!errors.ossTelNo3}
                                                {...register("ossTelNo3", {required: true, maxLength: 4, pattern: /^\d+$/})}
                                    />
                                    {errors.ossTelNo3 && errors.ossTelNo3.type === "required" &&
                                    <Form.Control.Feedback type="invalid">
                                        この項目は必須です。
                                    </Form.Control.Feedback>
                                    }
                                    {errors.ossTelNo3 && errors.ossTelNo3.type === "maxLength" &&
                                    <Form.Control.Feedback type="invalid">
                                        この項目は4桁までです。
                                    </Form.Control.Feedback>
                                    }
                                    {errors.ossTelNo3 && errors.ossTelNo3.type === "pattern" &&
                                    <Form.Control.Feedback type="invalid">
                                        半角数字で入力してください。
                                    </Form.Control.Feedback>
                                    }
                                </div>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                OSS申請者商号（法人名）<br/>
                                    <span className="subLabel">(全角、最大25文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control type="text" placeholder="" defaultValue={loadData?.ossCorpNameKanji}
                                              className={"form-control"}
                                              {...register("ossCorpNameKanji", {required: false, maxLength: 25, pattern: /^[^ -~]+$/})}
                                              isInvalid={!!errors.ossCorpNameKanji}/>
                                {errors.ossCorpNameKanji && errors.ossCorpNameKanji.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は25文字までです。
                                </Form.Control.Feedback>
                                }
                                {errors.ossCorpNameKanji && errors.ossCorpNameKanji.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    全角で入力してください。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                OSS申請者商号（法人名）フリガナ<br/>
                                    <span className="subLabel">(全角カナ、全角空白、全角ハイフン、全角長音、最大60文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control type="text" placeholder="" defaultValue={loadData?.ossCorpNameKana}
                                              className={"form-control"}
                                              {...register("ossCorpNameKana", {required: false, maxLength: 60, pattern: /^[ァ-ヶ‐ー　]+$/})}
                                              isInvalid={!!errors.ossCorpNameKana}/>
                                {errors.ossCorpNameKana && errors.ossCorpNameKana.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は60文字までです。
                                </Form.Control.Feedback>
                                }
                                {errors.ossCorpNameKana && errors.ossCorpNameKana.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    全角カナ、全角空白、全角ハイフン、全角長音で入力してください。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <footer className="footStyle">
                        <Row>
                            <Col xs={12}>
                                <ThrottleButton variant={"secondary"} onClick={props.onUpdate}>戻る</ThrottleButton>
                                <ThrottleButton style={{position: "absolute", right: 0}} onClick={onSaveDialog} variant={"success"}>OK</ThrottleButton>
                            </Col>
                        </Row>
                    </footer>
                </Modal.Footer>
            </Form>
        </Modal>
    )

    return (
        dataLoaded ? Loaded : Loading
   
    )
}

export default (CommonAdminAccountEditBox);

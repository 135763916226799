export const FormatDate = (date: Date | undefined, format: string) => {
    if (format === "") {
        return "";
    }
    if (date == null) {
        return "";
    }
    let result = format;
    const YYYY_value = date.getFullYear();
    const MM_value = 1 + date.getMonth();
    const DD_value = date.getDate();
    const HH_value = date.getHours();
    const mm_value = date.getMinutes();
    const ss_value = date.getSeconds();

    const YYYY = YYYY_value.toString();
    const MM = ('0' + MM_value).slice(-2);
    const DD = ('0' + DD_value).slice(-2);
    const HH = ('0' + HH_value).slice(-2);
    const mm = ('0' + mm_value).slice(-2);
    const ss = ('0' + ss_value).slice(-2);

    result = result.replace(/YYYY/g, YYYY);
    result = result.replace(/MM/g, MM);
    result = result.replace(/DD/g, DD);
    result = result.replace(/HH/g, HH);
    result = result.replace(/mm/g, mm);
    result = result.replace(/ss/g, ss);

    return result;
};

export const format10DateToHaiphongDate = (target: string | undefined) => {
    if (target == null) {
        return undefined;
    } else {
        const YMD = (target + '0000000000');
        return YMD.substr(0, 4) + "-" + YMD.substr(5, 2) + "-" + YMD.substr(8, 2);
    }
}

export const format8DateToHaiphongDate = (target: string | undefined) => {
    if (target == null) {
        return undefined;
    } else {
        const YMD = (target + '00000000');
        return YMD.substr(0, 4) + "-" + YMD.substr(4, 2) + "-" + YMD.substr(6, 2);
    }
}
export const format422DateToHaiphongDate = (Y: string | undefined, M: string | undefined, D: string | undefined) => {
    if (Y == null && M == null && D == null) {
        return undefined;
    } else {
        const YMD = ((Y ?? "0000") + (M ?? "00") + (D ?? "00") + '00000000');
        return YMD.substr(0, 4) + "-" + YMD.substr(4, 2) + "-" + YMD.substr(6, 2);
    }
}
export const formatHaiphongDateTo8 = (target: string | undefined) => {
    if (target == null) {
        return undefined;
    } else {
        return target.replace(/-/g, "");
    }
}

export const formatHaiphongDateToY = (target: string | undefined) => {
    if (target == null) {
        return undefined;
    } else {
        const YMD = (target + '00000000')
        return YMD.substr(0, 4);
    }
}
export const formatHaiphongDateToM = (target: string | undefined) => {
    if (target == null) {
        return undefined;
    } else {
        const YMD = (target + '00000000')
        return YMD.substr(5, 2);
    }
}
export const formatHaiphongDateToD = (target: string | undefined) => {
    if (target == null) {
        return undefined;
    } else {
        const YMD = (target + '00000000')
        return YMD.substr(8, 2);
    }
}
export const formatHaiphongDateToSlashYMD = (target: string | undefined) => {
    if (target == null) {
        return undefined;
    }
    if (target.length <= 10) {
        return undefined;
    }

    return formatHaiphongDateToY(target) + "/" + formatHaiphongDateToM(target) + "/" + formatHaiphongDateToD(target);
}

export const plus10year = () => {
    const dt = new Date();
    dt.setFullYear(dt.getFullYear() + 10);
    return FormatDate(dt, "YYYY-MM-DD");
}
export const plus100year = () => {
    const dt = new Date();
    dt.setFullYear(dt.getFullYear() + 100);
    return FormatDate(dt, "YYYY-MM-DD");
}
export const minus100year = () => {
    const dt = new Date();
    dt.setFullYear(dt.getFullYear() - 100);
    return FormatDate(dt, "YYYY-MM-DD");
}

export const plus5year = () => {
    const dt = new Date();
    dt.setFullYear(dt.getFullYear() + 5);
    return FormatDate(dt, "YYYY-MM-DD");
}
export const minus5year = () => {
    const dt = new Date();
    dt.setFullYear(dt.getFullYear() - 5);
    return FormatDate(dt, "YYYY-MM-DD");
}

export const ymdMixing = (y: string | undefined, m: string | undefined, d: string | undefined): Date | undefined => {
    try {
        return new Date(y + "/" + m + "/" + d);
    } catch {
    }

    return undefined

}
//react 
import React, {FormEventHandler, useCallback} from "react";


//common


//local
import {Button} from "react-bootstrap";
import {throttle} from "lodash";
import {ButtonProps, ButtonType} from "react-bootstrap/Button";

interface Props extends ButtonProps  {
}


const ThrottleButton = (props: Props) => {
    const { onClick, ...rest } = props
    const onClickFunc = (newValue)=>{
        throttleFn(newValue);
    }
    const onThrottleClick = (v)=>{
        if (props.onClick !=null)
        {
            props.onClick(v)
        }
    }
    const throttleFn = useCallback(throttle(onThrottleClick, 500),[]);

    return <Button onClick={onClickFunc} {...rest}></Button>
}
export default (ThrottleButton);


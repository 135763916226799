
//react 
import React from "react";
import {Col, Row} from "react-bootstrap";
import {useDispatch, useStore} from "react-redux";
import {createUrl, Params, query2params} from "../user/ApplicationTable";
import {setSearchKey} from "../globalReduxActions";


//common


//local



interface Props {
    value : number, //0始まり
    onPaging : (number)=>void,
    maxPage : number //0始まり
    history?: any
}

export const param2query = (param: Params): string => {
    const query = "search=" + param.search + "&display=" + (param.checked === true ? "all" : "") + "&status=" + (param.radioChecked === true ? "" : "all") + "&page=" + param.page;
    return query;
}

const Paging =  (props: Props) => {

    const store = useStore()
    const state = store.getState()
    const query: string = state.globalSearchKey;
    const paramsCurrent: Params = query2params(query);
    const paramsNext: Params = query2params(query);
    const paramsNext2: Params = query2params(query);
    const paramsPreview: Params = query2params(query);
    const paramsPreview2: Params = query2params(query);

    // 現在のページ番号URL
    paramsCurrent.page = props.value + 1;
    const urlCurrent: string = createUrl(paramsCurrent);

    // 次のページ番号URL
    paramsNext.page = props.value + 1 + 1;
    const urlNext: string = createUrl(paramsNext);

    // ２つ次のページ番号URL
    paramsNext2.page = props.value + 2 + 1;
    const urlNext2: string = createUrl(paramsNext2);

    // 前のページ番号URL
    paramsPreview.page = (props.value - 1 + 1) <= 0 ? 1 : props.value;
    const urlPreview: string = createUrl(paramsPreview);

    // ２つ前のページ番号URL
    paramsPreview2.page = (props.value - 2 + 1) <= 0 ? 1 : props.value - 2 + 1;
    const urlPreview2: string = createUrl(paramsPreview2);

    const dispatch = useDispatch();

    const onCurrent = () => {
        props.onPaging(props.value);
        if (document.activeElement != null) {
            // @ts-ignore
            document.activeElement.blur();
        }
        dispatch(setSearchKey(param2query(paramsCurrent)));
        props.history?.push(urlCurrent);
        // window.location.href = urlCurrent;
    }

    // 1つ前へ
    const onPreview = () => {
        props.onPaging(props.value - 1);
        if (document.activeElement != null) {
            // @ts-ignore
            document.activeElement.blur();
        }
        dispatch(setSearchKey(param2query(paramsPreview)));
        props.history?.push(urlPreview);
        // window.location.href = urlPreview;
    }

    // 1つ先へ
    const onNext = () => {
        props.onPaging(props.value + 1);
        if (document.activeElement != null) {
            // @ts-ignore
            document.activeElement.blur();
        }
        dispatch(setSearchKey(param2query(paramsNext)));
        props.history?.push(urlNext);
        // window.location.href = urlNext;
    }

    // 2つ前へ
    const onClickM2 = () => {
        props.onPaging(props.value - 2);
        if (document.activeElement != null) {
            // @ts-ignore
            document.activeElement.blur();
        }
        dispatch(setSearchKey(param2query(paramsPreview2)));
        props.history?.push(urlPreview2);
        // window.location.href = urlPreview2;
    }

    // 2つ先へ
    const onClickP2 = () => {
        props.onPaging(props.value + 2);
        if (document.activeElement != null) {
            // @ts-ignore
            document.activeElement.blur();
        }
        dispatch(setSearchKey(param2query(paramsNext2)));
        props.history?.push(urlNext2);
        // window.location.href = urlNext2;
    }

    return (
        <Row>
            <Col>

                <nav aria-label="Page navigation" style={{float: "right"}}>
                    <ul className="pagination">
                        <li className="page-item" onClick={onPreview}><a className="page-link" href="#">&lt;</a></li>
                        {1 < props.value && <li className="page-item" onClick={onClickM2}><a className="page-link" href="#">{props.value - 1}</a></li>}
                        {0 < props.value && <li className="page-item" onClick={onPreview}><a className="page-link" href="#">{props.value}</a></li>}
                        {<li className="page-item page-current"><a className="page-link" href="#">{props.value + 1}</a></li>}
                        {props.maxPage > props.value && <li className="page-item" onClick={onNext}><a className="page-link" href="#">{props.value + 2}</a></li>}
                        {props.maxPage > props.value + 1 && <li className="page-item" onClick={onClickP2}><a className="page-link" href="#">{props.value + 3}</a></li>}
                        <li className="page-item" onClick={onNext}><a className="page-link" href="#">&gt;</a></li>
                    </ul>
                </nav>
            </Col>
        </Row>
    );
}

export default (Paging);


//react 
import React, {useState} from "react";


//common
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


//local
import {InsuranceItem} from "../client-axios";
import InsuranceEditBox from "./InsuranceEditBox";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";


interface Props {
    value: InsuranceItem,
    onDeleted: (id) => void,
    onEdited: (item : InsuranceItem) => void,
}


const OrganizationInsuranceRow = (props: Props) => {

    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }  
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
    }

    //編集系
    const [isShowInsuranceEditBox, setIsShowInsuranceEditBox] = useState(false);
    const onEditButtonClick = () => {
        setIsShowInsuranceEditBox(true)
    }
    const onInsuranceUpdate = (item) => {
        props.onEdited(item);
        setIsShowInsuranceEditBox(false)
    }
    const onInsuranceClose = () => {
        setIsShowInsuranceEditBox(false)
    }

    //削除系
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
    const onDeleteButtonClick = () => {
        setIsShowDeleteDialog(true)
    }
    const onDeleteYes = () => {
        props.onDeleted(props.value.id);
        setIsShowDeleteDialog(false);
    }
    const onDeleteNo = () => {
        setIsShowDeleteDialog(false)
    }


    return (

        <tr>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            {isShowInsuranceEditBox && <InsuranceEditBox onUpdate={onInsuranceUpdate} onClose={onInsuranceClose} value={props.value}/>}
            {isShowDeleteDialog && <CommonDialogBox value={"選択された保健組合を削除しますか？"} style={DialogStyle.yesNo}
                                                    onClickYes={onDeleteYes} onClickNo={onDeleteNo}/>}
            <td>
                {props.value.destinationId}
            </td>
            <td>
                {props.value.destinationName}
            </td>
            <td>
                {props.value.jigyousyoSeirikigouPref}
            </td>
            <td>
                {props.value.jigyousyoSeirikigouGunshiku}
            </td>
            <td>
                {props.value.jigyousyoSeirikigouKigou}
            </td>
            <td>
                {props.value.jigyousyoSeirikigouBangou}
            </td>
            <td><FontAwesomeIcon icon={faEdit} onClick={onEditButtonClick} style={{cursor: "pointer"}}/></td>
            <td><FontAwesomeIcon icon={faTrashAlt} onClick={onDeleteButtonClick} style={{cursor: "pointer"}}/></td>

        </tr>

    );
}


export default (OrganizationInsuranceRow);


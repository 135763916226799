//react 
import React from "react";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {Card, CardGroup, Container} from "react-bootstrap";


//common


//local
import ThrottleButton from "../control/ThrottleButton";


interface Props extends RouteComponentProps {
}


const AdminRouteMenu = (props: Props) => {
    document.title = "運用者メニュー";

    const onGotoList = () => {
        props.history.push("/admin/organizationlist");
    }

    const onGotoInsuranceMaster = () => {
        props.history.push("/admin/InsuranceMaster");
    }
    
    const onSignOutClick = () => {
        window.location.href = "/admin/SignOut";

    }

    return (
        <main role="main" className="flex-shrink-0">
            <Container fluid={"xl"}>
                <h1>運用者メニュー</h1>
                <CardGroup>

                    <Card className={"mr-3"} style={{width: '18rem'}}>
                        <Card.Title className={"card-title bg-primary"}>企業情報管理</Card.Title>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <Card.Text>
                                企業一覧より、企業情報の新規作成、編集、削除、メンバーの追加、編集、削除を実施します。
                            </Card.Text>
                            <div style={{textAlign: 'center'}}>
                                <ThrottleButton variant={"primary"} onClick={onGotoList}>一覧へ</ThrottleButton>
                            </div>
                        </Card.Body>
                    </Card>
                    

                    <Card className={"mr-3"} style={{width: '18rem'}}>
                        <Card.Title className={"card-title bg-primary"}>保険組合マスター</Card.Title>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <Card.Text>
                                保険組合マスタCSVの登録を実施します。
                            </Card.Text>
                            <div style={{textAlign: 'center'}}>
                                <ThrottleButton variant={"primary"} onClick={onGotoInsuranceMaster}>管理へ</ThrottleButton>
                            </div>
                        </Card.Body>
                    </Card>

                </CardGroup>
            </Container>
        </main>
    );

}

export default withRouter(AdminRouteMenu);


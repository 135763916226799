/* tslint:disable */
/* eslint-disable */
/**
 * mynap API
 * mynap API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountInfo
 */
export interface AccountInfo {
    /**
     * id
     * @type {string}
     * @memberof AccountInfo
     */
    'id': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AccountInfo
     */
    'mailAddress'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof AccountInfo
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AdminAccountInfo
 */
export interface AdminAccountInfo {
    /**
     * id
     * @type {string}
     * @memberof AdminAccountInfo
     */
    'id': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AdminAccountInfo
     */
    'mailAddress'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof AdminAccountInfo
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationList
 */
export interface ApplicationList {
    /**
     * 
     * @type {number}
     * @memberof ApplicationList
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetApplicationRow>}
     * @memberof ApplicationList
     */
    'items'?: Array<GetApplicationRow>;
}
/**
 * 
 * @export
 * @interface ApplicationLogItem
 */
export interface ApplicationLogItem {
    /**
     * ID
     * @type {string}
     * @memberof ApplicationLogItem
     */
    'id': string;
    /**
     * 日付
     * @type {string}
     * @memberof ApplicationLogItem
     */
    'receiveDate'?: string;
    /**
     * ログ
     * @type {string}
     * @memberof ApplicationLogItem
     */
    'logMessage'?: string;
    /**
     * ログフル
     * @type {string}
     * @memberof ApplicationLogItem
     */
    'logMessageFull'?: string;
    /**
     * ログURL
     * @type {string}
     * @memberof ApplicationLogItem
     */
    'logMessageURL'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationRowItem
 */
export interface ApplicationRowItem {
    /**
     * ID
     * @type {string}
     * @memberof ApplicationRowItem
     */
    'id': string;
    /**
     * 行番号
     * @type {number}
     * @memberof ApplicationRowItem
     */
    'rowNumber'?: number;
    /**
     * ファイル名
     * @type {string}
     * @memberof ApplicationRowItem
     */
    'fileName'?: string;
    /**
     * バイナリー
     * @type {string}
     * @memberof ApplicationRowItem
     */
    'binary'?: string;
}
/**
 * 
 * @export
 * @interface GetAccessKey
 */
export interface GetAccessKey {
    /**
     * アクセスキー
     * @type {string}
     * @memberof GetAccessKey
     */
    'accesskey'?: string;
    /**
     * アクセスURL
     * @type {string}
     * @memberof GetAccessKey
     */
    'accessUrl'?: string;
}
/**
 * 
 * @export
 * @interface GetAccountInfo
 */
export interface GetAccountInfo {
    /**
     * id
     * @type {string}
     * @memberof GetAccountInfo
     */
    'id': string;
    /**
     * id
     * @type {string}
     * @memberof GetAccountInfo
     */
    'orgnizationId'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossMailAddress'?: string;
    /**
     * gBizのキー
     * @type {string}
     * @memberof GetAccountInfo
     */
    'token'?: string;
    /**
     * 利用者レベル0=未認証, 1=gBizプライム,2=gBinメンバー
     * @type {number}
     * @memberof GetAccountInfo
     */
    'levelKind'?: number;
    /**
     * 所属等(表示用名称)
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossDispName'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossName'?: string;
    /**
     * OSS申請者住所
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossAddress'?: string;
    /**
     * OSS申請者情報 性別(1：男、2：女、3：不明)
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossSex'?: string;
    /**
     * OSS申請者情報 生年月日(YYYYMMDD)
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossBirthday'?: string;
    /**
     * OSS申請者情報 電話番号市外局番
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossTelNo1'?: string;
    /**
     * OSS申請者情報 電話番号市内局番
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossTelNo2'?: string;
    /**
     * OSS申請者情報 電話番号加入者番号
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossTelNo3'?: string;
    /**
     * OSS申請者商号（法人名）
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossCorpNameKanji'?: string;
    /**
     * OSS申請者商号（法人名）ふりがな
     * @type {string}
     * @memberof GetAccountInfo
     */
    'ossCorpNameKana'?: string;
    /**
     * 提出代行証明書の実ファイル名
     * @type {string}
     * @memberof GetAccountInfo
     */
    'attachFileName'?: string;
    /**
     * 提出代行証明書
     * @type {string}
     * @memberof GetAccountInfo
     */
    'attachFile'?: string;
    /**
     * 前回サインイン日時
     * @type {string}
     * @memberof GetAccountInfo
     */
    'lastSigninDate'?: string;
}
/**
 * 
 * @export
 * @interface GetAdminAccountInfo
 */
export interface GetAdminAccountInfo {
    /**
     * id
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'id': string;
    /**
     * 組織ID
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'organizationId'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossMailAddress'?: string;
    /**
     * gBizのキー
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'token'?: string;
    /**
     * 利用者レベル0=未認証, 1=gBizプライム,2=gBinメンバー
     * @type {number}
     * @memberof GetAdminAccountInfo
     */
    'levelKind'?: number;
    /**
     * 所属等(表示用名称)
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossDispName'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossName'?: string;
    /**
     * 提出代行証明書
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'attachFile'?: string;
    /**
     * OSS申請者住所
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossAddress'?: string;
    /**
     * OSS申請者情報 性別(1：男、2：女、3：不明)
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossSex'?: string;
    /**
     * OSS申請者情報 生年月日(YYYYMMDD)
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossBirthday'?: string;
    /**
     * OSS申請者情報 電話番号市外局番
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossTelNo1'?: string;
    /**
     * OSS申請者情報 電話番号市内局番
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossTelNo2'?: string;
    /**
     * OSS申請者情報 電話番号加入者番号
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossTelNo3'?: string;
    /**
     * OSS申請者商号（法人名）
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossCorpNameKanji'?: string;
    /**
     * OSS申請者商号（法人名）ふりがな
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'ossCorpNameKana'?: string;
    /**
     * 提出代行証明書の実ファイル名
     * @type {string}
     * @memberof GetAdminAccountInfo
     */
    'attachFileName'?: string;
}
/**
 * 
 * @export
 * @interface GetAdminOrganizationInfo
 */
export interface GetAdminOrganizationInfo {
    /**
     * id
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'id': string;
    /**
     * 法人番号
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpNo'?: string;
    /**
     * 商号（法人名）
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpNameKanji'?: string;
    /**
     * 商号（法人名）ふりがな
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpNameKana'?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpKind'?: string;
    /**
     * 法人格がその他の場合の入力欄
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpKindEtc'?: string;
    /**
     * 法人格前後区分
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpKindBA'?: string;
    /**
     * 1=AAD、2=統合認証
     * @type {number}
     * @memberof GetAdminOrganizationInfo
     */
    'provider'?: number;
    /**
     * 統合認証用URL
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'signInUrl'?: string;
    /**
     * クライアントID
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'clientId'?: string;
    /**
     * 
     * @type {Array<InsuranceItem>}
     * @memberof GetAdminOrganizationInfo
     */
    'insurances'?: Array<InsuranceItem>;
    /**
     * 本店又は主たる事務所の所在地 郵便番号
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpYubin'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 都道府県
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpPref'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 市区町村
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpShikuchouson'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 番地以下
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpBanchi'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 それ以降の住所
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpIkou'?: string;
    /**
     * 代表者氏
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeFamilyName'?: string;
    /**
     * 代表者名
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeFirstName'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市外局番
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpTelNo1'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市内局番
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpTelNo2'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 加入者番号
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'corpTelNo3'?: string;
    /**
     * 代表者氏（カナ）
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeFamilyNameKana'?: string;
    /**
     * 代表者名（カナ）
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeFirstNameKana'?: string;
    /**
     * 代表者郵便番号
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeYubin'?: string;
    /**
     * 代表者都道府県
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativePref'?: string;
    /**
     * 代表者市区町村
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeShikuchouson'?: string;
    /**
     * 代表者番地以下
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeBanchi'?: string;
    /**
     * 代表者それ以降の住所
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeIkou'?: string;
    /**
     * 代表者市外局番
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeTelNo1'?: string;
    /**
     * 代表者市内局番
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeTelNo2'?: string;
    /**
     * 代表者加入者番号
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeTelNo3'?: string;
    /**
     * 代表者メールアドレス
     * @type {string}
     * @memberof GetAdminOrganizationInfo
     */
    'representativeMailAddress'?: string;
}
/**
 * 
 * @export
 * @interface GetAdminOrganizationList
 */
export interface GetAdminOrganizationList {
    /**
     * 
     * @type {Array<GetAdminOrganizationInfo>}
     * @memberof GetAdminOrganizationList
     */
    'items'?: Array<GetAdminOrganizationInfo>;
}
/**
 * 
 * @export
 * @interface GetApplicationInfo
 */
export interface GetApplicationInfo {
    /**
     * ID
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'id': string;
    /**
     * 申請日
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'applicationDate'?: string;
    /**
     * 申請種類
     * @type {number}
     * @memberof GetApplicationInfo
     */
    'applicationKind'?: number;
    /**
     * ステータス
     * @type {number}
     * @memberof GetApplicationInfo
     */
    'statusKind'?: number;
    /**
     * 申請タイトル
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'searchTitle'?: string;
    /**
     * 申請備考
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'searchBikou'?: string;
    /**
     * 申請データ行数
     * @type {number}
     * @memberof GetApplicationInfo
     */
    'rowCount'?: number;
    /**
     * 仮申請番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'tempApplicationNo'?: string;
    /**
     * 申請番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'applicationNo'?: string;
    /**
     * 提出元ID
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'teishutumotoId'?: string;
    /**
     * 通番
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'tsuban'?: string;
    /**
     * 識別情報年
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'sikibetuJouhouY'?: string;
    /**
     * 識別情報月
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'sikibetuJouhouM'?: string;
    /**
     * 識別情報日
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'sikibetuJouhouD'?: string;
    /**
     * 事業所整理記号都道府県コード
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyousyoSeirikigouPref'?: string;
    /**
     * 事業所整理記号郡市区記号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyousyoSeirikigouGunshiku'?: string;
    /**
     * 事業所整理記号事業所記号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyousyoSeirikigouKigou'?: string;
    /**
     * 事業所整理記号事業所番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyousyoSeirikigouBangou'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 資格取得届/70歳以上被用者該当届（件）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'kenpoSikakuShutoku'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 被扶養者異動届/国民年金第3号被保険者関係届（件）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'kenpoHihuyousha'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 資格喪失届/70歳以上被用者不該当届（件）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'kenpoSikakuSoushitsu'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 月額変更届/70歳以上被用者月額変更届（件）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'kenpoGetsugakuHenkou'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 算定基礎届/70歳以上被用者算定基礎届（件）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'kenpoSanteiKiso'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数賞与支払届/70歳以上被用者賞与支払届（件）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'kenpoShouyoShiharai'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数届書合計（件）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'kenpoGoukei'?: string;
    /**
     * 国民年金　届書総件数 国民年金第3号被保険者関係届（件）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'kokuminNenkin3gou'?: string;
    /**
     * 国民年金　届書総件数 届書合計（件）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'kokuminNenkinGoukei'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'bikou'?: string;
    /**
     * 事業所所在地郵便番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyoushoYubin'?: string;
    /**
     * 事業所所在地都道府県
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyoushoPref'?: string;
    /**
     * 事業所所在地市区町村
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyoushoShikuchouson'?: string;
    /**
     * 事業所所在地番地以下
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyoushoBanchi'?: string;
    /**
     * 事業所所在地それ以降の住所
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyoushoIkou'?: string;
    /**
     * 事業所名称
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyoushoName'?: string;
    /**
     * 事業主氏
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyounushiFamilyName'?: string;
    /**
     * 事業主名
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'jigyounushiFirstName'?: string;
    /**
     * 電話番号市外局番
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'telNo1'?: string;
    /**
     * 電話番号市内局番
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'telNo2'?: string;
    /**
     * 電話番号加入者番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'telNo3'?: string;
    /**
     * 提出年
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'applicantsY'?: string;
    /**
     * 提出月
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'applicantsM'?: string;
    /**
     * 提出日
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'applicantsD'?: string;
    /**
     * 社会保険労務士の提出代行者名氏
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'sharoushiFamilyName'?: string;
    /**
     * 社会保険労務士の提出代行者名名
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'sharoushiFirstName'?: string;
    /**
     * 通知書希望形式
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'tsuuchisyoKibou'?: string;
    /**
     * 提出元事業所法人番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpName'?: string;
    /**
     * 提出先健康保険組合保険者番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'destHokenKumiaiNo'?: string;
    /**
     * 提出先健康保険組合名称
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'destHokenKumiaiName'?: string;
    /**
     * 郵送
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'attachedPost'?: string;
    /**
     * 電子
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'attachedE'?: string;
    /**
     * なし
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'attachedNone'?: string;
    /**
     * 取り下げ可否フラグ
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'cancelAvailability'?: string;
    /**
     * 取り下げ理由設定可否フラグ
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'cancelCauseAvailability'?: string;
    /**
     * 社会保険労務士の提出代行者名氏
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'cancelReason'?: string;


    /**
     * 提出先情報ID
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'destinationId'?: string;

    /**
     * 提出先名
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'destinationName'?: string;
    /**
     * 拡張コード１
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'destinationExtra1'?: string;
    /**
     * 法人番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpNo'?: string;
    /**
     * 商号（法人名）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpNameKanji'?: string;
    /**
     * 商号（法人名）ふりがな
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpNameKana'?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpKind'?: string;
    /**
     * 法人格がその他の場合の入力欄
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpKindEtc'?: string;
    /**
     * 法人格前後区分
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpKindBA'?: string;
    /**
     * 本店又は主たる事務所の所在地 郵便番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpYubin'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 都道府県
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpPref'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 市区町村
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpShikuchouson'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 番地以下
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpBanchi'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 それ以降の住所
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpIkou'?: string;
    /**
     * 代表者氏
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeFamilyName'?: string;
    /**
     * 代表者名
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeFirstName'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市外局番
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpTelNo1'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市内局番
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpTelNo2'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 加入者番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'corpTelNo3'?: string;
    /**
     * 代表者氏（カナ）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeFamilyNameKana'?: string;
    /**
     * 代表者名（カナ）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeFirstNameKana'?: string;
    /**
     * 代表者郵便番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeYubin'?: string;
    /**
     * 代表者都道府県
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativePref'?: string;
    /**
     * 代表者市区町村
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeShikuchouson'?: string;
    /**
     * 代表者番地以下
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeBanchi'?: string;
    /**
     * 代表者それ以降の住所
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeIkou'?: string;
    /**
     * 代表者市外局番
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeTelNo1'?: string;
    /**
     * 代表者市内局番
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeTelNo2'?: string;
    /**
     * 代表者加入者番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeTelNo3'?: string;
    /**
     * 代表者メールアドレス
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'representativeMailAddress'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossName'?: string;
    /**
     * OSS申請者住所
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossAddress'?: string;
    /**
     * OSS申請者情報 性別(1：男、2：女、3：不明)
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossSex'?: string;
    /**
     * OSS申請者情報 生年月日(YYYYMMDD)
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossBirthday'?: string;
    /**
     * OSS申請者情報 メールアドレス
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossMailAddress'?: string;
    /**
     * OSS申請者情報 電話番号市外局番
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossTelNo1'?: string;
    /**
     * OSS申請者情報 電話番号市内局番
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossTelNo2'?: string;
    /**
     * OSS申請者情報 電話番号加入者番号
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossTelNo3'?: string;
    /**
     * OSS申請者商号（法人名）
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossCorpNameKanji'?: string;
    /**
     * OSS申請者商号（法人名）ふりがな
     * @type {string}
     * @memberof GetApplicationInfo
     */
    'ossCorpNameKana'?: string;
    /**
     * 提出代行証明書の送信フラグ 0=送信しない 1=送信する
     * @type {number}
     * @memberof GetApplicationInfo
     */
    'sendAttachFile'?: number;
    /**
     * 
     * @type {Array<ApplicationLogItem>}
     * @memberof GetApplicationInfo
     */
    'logs'?: Array<ApplicationLogItem>;
}
/**
 * 
 * @export
 * @interface GetApplicationLog
 */
export interface GetApplicationLog {
    /**
     * 主キー
     * @type {string}
     * @memberof GetApplicationLog
     */
    'id'?: string;
    /**
     * 申請キー
     * @type {string}
     * @memberof GetApplicationLog
     */
    'applicationId'?: string;
    /**
     * ログを受け取った日付
     * @type {string}
     * @memberof GetApplicationLog
     */
    'receiveDate'?: string;
    /**
     * ログメッセージ1
     * @type {string}
     * @memberof GetApplicationLog
     */
    'logMessage'?: string;
    /**
     * ログメッセージ2
     * @type {string}
     * @memberof GetApplicationLog
     */
    'logMessageFull'?: string;
    /**
     * ログのURL（公文書のURL）
     * @type {string}
     * @memberof GetApplicationLog
     */
    'logMessageURL'?: string;
    /**
     * システム利用メモエリア
     * @type {string}
     * @memberof GetApplicationLog
     */
    'systemNote'?: string;
}
/**
 * 
 * @export
 * @interface GetApplicationRow
 */
export interface GetApplicationRow {
    /**
     * ID
     * @type {string}
     * @memberof GetApplicationRow
     */
    'id': string;
    /**
     * 申請日
     * @type {string}
     * @memberof GetApplicationRow
     */
    'applicationDate'?: string;
    /**
     * 申請種類
     * @type {number}
     * @memberof GetApplicationRow
     */
    'applicationKind'?: number;
    /**
     * ステータス
     * @type {number}
     * @memberof GetApplicationRow
     */
    'statusKind'?: number;
    /**
     * 検索用タイトル
     * @type {string}
     * @memberof GetApplicationRow
     */
    'searchTitle'?: string;
    /**
     * 検索用備考
     * @type {string}
     * @memberof GetApplicationRow
     */
    'searchBikou'?: string;
    /**
     * 申請データ行数
     * @type {number}
     * @memberof GetApplicationRow
     */
    'rowCount'?: number;
    /**
     * ファイル名
     * @type {string}
     * @memberof GetApplicationRow
     */
    'fileName'?: string;
    /**
     * ファイル実体
     * @type {object}
     * @memberof GetApplicationRow
     */
    'binary'?: object;
    /**
     * 公文書あり
     * @type {boolean}
     * @memberof GetApplicationRow
     */
    'hasDocument'?: boolean;
    /**
     * 新規公文書あり
     * @type {boolean}
     * @memberof GetApplicationRow
     */
    'hasNewDocument'?: boolean;
    /**
     * 作成者名
     * @type {string}
     * @memberof GetApplicationRow
     */
    'ossName'?: string;
    /**
     * 作成者のGUID
     * @type {string}
     * @memberof GetApplicationRow
     */
    'ossCreateAt'?: string;
    /**
     * 仮申請番号
     * @type {string}
     * @memberof GetApplicationRow
     */
    'tempApplicationNo'?: string;
    /**
     * 申請番号
     * @type {string}
     * @memberof GetApplicationRow
     */
    'applicationNo'?: string;
    /**
     * 終了フラグ
     * @type {number}
     * @memberof GetApplicationRow
     */
    'endFlag'?: number;
}
/**
 * 
 * @export
 * @interface GetInsuranceMasterInfo
 */
export interface GetInsuranceMasterInfo {
    /**
     * ID
     * @type {string}
     * @memberof GetInsuranceMasterInfo
     */
    'id': string;
    /**
     * 提出先情報ID
     * @type {string}
     * @memberof GetInsuranceMasterInfo
     */
    'destinationId'?: string;
    /**
     * 提出先名
     * @type {string}
     * @memberof GetInsuranceMasterInfo
     */
    'destinationName'?: string;
    /**
     * 拡張コード１
     * @type {string}
     * @memberof GetInsuranceMasterInfo
     */
    'destinationExtra1'?: string;
}
/**
 * 
 * @export
 * @interface GetInsuranceMasterList
 */
export interface GetInsuranceMasterList {
    /**
     * 
     * @type {number}
     * @memberof GetInsuranceMasterList
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetInsuranceMasterInfo>}
     * @memberof GetInsuranceMasterList
     */
    'items'?: Array<GetInsuranceMasterInfo>;
}
/**
 * 
 * @export
 * @interface GetOrganizationInfo
 */
export interface GetOrganizationInfo {
    /**
     * id
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'id': string;
    /**
     * 法人番号
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpNo'?: string;
    /**
     * 商号（法人名）
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpNameKanji'?: string;
    /**
     * 商号（法人名）ふりがな
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpNameKana'?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpKind'?: string;
    /**
     * 法人格がその他の場合の入力欄
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpKindEtc'?: string;
    /**
     * 法人格前後区分
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpKindBA'?: string;
    /**
     * 
     * @type {Array<InsuranceItem>}
     * @memberof GetOrganizationInfo
     */
    'insuranceList'?: Array<InsuranceItem>;
    /**
     * 本店又は主たる事務所の所在地 郵便番号
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpYubin'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 都道府県
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpPref'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 市区町村
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpShikuchouson'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 番地以下
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpBanchi'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 それ以降の住所
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpIkou'?: string;
    /**
     * 代表者氏
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeFamilyName'?: string;
    /**
     * 代表者名
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeFirstName'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市外局番
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpTelNo1'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市内局番
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpTelNo2'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 加入者番号
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'corpTelNo3'?: string;
    /**
     * 代表者氏（カナ）
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeFamilyNameKana'?: string;
    /**
     * 代表者名（カナ）
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeFirstNameKana'?: string;
    /**
     * 代表者郵便番号
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeYubin'?: string;
    /**
     * 代表者都道府県
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativePref'?: string;
    /**
     * 代表者市区町村
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeShikuchouson'?: string;
    /**
     * 代表者番地以下
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeBanchi'?: string;
    /**
     * 代表者それ以降の住所
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeIkou'?: string;
    /**
     * 代表者市外局番
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeTelNo1'?: string;
    /**
     * 代表者市内局番
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeTelNo2'?: string;
    /**
     * 代表者加入者番号
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeTelNo3'?: string;
    /**
     * 代表者メールアドレス
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'representativeMailAddress'?: string;
    /**
     * 1=AAD、2=統合認証
     * @type {number}
     * @memberof GetOrganizationInfo
     */
    'provider'?: number;
    /**
     * 統合認証用URL
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'signInUrl'?: string;
    /**
     * クライアントID
     * @type {string}
     * @memberof GetOrganizationInfo
     */
    'clientId'?: string;
}
/**
 * 
 * @export
 * @interface GetOrganizationList
 */
export interface GetOrganizationList {
    /**
     * 
     * @type {Array<GetOrganizationInfo>}
     * @memberof GetOrganizationList
     */
    'items'?: Array<GetOrganizationInfo>;
}
/**
 * 
 * @export
 * @interface GetSystemMessageInfo
 */
export interface GetSystemMessageInfo {
    /**
     * 画面コード 001: 利用者サインイン画面  004: 利用者トップ画面 
     * @type {string}
     * @memberof GetSystemMessageInfo
     */
    'viewCode': string;
    /**
     * ロール 0 : ALL 1 : 利用者 2 : 管理者 
     * @type {number}
     * @memberof GetSystemMessageInfo
     */
    'labelKind'?: number;
    /**
     * 適用開始日時
     * @type {string}
     * @memberof GetSystemMessageInfo
     */
    'applyStartDate'?: string;
    /**
     * 適用終了日時
     * @type {string}
     * @memberof GetSystemMessageInfo
     */
    'applyEndDate'?: string;
    /**
     * 終了
     * @type {number}
     * @memberof GetSystemMessageInfo
     */
    'completed'?: number;
    /**
     * メッセージ
     * @type {string}
     * @memberof GetSystemMessageInfo
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetSystemMessageList
 */
export interface GetSystemMessageList {
    /**
     * 
     * @type {number}
     * @memberof GetSystemMessageList
     */
    'count'?: number;
    /**
     * 
     * @type {Array<GetSystemMessageInfo>}
     * @memberof GetSystemMessageList
     */
    'items'?: Array<GetSystemMessageInfo>;
}
/**
 * 
 * @export
 * @interface InsuranceItem
 */
export interface InsuranceItem {
    /**
     * 主キー
     * @type {string}
     * @memberof InsuranceItem
     */
    'id'?: string;
    /**
     * 組織ID
     * @type {string}
     * @memberof InsuranceItem
     */
    'organizationId'?: string;
    /**
     * 行番号
     * @type {number}
     * @memberof InsuranceItem
     */
    'rowNumber'?: number;
    /**
     * 提出先情報ID
     * @type {string}
     * @memberof InsuranceItem
     */
    'destinationId'?: string;
    /**
     * 提出先名
     * @type {string}
     * @memberof InsuranceItem
     */
    'destinationName'?: string;
    /**
     * 拡張コード１
     * @type {string}
     * @memberof InsuranceItem
     */
    'destinationExtra1'?: string;
    /**
     * 事業所整理記号都道府県コード
     * @type {string}
     * @memberof InsuranceItem
     */
    'jigyousyoSeirikigouPref'?: string;
    /**
     * 事業所整理記号郡市区記号
     * @type {string}
     * @memberof InsuranceItem
     */
    'jigyousyoSeirikigouGunshiku'?: string;
    /**
     * 事業所整理記号事業所記号
     * @type {string}
     * @memberof InsuranceItem
     */
    'jigyousyoSeirikigouKigou'?: string;
    /**
     * 事業所整理記号事業所番号
     * @type {string}
     * @memberof InsuranceItem
     */
    'jigyousyoSeirikigouBangou'?: string;
}
/**
 * 
 * @export
 * @interface PostAccessKey
 */
export interface PostAccessKey {
    /**
     * アクセスキー
     * @type {string}
     * @memberof PostAccessKey
     */
    'accesskey'?: string;
}
/**
 * 
 * @export
 * @interface PostAccountInfo
 */
export interface PostAccountInfo {
    /**
     * id
     * @type {string}
     * @memberof PostAccountInfo
     */
    'orgnizationId'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossMailAddress'?: string;
    /**
     * gBizのキー
     * @type {string}
     * @memberof PostAccountInfo
     */
    'token'?: string;
    /**
     * 利用者レベル0=未認証, 1=gBizプライム,2=gBinメンバー
     * @type {number}
     * @memberof PostAccountInfo
     */
    'levelKind'?: number;
    /**
     * 所属等(表示用名称)
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossDispName'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossName'?: string;
    /**
     * 提出代行証明書
     * @type {string}
     * @memberof PostAccountInfo
     */
    'attachFile'?: string;
    /**
     * OSS申請者住所
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossAddress'?: string;
    /**
     * OSS申請者情報 性別(1：男、2：女、3：不明)
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossSex'?: string;
    /**
     * OSS申請者情報 生年月日(YYYYMMDD)
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossBirthday'?: string;
    /**
     * OSS申請者情報 電話番号市外局番
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossTelNo1'?: string;
    /**
     * OSS申請者情報 電話番号市内局番
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossTelNo2'?: string;
    /**
     * OSS申請者情報 電話番号加入者番号
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossTelNo3'?: string;
    /**
     * OSS申請者商号（法人名）
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossCorpNameKanji'?: string;
    /**
     * OSS申請者商号（法人名）ふりがな
     * @type {string}
     * @memberof PostAccountInfo
     */
    'ossCorpNameKana'?: string;
    /**
     * 提出代行証明書の実ファイル名
     * @type {string}
     * @memberof PostAccountInfo
     */
    'attachFileName'?: string;
}
/**
 * 
 * @export
 * @interface PostAdminAccountInfo
 */
export interface PostAdminAccountInfo {
    /**
     * アカウントID
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'id'?: string;
    /**
     * 組織ID
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'organizationId'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossMailAddress'?: string;
    /**
     * gBizのキー
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'token'?: string;
    /**
     * 利用者レベル0=未認証, 1=gBizプライム,2=gBinメンバー
     * @type {number}
     * @memberof PostAdminAccountInfo
     */
    'levelKind'?: number;
    /**
     * 所属等(表示用名称)
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossDispName'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossName'?: string;
    /**
     * 提出代行証明書
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'attachFile'?: string;
    /**
     * OSS申請者住所
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossAddress'?: string;
    /**
     * OSS申請者情報 性別(1：男、2：女、3：不明)
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossSex'?: string;
    /**
     * OSS申請者情報 生年月日(YYYYMMDD)
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossBirthday'?: string;
    /**
     * OSS申請者情報 電話番号市外局番
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossTelNo1'?: string;
    /**
     * OSS申請者情報 電話番号市内局番
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossTelNo2'?: string;
    /**
     * OSS申請者情報 電話番号加入者番号
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossTelNo3'?: string;
    /**
     * OSS申請者商号（法人名）
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossCorpNameKanji'?: string;
    /**
     * OSS申請者商号（法人名）ふりがな
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'ossCorpNameKana'?: string;
    /**
     * 提出代行証明書の実ファイル名
     * @type {string}
     * @memberof PostAdminAccountInfo
     */
    'attachFileName'?: string;
}
/**
 * 
 * @export
 * @interface PostAdminOrganizationInfo
 */
export interface PostAdminOrganizationInfo {
    /**
     * id
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'id'?: string;
    /**
     * 法人番号
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpNo'?: string;
    /**
     * 商号（法人名）
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpNameKanji'?: string;
    /**
     * 商号（法人名）ふりがな
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpNameKana'?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpKind'?: string;
    /**
     * 法人格がその他の場合の入力欄
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpKindEtc'?: string;
    /**
     * 法人格前後区分
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpKindBA'?: string;
    /**
     * 1=AAD、2=統合認証
     * @type {number}
     * @memberof PostAdminOrganizationInfo
     */
    'provider'?: number;
    /**
     * 統合認証用URL
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'signInUrl'?: string;
    /**
     * クライアントID
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'clientId'?: string;
    /**
     * 
     * @type {Array<InsuranceItem>}
     * @memberof PostAdminOrganizationInfo
     */
    'insuranceList'?: Array<InsuranceItem>;
    /**
     * 本店又は主たる事務所の所在地 郵便番号
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpYubin'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 都道府県
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpPref'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 市区町村
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpShikuchouson'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 番地以下
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpBanchi'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 それ以降の住所
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpIkou'?: string;
    /**
     * 代表者氏
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeFamilyName'?: string;
    /**
     * 代表者名
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeFirstName'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市外局番
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpTelNo1'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市内局番
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpTelNo2'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 加入者番号
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'corpTelNo3'?: string;
    /**
     * 代表者氏（カナ）
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeFamilyNameKana'?: string;
    /**
     * 代表者名（カナ）
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeFirstNameKana'?: string;
    /**
     * 代表者郵便番号
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeYubin'?: string;
    /**
     * 代表者都道府県
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativePref'?: string;
    /**
     * 代表者市区町村
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeShikuchouson'?: string;
    /**
     * 代表者番地以下
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeBanchi'?: string;
    /**
     * 代表者それ以降の住所
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeIkou'?: string;
    /**
     * 代表者市外局番
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeTelNo1'?: string;
    /**
     * 代表者市内局番
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeTelNo2'?: string;
    /**
     * 代表者加入者番号
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeTelNo3'?: string;
    /**
     * 代表者メールアドレス
     * @type {string}
     * @memberof PostAdminOrganizationInfo
     */
    'representativeMailAddress'?: string;
}
/**
 * 
 * @export
 * @interface PostGuid
 */
export interface PostGuid {
    /**
     * id
     * @type {string}
     * @memberof PostGuid
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface PostInsuranceMaster
 */
export interface PostInsuranceMaster {
    /**
     * 
     * @type {string}
     * @memberof PostInsuranceMaster
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface PostOrganizationInfo
 */
export interface PostOrganizationInfo {
    /**
     * id
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'id'?: string;
    /**
     * 法人番号
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpNo'?: string;
    /**
     * 商号（法人名）
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpNameKanji'?: string;
    /**
     * 商号（法人名）ふりがな
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpNameKana'?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpKind'?: string;
    /**
     * 法人格がその他の場合の入力欄
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpKindEtc'?: string;
    /**
     * 法人格前後区分
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpKindBA'?: string;
    /**
     * 
     * @type {Array<InsuranceItem>}
     * @memberof PostOrganizationInfo
     */
    'insuranceList'?: Array<InsuranceItem>;
    /**
     * 本店又は主たる事務所の所在地 郵便番号
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpYubin'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 都道府県
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpPref'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 市区町村
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpShikuchouson'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 番地以下
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpBanchi'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 それ以降の住所
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpIkou'?: string;
    /**
     * 代表者氏
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeFamilyName'?: string;
    /**
     * 代表者名
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeFirstName'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市外局番
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpTelNo1'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市内局番
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpTelNo2'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 加入者番号
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'corpTelNo3'?: string;
    /**
     * 代表者氏（カナ）
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeFamilyNameKana'?: string;
    /**
     * 代表者名（カナ）
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeFirstNameKana'?: string;
    /**
     * 代表者郵便番号
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeYubin'?: string;
    /**
     * 代表者都道府県
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativePref'?: string;
    /**
     * 代表者市区町村
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeShikuchouson'?: string;
    /**
     * 代表者番地以下
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeBanchi'?: string;
    /**
     * 代表者それ以降の住所
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeIkou'?: string;
    /**
     * 代表者市外局番
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeTelNo1'?: string;
    /**
     * 代表者市内局番
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeTelNo2'?: string;
    /**
     * 代表者加入者番号
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeTelNo3'?: string;
    /**
     * 代表者メールアドレス
     * @type {string}
     * @memberof PostOrganizationInfo
     */
    'representativeMailAddress'?: string;
}
/**
 * 
 * @export
 * @interface PutAccountInfo
 */
export interface PutAccountInfo {
    /**
     * id
     * @type {string}
     * @memberof PutAccountInfo
     */
    'orgnizationId'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossMailAddress'?: string;
    /**
     * 利用者レベル0=未認証, 1=gBizプライム,2=gBinメンバー
     * @type {number}
     * @memberof PutAccountInfo
     */
    'levelKind'?: number;
    /**
     * 所属等(表示用名称)
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossDispName'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossName'?: string;
    /**
     * 提出代行証明書
     * @type {string}
     * @memberof PutAccountInfo
     */
    'attachFile'?: string;
    /**
     * OSS申請者住所
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossAddress'?: string;
    /**
     * OSS申請者情報 性別(1：男、2：女、3：不明)
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossSex'?: string;
    /**
     * OSS申請者情報 生年月日(YYYYMMDD)
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossBirthday'?: string;
    /**
     * OSS申請者情報 電話番号市外局番
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossTelNo1'?: string;
    /**
     * OSS申請者情報 電話番号市内局番
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossTelNo2'?: string;
    /**
     * OSS申請者情報 電話番号加入者番号
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossTelNo3'?: string;
    /**
     * OSS申請者商号（法人名）
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossCorpNameKanji'?: string;
    /**
     * OSS申請者商号（法人名）ふりがな
     * @type {string}
     * @memberof PutAccountInfo
     */
    'ossCorpNameKana'?: string;
    /**
     * 提出代行証明書の実ファイル名
     * @type {string}
     * @memberof PutAccountInfo
     */
    'attachFileName'?: string;
}
/**
 * 
 * @export
 * @interface PutAdminAccountInfo
 */
export interface PutAdminAccountInfo {
    /**
     * メールアドレス
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossMailAddress'?: string;
    /**
     * gBizのキー
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'token'?: string;
    /**
     * 利用者レベル0=未認証, 1=gBizプライム,2=gBinメンバー
     * @type {number}
     * @memberof PutAdminAccountInfo
     */
    'levelKind'?: number;
    /**
     * 所属等(表示用名称)
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossDispName'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossName'?: string;
    /**
     * 提出代行証明書
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'attachFile'?: string;
    /**
     * OSS申請者住所
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossAddress'?: string;
    /**
     * OSS申請者情報 性別(1：男、2：女、3：不明)
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossSex'?: string;
    /**
     * OSS申請者情報 生年月日(YYYYMMDD)
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossBirthday'?: string;
    /**
     * OSS申請者情報 電話番号市外局番
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossTelNo1'?: string;
    /**
     * OSS申請者情報 電話番号市内局番
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossTelNo2'?: string;
    /**
     * OSS申請者情報 電話番号加入者番号
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossTelNo3'?: string;
    /**
     * OSS申請者商号（法人名）
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossCorpNameKanji'?: string;
    /**
     * OSS申請者商号（法人名）ふりがな
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'ossCorpNameKana'?: string;
    /**
     * 提出代行証明書の実ファイル名
     * @type {string}
     * @memberof PutAdminAccountInfo
     */
    'attachFileName'?: string;
}
/**
 * 
 * @export
 * @interface PutAdminOrganizationInfo
 */
export interface PutAdminOrganizationInfo {
    /**
     * id
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'id': string;
    /**
     * 法人番号
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpNo'?: string;
    /**
     * 商号（法人名）
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpNameKanji'?: string;
    /**
     * 商号（法人名）ふりがな
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpNameKana'?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpKind'?: string;
    /**
     * 法人格がその他の場合の入力欄
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpKindEtc'?: string;
    /**
     * 法人格前後区分
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpKindBA'?: string;
    /**
     * 1=AAD、2=統合認証
     * @type {number}
     * @memberof PutAdminOrganizationInfo
     */
    'provider'?: number;
    /**
     * 統合認証用URL
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'signInUrl'?: string;
    /**
     * クライアントID
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'clientId'?: string;
    /**
     * 
     * @type {Array<InsuranceItem>}
     * @memberof PutAdminOrganizationInfo
     */
    'insuranceList'?: Array<InsuranceItem>;
    /**
     * 本店又は主たる事務所の所在地 郵便番号
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpYubin'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 都道府県
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpPref'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 市区町村
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpShikuchouson'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 番地以下
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpBanchi'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 それ以降の住所
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpIkou'?: string;
    /**
     * 代表者氏
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeFamilyName'?: string;
    /**
     * 代表者名
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeFirstName'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市外局番
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpTelNo1'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市内局番
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpTelNo2'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 加入者番号
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'corpTelNo3'?: string;
    /**
     * 代表者氏（カナ）
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeFamilyNameKana'?: string;
    /**
     * 代表者名（カナ）
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeFirstNameKana'?: string;
    /**
     * 代表者郵便番号
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeYubin'?: string;
    /**
     * 代表者都道府県
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativePref'?: string;
    /**
     * 代表者市区町村
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeShikuchouson'?: string;
    /**
     * 代表者番地以下
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeBanchi'?: string;
    /**
     * 代表者それ以降の住所
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeIkou'?: string;
    /**
     * 代表者市外局番
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeTelNo1'?: string;
    /**
     * 代表者市内局番
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeTelNo2'?: string;
    /**
     * 代表者加入者番号
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeTelNo3'?: string;
    /**
     * 代表者メールアドレス
     * @type {string}
     * @memberof PutAdminOrganizationInfo
     */
    'representativeMailAddress'?: string;
}
/**
 * 
 * @export
 * @interface PutGuid
 */
export interface PutGuid {
    /**
     * id
     * @type {string}
     * @memberof PutGuid
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface PutGuidList
 */
export interface PutGuidList {
    /**
     * 
     * @type {Array<PutGuid>}
     * @memberof PutGuidList
     */
    'items'?: Array<PutGuid>;
}
/**
 * 
 * @export
 * @interface PutOrganizationInfo
 */
export interface PutOrganizationInfo {
    /**
     * id
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'id'?: string;
    /**
     * 法人番号
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpNo'?: string;
    /**
     * 商号（法人名）
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpNameKanji'?: string;
    /**
     * 商号（法人名）ふりがな
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpNameKana'?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpKind'?: string;
    /**
     * 法人格がその他の場合の入力欄
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpKindEtc'?: string;
    /**
     * 法人格前後区分
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpKindBA'?: string;
    /**
     * 
     * @type {Array<InsuranceItem>}
     * @memberof PutOrganizationInfo
     */
    'insuranceList'?: Array<InsuranceItem>;
    /**
     * 本店又は主たる事務所の所在地 郵便番号
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpYubin'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 都道府県
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpPref'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 市区町村
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpShikuchouson'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 番地以下
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpBanchi'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 それ以降の住所
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpIkou'?: string;
    /**
     * 代表者氏
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeFamilyName'?: string;
    /**
     * 代表者名
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeFirstName'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市外局番
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpTelNo1'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市内局番
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpTelNo2'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 加入者番号
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'corpTelNo3'?: string;
    /**
     * 代表者氏（カナ）
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeFamilyNameKana'?: string;
    /**
     * 代表者名（カナ）
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeFirstNameKana'?: string;
    /**
     * 代表者郵便番号
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeYubin'?: string;
    /**
     * 代表者都道府県
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativePref'?: string;
    /**
     * 代表者市区町村
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeShikuchouson'?: string;
    /**
     * 代表者番地以下
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeBanchi'?: string;
    /**
     * 代表者それ以降の住所
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeIkou'?: string;
    /**
     * 代表者市外局番
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeTelNo1'?: string;
    /**
     * 代表者市内局番
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeTelNo2'?: string;
    /**
     * 代表者加入者番号
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeTelNo3'?: string;
    /**
     * 代表者メールアドレス
     * @type {string}
     * @memberof PutOrganizationInfo
     */
    'representativeMailAddress'?: string;
}
/**
 * 
 * @export
 * @interface RefundReason
 */
export interface RefundReason {
    /**
     * 申請取下げ理由
     * @type {string}
     * @memberof RefundReason
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface UpdateApplicationInfo
 */
export interface UpdateApplicationInfo {
    /**
     * ID
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'id'?: string;
    /**
     * 申請日
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'applicationDate'?: string;
    /**
     * 申請種類
     * @type {number}
     * @memberof UpdateApplicationInfo
     */
    'applicationKind'?: number;
    /**
     * ステータス
     * @type {number}
     * @memberof UpdateApplicationInfo
     */
    'statusKind'?: number;
    /**
     * 申請タイトル
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'searchTitle'?: string;
    /**
     * 申請備考
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'searchBikou'?: string;
    /**
     * 申請データ行数
     * @type {number}
     * @memberof UpdateApplicationInfo
     */
    'rowCount'?: number;
    /**
     * 仮申請番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'tempApplicationNo'?: string;
    /**
     * 申請番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'applicationNo'?: string;
    /**
     * 提出元ID
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'teishutumotoId'?: string;
    /**
     * 通番
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'tsuban'?: string;
    /**
     * 識別情報年
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'sikibetuJouhouY'?: string;
    /**
     * 識別情報月
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'sikibetuJouhouM'?: string;
    /**
     * 識別情報日
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'sikibetuJouhouD'?: string;
    /**
     * 事業所整理記号都道府県コード
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyousyoSeirikigouPref'?: string;
    /**
     * 事業所整理記号郡市区記号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyousyoSeirikigouGunshiku'?: string;
    /**
     * 事業所整理記号事業所記号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyousyoSeirikigouKigou'?: string;
    /**
     * 事業所整理記号事業所番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyousyoSeirikigouBangou'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 資格取得届/70歳以上被用者該当届（件）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'kenpoSikakuShutoku'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 被扶養者異動届/国民年金第3号被保険者関係届（件）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'kenpoHihuyousha'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 資格喪失届/70歳以上被用者不該当届（件）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'kenpoSikakuSoushitsu'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 月額変更届/70歳以上被用者月額変更届（件）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'kenpoGetsugakuHenkou'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数 算定基礎届/70歳以上被用者算定基礎届（件）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'kenpoSanteiKiso'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数賞与支払届/70歳以上被用者賞与支払届（件）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'kenpoShouyoShiharai'?: string;
    /**
     * 健康保険・厚生年金保険　届書総件数届書合計（件）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'kenpoGoukei'?: string;
    /**
     * 国民年金　届書総件数 国民年金第3号被保険者関係届（件）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'kokuminNenkin3gou'?: string;
    /**
     * 国民年金　届書総件数 届書合計（件）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'kokuminNenkinGoukei'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'bikou'?: string;
    /**
     * 事業所所在地郵便番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyoushoYubin'?: string;
    /**
     * 事業所所在地都道府県
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyoushoPref'?: string;
    /**
     * 事業所所在地市区町村
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyoushoShikuchouson'?: string;
    /**
     * 事業所所在地番地以下
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyoushoBanchi'?: string;
    /**
     * 事業所所在地それ以降の住所
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyoushoIkou'?: string;
    /**
     * 事業所名称
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyoushoName'?: string;
    /**
     * 事業主氏
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyounushiFamilyName'?: string;
    /**
     * 事業主名
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'jigyounushiFirstName'?: string;
    /**
     * 電話番号市外局番
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'telNo1'?: string;
    /**
     * 電話番号市内局番
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'telNo2'?: string;
    /**
     * 電話番号加入者番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'telNo3'?: string;
    /**
     * 提出年
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'applicantsY'?: string;
    /**
     * 提出月
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'applicantsM'?: string;
    /**
     * 提出日
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'applicantsD'?: string;
    /**
     * 社会保険労務士の提出代行者名氏
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'sharoushiFamilyName'?: string;
    /**
     * 社会保険労務士の提出代行者名名
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'sharoushiFirstName'?: string;
    /**
     * 通知書希望形式
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'tsuuchisyoKibou'?: string;
    /**
     * 提出元事業所法人番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpName'?: string;
    /**
     * 提出先健康保険組合保険者番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'destHokenKumiaiNo'?: string;
    /**
     * 提出先健康保険組合名称
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'destHokenKumiaiName'?: string;
    /**
     * 郵送
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'attachedPost'?: string;
    /**
     * 電子
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'attachedE'?: string;
    /**
     * なし
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'attachedNone'?: string;
    /**
     * 取り下げ可否フラグ
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'cancelAvailability'?: string;
    /**
     * 取り下げ理由設定可否フラグ
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'cancelCauseAvailability'?: string;
    /**
     * 社会保険労務士の提出代行者名氏
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'cancelReason'?: string;

    /**
     * 提出先情報ID
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'destinationId'?: string;

    /**
     * 提出先名
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'destinationName'?: string;
    /**
     * 拡張コード１
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'destinationExtra1'?: string;
    /**
     * 法人番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpNo'?: string;
    /**
     * 商号（法人名）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpNameKanji'?: string;
    /**
     * 商号（法人名）ふりがな
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpNameKana'?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpKind'?: string;
    /**
     * 法人格がその他の場合の入力欄
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpKindEtc'?: string;
    /**
     * 法人格前後区分
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpKindBA'?: string;
    /**
     * 本店又は主たる事務所の所在地 郵便番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpYubin'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 都道府県
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpPref'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 市区町村
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpShikuchouson'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 番地以下
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpBanchi'?: string;
    /**
     * 本店又は主たる事務所の所在地住所 それ以降の住所
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpIkou'?: string;
    /**
     * 代表者氏
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeFamilyName'?: string;
    /**
     * 代表者名
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeFirstName'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市外局番
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpTelNo1'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 市内局番
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpTelNo2'?: string;
    /**
     * 本店又は主たる事務所の所在地電話番号 加入者番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'corpTelNo3'?: string;
    /**
     * 代表者氏（カナ）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeFamilyNameKana'?: string;
    /**
     * 代表者名（カナ）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeFirstNameKana'?: string;
    /**
     * 代表者郵便番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeYubin'?: string;
    /**
     * 代表者都道府県
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativePref'?: string;
    /**
     * 代表者市区町村
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeShikuchouson'?: string;
    /**
     * 代表者番地以下
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeBanchi'?: string;
    /**
     * 代表者それ以降の住所
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeIkou'?: string;
    /**
     * 代表者市外局番
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeTelNo1'?: string;
    /**
     * 代表者市内局番
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeTelNo2'?: string;
    /**
     * 代表者加入者番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeTelNo3'?: string;
    /**
     * 代表者メールアドレス
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'representativeMailAddress'?: string;
    /**
     * 名前
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossName'?: string;
    /**
     * OSS申請者住所
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossAddress'?: string;
    /**
     * OSS申請者情報 性別(1：男、2：女、3：不明)
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossSex'?: string;
    /**
     * OSS申請者情報 生年月日(YYYYMMDD)
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossBirthday'?: string;
    /**
     * OSS申請者情報 メールアドレス
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossMailAddress'?: string;
    /**
     * OSS申請者情報 電話番号市外局番
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossTelNo1'?: string;
    /**
     * OSS申請者情報 電話番号市内局番
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossTelNo2'?: string;
    /**
     * OSS申請者情報 電話番号加入者番号
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossTelNo3'?: string;
    /**
     * OSS申請者商号（法人名）
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossCorpNameKanji'?: string;
    /**
     * OSS申請者商号（法人名）ふりがな
     * @type {string}
     * @memberof UpdateApplicationInfo
     */
    'ossCorpNameKana'?: string;
    /**
     * 提出代行証明書の送信フラグ 0=送信しない 1=送信する
     * @type {number}
     * @memberof UpdateApplicationInfo
     */
    'sendAttachFile'?: number;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 顧客管理者アカウントサインイン情報取得
         * @summary 顧客管理者アカウントサインイン情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 組織情報一覧の取得
         * @summary 組織情報一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 組織情報の削除
         * @summary 組織情報の削除
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAdminOrganizationIdDelete', 'id', id)
            const localVarPath = `/api/admin/organization/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 組織1件の情報を取得
         * @summary 組織1件の情報を取得
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAdminOrganizationIdGet', 'id', id)
            const localVarPath = `/api/admin/organization/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 組織情報の更新
         * @summary 組織情報の更新
         * @param {string} id Guid
         * @param {PutAdminOrganizationInfo} [body] Organization &amp; insurance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationIdPut: async (id: string, body?: PutAdminOrganizationInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAdminOrganizationIdPut', 'id', id)
            const localVarPath = `/api/admin/organization/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定の組織ID配下のアカウント一覧を取得する
         * @summary アカウント一覧取得
         * @param {string} oid 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountGet: async (oid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oid' is not null or undefined
            assertParamExists('apiAdminOrganizationOidAccountGet', 'oid', oid)
            const localVarPath = `/api/admin/organization/{oid}/account/`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウント情報削除
         * @summary アカウント情報削除
         * @param {string} oid 組織ID
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountIdDelete: async (oid: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oid' is not null or undefined
            assertParamExists('apiAdminOrganizationOidAccountIdDelete', 'oid', oid)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAdminOrganizationOidAccountIdDelete', 'id', id)
            const localVarPath = `/api/admin/organization/{oid}/account/{id}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントの1件情報取得
         * @summary アカウントの1件情報取得
         * @param {string} oid 組織ID
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountIdGet: async (oid: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oid' is not null or undefined
            assertParamExists('apiAdminOrganizationOidAccountIdGet', 'oid', oid)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAdminOrganizationOidAccountIdGet', 'id', id)
            const localVarPath = `/api/admin/organization/{oid}/account/{id}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウント情報更新
         * @summary アカウント情報更新
         * @param {string} oid 組織ID
         * @param {string} id Guid
         * @param {PutAdminAccountInfo} [body] account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountIdPut: async (oid: string, id: string, body?: PutAdminAccountInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oid' is not null or undefined
            assertParamExists('apiAdminOrganizationOidAccountIdPut', 'oid', oid)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAdminOrganizationOidAccountIdPut', 'id', id)
            const localVarPath = `/api/admin/organization/{oid}/account/{id}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定の組織ID配下にアカウントを追加する
         * @summary アカウント新規追加
         * @param {string} oid 組織ID
         * @param {PostAdminAccountInfo} [body] account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountPost: async (oid: string, body?: PostAdminAccountInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oid' is not null or undefined
            assertParamExists('apiAdminOrganizationOidAccountPost', 'oid', oid)
            const localVarPath = `/api/admin/organization/{oid}/account/`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 組織情報の新規作成
         * @summary 組織情報の新規作成
         * @param {PostAdminOrganizationInfo} [body] Organization &amp; insurance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationPost: async (body?: PostAdminOrganizationInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 顧客管理者アカウントサインイン情報取得
         * @summary 顧客管理者アカウントサインイン情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 組織情報一覧の取得
         * @summary 組織情報一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminOrganizationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 組織情報の削除
         * @summary 組織情報の削除
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 組織1件の情報を取得
         * @summary 組織1件の情報を取得
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminOrganizationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 組織情報の更新
         * @summary 組織情報の更新
         * @param {string} id Guid
         * @param {PutAdminOrganizationInfo} [body] Organization &amp; insurance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationIdPut(id: string, body?: PutAdminOrganizationInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutAdminOrganizationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationIdPut(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定の組織ID配下のアカウント一覧を取得する
         * @summary アカウント一覧取得
         * @param {string} oid 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationOidAccountGet(oid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAdminAccountInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationOidAccountGet(oid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウント情報削除
         * @summary アカウント情報削除
         * @param {string} oid 組織ID
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationOidAccountIdDelete(oid: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationOidAccountIdDelete(oid, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントの1件情報取得
         * @summary アカウントの1件情報取得
         * @param {string} oid 組織ID
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationOidAccountIdGet(oid: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationOidAccountIdGet(oid, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウント情報更新
         * @summary アカウント情報更新
         * @param {string} oid 組織ID
         * @param {string} id Guid
         * @param {PutAdminAccountInfo} [body] account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationOidAccountIdPut(oid: string, id: string, body?: PutAdminAccountInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutAdminAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationOidAccountIdPut(oid, id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定の組織ID配下にアカウントを追加する
         * @summary アカウント新規追加
         * @param {string} oid 組織ID
         * @param {PostAdminAccountInfo} [body] account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationOidAccountPost(oid: string, body?: PostAdminAccountInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAdminAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationOidAccountPost(oid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 組織情報の新規作成
         * @summary 組織情報の新規作成
         * @param {PostAdminOrganizationInfo} [body] Organization &amp; insurance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminOrganizationPost(body?: PostAdminOrganizationInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAdminOrganizationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminOrganizationPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 顧客管理者アカウントサインイン情報取得
         * @summary 顧客管理者アカウントサインイン情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminMeGet(options?: any): AxiosPromise<AdminAccountInfo> {
            return localVarFp.apiAdminMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 組織情報一覧の取得
         * @summary 組織情報一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationGet(options?: any): AxiosPromise<GetAdminOrganizationList> {
            return localVarFp.apiAdminOrganizationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 組織情報の削除
         * @summary 組織情報の削除
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAdminOrganizationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 組織1件の情報を取得
         * @summary 組織1件の情報を取得
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationIdGet(id: string, options?: any): AxiosPromise<GetAdminOrganizationInfo> {
            return localVarFp.apiAdminOrganizationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 組織情報の更新
         * @summary 組織情報の更新
         * @param {string} id Guid
         * @param {PutAdminOrganizationInfo} [body] Organization &amp; insurance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationIdPut(id: string, body?: PutAdminOrganizationInfo, options?: any): AxiosPromise<PutAdminOrganizationInfo> {
            return localVarFp.apiAdminOrganizationIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定の組織ID配下のアカウント一覧を取得する
         * @summary アカウント一覧取得
         * @param {string} oid 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountGet(oid: string, options?: any): AxiosPromise<Array<GetAdminAccountInfo>> {
            return localVarFp.apiAdminOrganizationOidAccountGet(oid, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウント情報削除
         * @summary アカウント情報削除
         * @param {string} oid 組織ID
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountIdDelete(oid: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAdminOrganizationOidAccountIdDelete(oid, id, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントの1件情報取得
         * @summary アカウントの1件情報取得
         * @param {string} oid 組織ID
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountIdGet(oid: string, id: string, options?: any): AxiosPromise<GetAdminAccountInfo> {
            return localVarFp.apiAdminOrganizationOidAccountIdGet(oid, id, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウント情報更新
         * @summary アカウント情報更新
         * @param {string} oid 組織ID
         * @param {string} id Guid
         * @param {PutAdminAccountInfo} [body] account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountIdPut(oid: string, id: string, body?: PutAdminAccountInfo, options?: any): AxiosPromise<PutAdminAccountInfo> {
            return localVarFp.apiAdminOrganizationOidAccountIdPut(oid, id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定の組織ID配下にアカウントを追加する
         * @summary アカウント新規追加
         * @param {string} oid 組織ID
         * @param {PostAdminAccountInfo} [body] account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationOidAccountPost(oid: string, body?: PostAdminAccountInfo, options?: any): AxiosPromise<PostAdminAccountInfo> {
            return localVarFp.apiAdminOrganizationOidAccountPost(oid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 組織情報の新規作成
         * @summary 組織情報の新規作成
         * @param {PostAdminOrganizationInfo} [body] Organization &amp; insurance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminOrganizationPost(body?: PostAdminOrganizationInfo, options?: any): AxiosPromise<PostAdminOrganizationInfo> {
            return localVarFp.apiAdminOrganizationPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 顧客管理者アカウントサインイン情報取得
     * @summary 顧客管理者アカウントサインイン情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminMeGet(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 組織情報一覧の取得
     * @summary 組織情報一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationGet(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 組織情報の削除
     * @summary 組織情報の削除
     * @param {string} id Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationIdDelete(id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 組織1件の情報を取得
     * @summary 組織1件の情報を取得
     * @param {string} id Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationIdGet(id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 組織情報の更新
     * @summary 組織情報の更新
     * @param {string} id Guid
     * @param {PutAdminOrganizationInfo} [body] Organization &amp; insurance data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationIdPut(id: string, body?: PutAdminOrganizationInfo, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定の組織ID配下のアカウント一覧を取得する
     * @summary アカウント一覧取得
     * @param {string} oid 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationOidAccountGet(oid: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationOidAccountGet(oid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウント情報削除
     * @summary アカウント情報削除
     * @param {string} oid 組織ID
     * @param {string} id Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationOidAccountIdDelete(oid: string, id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationOidAccountIdDelete(oid, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントの1件情報取得
     * @summary アカウントの1件情報取得
     * @param {string} oid 組織ID
     * @param {string} id Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationOidAccountIdGet(oid: string, id: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationOidAccountIdGet(oid, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウント情報更新
     * @summary アカウント情報更新
     * @param {string} oid 組織ID
     * @param {string} id Guid
     * @param {PutAdminAccountInfo} [body] account data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationOidAccountIdPut(oid: string, id: string, body?: PutAdminAccountInfo, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationOidAccountIdPut(oid, id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定の組織ID配下にアカウントを追加する
     * @summary アカウント新規追加
     * @param {string} oid 組織ID
     * @param {PostAdminAccountInfo} [body] account data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationOidAccountPost(oid: string, body?: PostAdminAccountInfo, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationOidAccountPost(oid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 組織情報の新規作成
     * @summary 組織情報の新規作成
     * @param {PostAdminOrganizationInfo} [body] Organization &amp; insurance data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminOrganizationPost(body?: PostAdminOrganizationInfo, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminOrganizationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Application申請を取得する。ページングする
         * @summary 申請一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 申請を終了にする 
         * @summary 申請の終了
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdCompletedPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationIdCompletedPut', 'id', id)
            const localVarPath = `/api/application/{id}/completed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 申請を1件取得する
         * @summary 申請一件取得
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationIdGet', 'id', id)
            const localVarPath = `/api/application/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 公文書を取得する
         * @summary 公文書を取得する
         * @param {string} id Guid
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdGetDocumentDocIdGet: async (id: string, docId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationIdGetDocumentDocIdGet', 'id', id)
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('apiApplicationIdGetDocumentDocIdGet', 'docId', docId)
            const localVarPath = `/api/application/{id}/GetDocument/{docId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 更新する。 IDがある場合には更新、IDが存在しない場合には404 not foundを返す 
         * @summary 申請の更新
         * @param {string} id Guid
         * @param {UpdateApplicationInfo} body 申請情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdPut: async (id: string, body: UpdateApplicationInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationIdPut', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiApplicationIdPut', 'body', body)
            const localVarPath = `/api/application/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         * 更新する。 IDがある場合には更新、IDが存在しない場合には404 not foundを返す 
         * @summary 申請の更新
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdDelete: async (id: string,
            //body: UpdateApplicationInfo,
            options: AxiosRequestConfig = {}): Promise<RequestArgs> =>
        {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationIdDelete', 'id', id)
            // verify required parameter 'body' is not null or undefined

            const localVarPath = `/api/application/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration)
            {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            //localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         * 申請を取り下げる 申請ロジックは後で結合する 
         * @summary 申請の取り下げ
         * @param {string} id id
         * @param {RefundReason} [reason] 申請理由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdRefundPut: async (id: string, reason?: RefundReason, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationIdRefundPut', 'id', id)
            const localVarPath = `/api/application/{id}/refund`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 申請する
         * @summary 申請の送信
         * @param {string} id Guid
         * @param {Array<ApplicationRowItem>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdSendPost: async (id: string, body: Array<ApplicationRowItem>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationIdSendPost', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiApplicationIdSendPost', 'body', body)
            const localVarPath = `/api/application/{id}/send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 申請の公文書を1件取得する
         * @summary 申請公文書取得
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLogIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationLogIdGet', 'id', id)
            const localVarPath = `/api/applicationLog/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新規作成する。新規作成時にはIDが存在しないため、bodyで渡された内容を元に申請情報を作成し、IDを返す 
         * @summary 新規に申請を作成する
         * @param {UpdateApplicationInfo} body 新規申請情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationPost: async (body: UpdateApplicationInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiApplicationPost', 'body', body)
            const localVarPath = `/api/application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 該当ページのApplicationを更新する。IDのarrayを送る。 更新結果を受け取れば、申請一覧の取得を行い直す 
         * @summary ステータスの更新
         * @param {PutGuidList} id ステータスの更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationStatusupdatePut: async (id: PutGuidList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationStatusupdatePut', 'id', id)
            const localVarPath = `/api/application/statusupdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(id, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アクセスキーを返す
         * @summary アクセスキーを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMnpAuthenticateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mnp/Authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アクセスキーを渡す
         * @summary アクセスキーを渡す
         * @param {PostAccessKey} accesskey アクセスキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMnpPostAuthenticateUserPost: async (accesskey: PostAccessKey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accesskey' is not null or undefined
            assertParamExists('apiMnpPostAuthenticateUserPost', 'accesskey', accesskey)
            const localVarPath = `/api/mnp/PostAuthenticateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accesskey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * Application申請を取得する。ページングする
         * @summary 申請一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 申請を終了にする 
         * @summary 申請の終了
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationIdCompletedPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationIdCompletedPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 申請を1件取得する
         * @summary 申請一件取得
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApplicationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 公文書を取得する
         * @summary 公文書を取得する
         * @param {string} id Guid
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationIdGetDocumentDocIdGet(id: string, docId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationIdGetDocumentDocIdGet(id, docId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 更新する。 IDがある場合には更新、IDが存在しない場合には404 not foundを返す 
         * @summary 申請の更新
         * @param {string} id Guid
         * @param {UpdateApplicationInfo} body 申請情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationIdPut(id: string, body: UpdateApplicationInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationIdPut(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 削除する。 IDがある場合には削除、IDが存在しない場合には404 not foundを返す 
         * @summary 申請の削除
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationIdDelete(id: string,
            // body: UpdateApplicationInfo,
            options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>>
        {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
         * 申請を取り下げる 申請ロジックは後で結合する 
         * @summary 申請の取り下げ
         * @param {string} id id
         * @param {RefundReason} [reason] 申請理由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationIdRefundPut(id: string, reason?: RefundReason, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationIdRefundPut(id, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 申請する
         * @summary 申請の送信
         * @param {string} id Guid
         * @param {Array<ApplicationRowItem>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationIdSendPost(id: string, body: Array<ApplicationRowItem>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationIdSendPost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 申請の公文書を1件取得する
         * @summary 申請公文書取得
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationLogIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApplicationLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationLogIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 新規作成する。新規作成時にはIDが存在しないため、bodyで渡された内容を元に申請情報を作成し、IDを返す 
         * @summary 新規に申請を作成する
         * @param {UpdateApplicationInfo} body 新規申請情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationPost(body: UpdateApplicationInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 該当ページのApplicationを更新する。IDのarrayを送る。 更新結果を受け取れば、申請一覧の取得を行い直す 
         * @summary ステータスの更新
         * @param {PutGuidList} id ステータスの更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationStatusupdatePut(id: PutGuidList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationStatusupdatePut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アクセスキーを返す
         * @summary アクセスキーを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMnpAuthenticateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccessKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMnpAuthenticateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アクセスキーを渡す
         * @summary アクセスキーを渡す
         * @param {PostAccessKey} accesskey アクセスキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMnpPostAuthenticateUserPost(accesskey: PostAccessKey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAccessKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMnpPostAuthenticateUserPost(accesskey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         * Application申請を取得する。ページングする
         * @summary 申請一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationGet(options?: any): AxiosPromise<ApplicationList> {
            return localVarFp.apiApplicationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 申請を終了にする 
         * @summary 申請の終了
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdCompletedPut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationIdCompletedPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 申請を1件取得する
         * @summary 申請一件取得
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdGet(id: string, options?: any): AxiosPromise<GetApplicationInfo> {
            return localVarFp.apiApplicationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 公文書を取得する
         * @summary 公文書を取得する
         * @param {string} id Guid
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdGetDocumentDocIdGet(id: string, docId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationIdGetDocumentDocIdGet(id, docId, options).then((request) => request(axios, basePath));
        },
        /**
         * 更新する。 IDがある場合には更新、IDが存在しない場合には404 not foundを返す 
         * @summary 申請の更新
         * @param {string} id Guid
         * @param {UpdateApplicationInfo} body 申請情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdPut(id: string, body: UpdateApplicationInfo, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 申請を取り下げる 申請ロジックは後で結合する 
         * @summary 申請の取り下げ
         * @param {string} id id
         * @param {RefundReason} [reason] 申請理由
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdRefundPut(id: string, reason?: RefundReason, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationIdRefundPut(id, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 申請する
         * @summary 申請の送信
         * @param {string} id Guid
         * @param {Array<ApplicationRowItem>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationIdSendPost(id: string, body: Array<ApplicationRowItem>, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationIdSendPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 申請の公文書を1件取得する
         * @summary 申請公文書取得
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationLogIdGet(id: string, options?: any): AxiosPromise<GetApplicationLog> {
            return localVarFp.apiApplicationLogIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 新規作成する。新規作成時にはIDが存在しないため、bodyで渡された内容を元に申請情報を作成し、IDを返す 
         * @summary 新規に申請を作成する
         * @param {UpdateApplicationInfo} body 新規申請情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationPost(body: UpdateApplicationInfo, options?: any): AxiosPromise<PostGuid> {
            return localVarFp.apiApplicationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 該当ページのApplicationを更新する。IDのarrayを送る。 更新結果を受け取れば、申請一覧の取得を行い直す 
         * @summary ステータスの更新
         * @param {PutGuidList} id ステータスの更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationStatusupdatePut(id: PutGuidList, options?: any): AxiosPromise<ApplicationList> {
            return localVarFp.apiApplicationStatusupdatePut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * アクセスキーを返す
         * @summary アクセスキーを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMnpAuthenticateGet(options?: any): AxiosPromise<GetAccessKey> {
            return localVarFp.apiMnpAuthenticateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * アクセスキーを渡す
         * @summary アクセスキーを渡す
         * @param {PostAccessKey} accesskey アクセスキー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMnpPostAuthenticateUserPost(accesskey: PostAccessKey, options?: any): AxiosPromise<PostAccessKey> {
            return localVarFp.apiMnpPostAuthenticateUserPost(accesskey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * Application申請を取得する。ページングする
     * @summary 申請一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 申請を終了にする 
     * @summary 申請の終了
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationIdCompletedPut(id: string, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationIdCompletedPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 申請を1件取得する
     * @summary 申請一件取得
     * @param {string} id Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationIdGet(id: string, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 公文書を取得する
     * @summary 公文書を取得する
     * @param {string} id Guid
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationIdGetDocumentDocIdGet(id: string, docId: string, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationIdGetDocumentDocIdGet(id, docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 更新する。 IDがある場合には更新、IDが存在しない場合には404 not foundを返す 
     * @summary 申請の更新
     * @param {string} id Guid
     * @param {UpdateApplicationInfo} body 申請情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationIdPut(id: string, body: UpdateApplicationInfo, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 申請を取り下げる 申請ロジックは後で結合する 
     * @summary 申請の取り下げ
     * @param {string} id id
     * @param {RefundReason} [reason] 申請理由
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationIdRefundPut(id: string, reason?: RefundReason, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationIdRefundPut(id, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 申請する
     * @summary 申請の送信
     * @param {string} id Guid
     * @param {Array<ApplicationRowItem>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationIdSendPost(id: string, body: Array<ApplicationRowItem>, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationIdSendPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 申請の公文書を1件取得する
     * @summary 申請公文書取得
     * @param {string} id Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationLogIdGet(id: string, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationLogIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新規作成する。新規作成時にはIDが存在しないため、bodyで渡された内容を元に申請情報を作成し、IDを返す 
     * @summary 新規に申請を作成する
     * @param {UpdateApplicationInfo} body 新規申請情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationPost(body: UpdateApplicationInfo, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 該当ページのApplicationを更新する。IDのarrayを送る。 更新結果を受け取れば、申請一覧の取得を行い直す 
     * @summary ステータスの更新
     * @param {PutGuidList} id ステータスの更新
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationStatusupdatePut(id: PutGuidList, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationStatusupdatePut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アクセスキーを返す
     * @summary アクセスキーを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiMnpAuthenticateGet(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiMnpAuthenticateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アクセスキーを渡す
     * @summary アクセスキーを渡す
     * @param {PostAccessKey} accesskey アクセスキー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiMnpPostAuthenticateUserPost(accesskey: PostAccessKey, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiMnpPostAuthenticateUserPost(accesskey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InsuranceMasterApi - axios parameter creator
 * @export
 */
export const InsuranceMasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 保険組合の更新ファイルのアップロード
         * @summary 保険組合の更新ファイルのアップロード
         * @param {PostInsuranceMaster} body ステータスの更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminInsuranceMasterPost: async (body: PostInsuranceMaster, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiAdminInsuranceMasterPost', 'body', body)
            const localVarPath = `/api/admin/insuranceMaster`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保険組合の一覧
         * @summary 保険組合の一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceMasterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insuranceMaster`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceMasterApi - functional programming interface
 * @export
 */
export const InsuranceMasterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceMasterApiAxiosParamCreator(configuration)
    return {
        /**
         * 保険組合の更新ファイルのアップロード
         * @summary 保険組合の更新ファイルのアップロード
         * @param {PostInsuranceMaster} body ステータスの更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminInsuranceMasterPost(body: PostInsuranceMaster, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminInsuranceMasterPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保険組合の一覧
         * @summary 保険組合の一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInsuranceMasterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInsuranceMasterList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInsuranceMasterGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsuranceMasterApi - factory interface
 * @export
 */
export const InsuranceMasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceMasterApiFp(configuration)
    return {
        /**
         * 保険組合の更新ファイルのアップロード
         * @summary 保険組合の更新ファイルのアップロード
         * @param {PostInsuranceMaster} body ステータスの更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminInsuranceMasterPost(body: PostInsuranceMaster, options?: any): AxiosPromise<void> {
            return localVarFp.apiAdminInsuranceMasterPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 保険組合の一覧
         * @summary 保険組合の一覧の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceMasterGet(options?: any): AxiosPromise<GetInsuranceMasterList> {
            return localVarFp.apiInsuranceMasterGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsuranceMasterApi - object-oriented interface
 * @export
 * @class InsuranceMasterApi
 * @extends {BaseAPI}
 */
export class InsuranceMasterApi extends BaseAPI {
    /**
     * 保険組合の更新ファイルのアップロード
     * @summary 保険組合の更新ファイルのアップロード
     * @param {PostInsuranceMaster} body ステータスの更新
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceMasterApi
     */
    public apiAdminInsuranceMasterPost(body: PostInsuranceMaster, options?: AxiosRequestConfig) {
        return InsuranceMasterApiFp(this.configuration).apiAdminInsuranceMasterPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保険組合の一覧
     * @summary 保険組合の一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceMasterApi
     */
    public apiInsuranceMasterGet(options?: AxiosRequestConfig) {
        return InsuranceMasterApiFp(this.configuration).apiInsuranceMasterGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Insurance情報のOrganizationIDが＝の該当行を取得する
         * @summary 組織情報の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insuranceの更新された行のみ更新する。 追加レコードはRowNumberを追加する。削除は用意しない（APIは） Insurance情報のOrganizationIDが＝の該当行を更新 
         * @summary 組織情報の更新
         * @param {PutOrganizationInfo} [body] Organization &amp; insurance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationPut: async (body?: PutOrganizationInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Insurance情報のOrganizationIDが＝の該当行を取得する
         * @summary 組織情報の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insuranceの更新された行のみ更新する。 追加レコードはRowNumberを追加する。削除は用意しない（APIは） Insurance情報のOrganizationIDが＝の該当行を更新 
         * @summary 組織情報の更新
         * @param {PutOrganizationInfo} [body] Organization &amp; insurance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationPut(body?: PutOrganizationInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * Insurance情報のOrganizationIDが＝の該当行を取得する
         * @summary 組織情報の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGet(options?: any): AxiosPromise<GetOrganizationInfo> {
            return localVarFp.apiOrganizationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Insuranceの更新された行のみ更新する。 追加レコードはRowNumberを追加する。削除は用意しない（APIは） Insurance情報のOrganizationIDが＝の該当行を更新 
         * @summary 組織情報の更新
         * @param {PutOrganizationInfo} [body] Organization &amp; insurance data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationPut(body?: PutOrganizationInfo, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationPut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * Insurance情報のOrganizationIDが＝の該当行を取得する
     * @summary 組織情報の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGet(options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insuranceの更新された行のみ更新する。 追加レコードはRowNumberを追加する。削除は用意しない（APIは） Insurance情報のOrganizationIDが＝の該当行を更新 
     * @summary 組織情報の更新
     * @param {PutOrganizationInfo} [body] Organization &amp; insurance data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationPut(body?: PutOrganizationInfo, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationPut(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SystemMessageApi - axios parameter creator
 * @export
 */
export const SystemMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * システムメッセージの取得
         * @summary システムメッセージの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/systemMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemMessageApi - functional programming interface
 * @export
 */
export const SystemMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * システムメッセージの取得
         * @summary システムメッセージの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSystemMessageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSystemMessageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSystemMessageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemMessageApi - factory interface
 * @export
 */
export const SystemMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemMessageApiFp(configuration)
    return {
        /**
         * システムメッセージの取得
         * @summary システムメッセージの取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemMessageGet(options?: any): AxiosPromise<GetSystemMessageList> {
            return localVarFp.apiSystemMessageGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemMessageApi - object-oriented interface
 * @export
 * @class SystemMessageApi
 * @extends {BaseAPI}
 */
export class SystemMessageApi extends BaseAPI {
    /**
     * システムメッセージの取得
     * @summary システムメッセージの取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessageApi
     */
    public apiSystemMessageGet(options?: AxiosRequestConfig) {
        return SystemMessageApiFp(this.configuration).apiSystemMessageGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定のAccount情報を削除する
         * @summary アカウント削除
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountIdDelete', 'id', id)
            const localVarPath = `/api/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定のAccount情報を取り出す
         * @summary アカウント読み込み
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountIdGet', 'id', id)
            const localVarPath = `/api/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定のAccount情報を更新する
         * @summary アカウント更新
         * @param {string} id Guid
         * @param {PutAccountInfo} body アカウント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountIdPut: async (id: string, body: PutAccountInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountIdPut', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiAccountIdPut', 'body', body)
            const localVarPath = `/api/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Account情報の新規登録を行う
         * @summary マイアカウント新規登録
         * @param {PostAccountInfo} body アカウント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountPost: async (body: PostAccountInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiAccountPost', 'body', body)
            const localVarPath = `/api/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Account情報の該当行。氏名、携帯番号、アタッチファイルの更新を行う
         * @summary マイアカウント管理
         * @param {PutAccountInfo} body アカウント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountPut: async (body: PutAccountInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('apiAccountPut', 'body', body)
            const localVarPath = `/api/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Account情報の該当行を取得する
         * @summary マイアカウント管理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Account情報の該当行を再度取得する
         * @summary マイアカウント管理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeReloadGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/reload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバー情報の一覧取得を行う
         * @summary メンバー一覧の取得を行う
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMemberGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定のAccount情報を削除する
         * @summary アカウント削除
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定のAccount情報を取り出す
         * @summary アカウント読み込み
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定のAccount情報を更新する
         * @summary アカウント更新
         * @param {string} id Guid
         * @param {PutAccountInfo} body アカウント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountIdPut(id: string, body: PutAccountInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountIdPut(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Account情報の新規登録を行う
         * @summary マイアカウント新規登録
         * @param {PostAccountInfo} body アカウント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountPost(body: PostAccountInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Account情報の該当行。氏名、携帯番号、アタッチファイルの更新を行う
         * @summary マイアカウント管理
         * @param {PutAccountInfo} body アカウント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountPut(body: PutAccountInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Account情報の該当行を取得する
         * @summary マイアカウント管理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Account情報の該当行を再度取得する
         * @summary マイアカウント管理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMeReloadGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMeReloadGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メンバー情報の一覧取得を行う
         * @summary メンバー一覧の取得を行う
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMemberGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAccountInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMemberGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 指定のAccount情報を削除する
         * @summary アカウント削除
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定のAccount情報を取り出す
         * @summary アカウント読み込み
         * @param {string} id Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountIdGet(id: string, options?: any): AxiosPromise<GetAccountInfo> {
            return localVarFp.apiAccountIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定のAccount情報を更新する
         * @summary アカウント更新
         * @param {string} id Guid
         * @param {PutAccountInfo} body アカウント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountIdPut(id: string, body: PutAccountInfo, options?: any): AxiosPromise<GetAccountInfo> {
            return localVarFp.apiAccountIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Account情報の新規登録を行う
         * @summary マイアカウント新規登録
         * @param {PostAccountInfo} body アカウント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountPost(body: PostAccountInfo, options?: any): AxiosPromise<PostAccountInfo> {
            return localVarFp.apiAccountPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Account情報の該当行。氏名、携帯番号、アタッチファイルの更新を行う
         * @summary マイアカウント管理
         * @param {PutAccountInfo} body アカウント情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountPut(body: PutAccountInfo, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Account情報の該当行を取得する
         * @summary マイアカウント管理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeGet(options?: any): AxiosPromise<GetAccountInfo> {
            return localVarFp.apiMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Account情報の該当行を再度取得する
         * @summary マイアカウント管理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMeReloadGet(options?: any): AxiosPromise<GetAccountInfo> {
            return localVarFp.apiMeReloadGet(options).then((request) => request(axios, basePath));
        },
        /**
         * メンバー情報の一覧取得を行う
         * @summary メンバー一覧の取得を行う
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMemberGet(options?: any): AxiosPromise<Array<GetAccountInfo>> {
            return localVarFp.apiMemberGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 指定のAccount情報を削除する
     * @summary アカウント削除
     * @param {string} id Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiAccountIdDelete(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiAccountIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定のAccount情報を取り出す
     * @summary アカウント読み込み
     * @param {string} id Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiAccountIdGet(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiAccountIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定のAccount情報を更新する
     * @summary アカウント更新
     * @param {string} id Guid
     * @param {PutAccountInfo} body アカウント情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiAccountIdPut(id: string, body: PutAccountInfo, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiAccountIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Account情報の新規登録を行う
     * @summary マイアカウント新規登録
     * @param {PostAccountInfo} body アカウント情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiAccountPost(body: PostAccountInfo, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiAccountPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Account情報の該当行。氏名、携帯番号、アタッチファイルの更新を行う
     * @summary マイアカウント管理
     * @param {PutAccountInfo} body アカウント情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiAccountPut(body: PutAccountInfo, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiAccountPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Account情報の該当行を取得する
     * @summary マイアカウント管理
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiMeGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Account情報の該当行を再度取得する
     * @summary マイアカウント管理
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiMeReloadGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiMeReloadGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メンバー情報の一覧取得を行う
     * @summary メンバー一覧の取得を行う
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiMemberGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiMemberGet(options).then((request) => request(this.axios, this.basePath));
    }
}



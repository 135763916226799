//react
import React, {useState} from "react";

//common
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

//local
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import {AdminApiFp, GetAccountInfo} from "../client-axios";
import {CommonConfiguration} from "../common/CommonSetting";
import CommonAdminAccountEditBox from "../common/CommonAdminAccountEditBox";

interface Props {
    value: GetAccountInfo,
    organizationId: string,
    onUpdate: () => void,
}

const AdminAccountRow = (props: Props) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    //ダイアログ
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");

    
    const onEdit = async() => {
        setIsEdit(true);
    };

    const onEditUpdated = () => {
        setIsEdit(false);
        props.onUpdate();
    };

    const onDeleteYes = async() => {
        const conf = CommonConfiguration();
        if (props.organizationId != null) {
            const apiDelete = await AdminApiFp(conf).apiAdminOrganizationOidAccountIdDelete(props.organizationId, props.value.id);
            try {
                const ret = await apiDelete();
                if (ret.status === 200) {
                    setDialogMessage("削除しました");
                }
            } catch (e) {
                //更新系例外
                if (e instanceof Error && e.message === "Network Error") {
                    setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                } else if (e.response.status === 400 || e.response.status === 500) {
                    setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
                } else if (e.response.status === 422) {
                    setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
                } else if (e.response.status === 404) {
                    setDialogWithClose("削除に失敗しました。");
                } else if (e.response.status === 405) {
                    window.location.href = "/timeout";
                } else {
                    setDialogWithClose("削除に失敗しました。");
                }
            }
        }
        setIsDelete(false);
    }
    
    const onDeleteNo = () => {
        setIsDelete(false);
    }
    
    const onDelete = () => {
        setIsDelete(true);
    };

    const onOkNoContinue = ()=>{
        setDialogWithClose("");
    }

    const onDialogOk = () => {
        setDialogMessage("");
        props.onUpdate();
    }

    return  (
        <tr key={props.value.id}>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onOkNoContinue} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            <td>{props.value.ossName}</td>
            <td>{props.value.ossMailAddress}</td>
            <td>{props.value.levelKind === 2 ? "管理者" : "ユーザー" }</td>
            <td><FontAwesomeIcon icon={faEdit} onClick={onEdit} style={{cursor: "pointer"}} /></td>
            <td><FontAwesomeIcon icon={faTrashAlt} onClick={onDelete} style={{cursor: "pointer"}} /></td>
            {isEdit && <CommonAdminAccountEditBox value={props.value} method={"PUT"} readonly={true} title={"アカウント編集"} organizationId={props.organizationId} refresh={true} onUpdate={onEditUpdated} />}
            {isDelete && <CommonDialogBox value={"削除しますか？"}  style={DialogStyle.yesNo} onClickYes={onDeleteYes} onClickNo={onDeleteNo} />}
        </tr>
    )
}

export default (AdminAccountRow);


import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Modal} from "react-bootstrap";
import ThrottleButton from "../control/ThrottleButton";

export enum DialogStyle{
    okOnly,
    okCancel,
    yesNo
}

export interface withDialogState{
    errorMessage : string 
}

interface Props{
    title? : string,
    value :string,
    style? : DialogStyle,
    icon? : IconProp,
    onClickOK?:((e:any)=>void),
    onClickCancel?:(e:any)=>void,
    onClickYes?:(e:any)=>void,
    onClickNo?:(e:any)=>void
}
interface State{}

const CommonDialogBox = (props: Props) => {
    const buttonStyle = props.style == null ? DialogStyle.okOnly : props.style;
    const buttonArea = buttonStyle === DialogStyle.okOnly ? <div><ThrottleButton className="btn btn-primary buttonProp" onClick={props.onClickOK} >OK</ThrottleButton></div> :
        buttonStyle === DialogStyle.okCancel ? <div><ThrottleButton className="btn-margin btn btn-success buttonProp" onClick={props.onClickOK}>OK</ThrottleButton><ThrottleButton className="btn btn-primary buttonProp" onClick={props.onClickCancel}>キャンセル</ThrottleButton></div> :
            buttonStyle === DialogStyle.yesNo ? <div><ThrottleButton className="btn-margin btn btn-success buttonProp" onClick={props.onClickYes}>Yes</ThrottleButton><ThrottleButton className="btn btn-primary buttonProp" onClick={props.onClickNo}>No</ThrottleButton></div> : <div />;

    const handleClose = ()=> {
        return false;
    // return    DialogStyle.okCancel ? props.onClickOK : props.onClickCancel;
    }
    const icon = props.icon !== undefined && <FontAwesomeIcon icon={props.icon} />;

    return (


    <Modal show={props.value !== ""} onHide={handleClose} className={"CommonDialogBox"}>
        <Modal.Header>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dialogStyle">
            <h3 className="headStyle">{icon}{props.title}</h3>
            <div className="bodyStyle">{props.value}</div>
        </Modal.Body>
        <Modal.Footer>
            <footer className="footStyle">{buttonArea}</footer>
        </Modal.Footer>
    </Modal>
    
    
        
    )
}

export default CommonDialogBox;

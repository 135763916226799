//react 
import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {Col, Container, Form, Row} from "react-bootstrap";


//common
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretSquareUp, faPaperPlane, faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";


//local
import {CommonConfiguration} from "../common/CommonSetting";
import {
    ApplicationApiFp,
    GetAccountInfo, GetApplicationInfo,
    GetOrganizationInfo,
    OrganizationApiFp, UpdateApplicationInfo,
    UserApiFp
} from "../client-axios";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import ApplicationForms from "./ApplicationForms";

import {
    format10DateToHaiphongDate, format422DateToHaiphongDate, FormatDate,
    formatHaiphongDateTo8,
    formatHaiphongDateToD,
    formatHaiphongDateToM,
    formatHaiphongDateToY
} from "../common/CommonFormat";
import CommonSpinner from "../common/CommonSpinner";
import ApplicationFilesForm, {ApplicationFileInterface} from "./ApplicationFilesForm";
import {isMynaPopupResult} from "../common/CommonComm";
import {MynaPopupResult} from "./UserRouteOidc";
import ThrottleButton from "../control/ThrottleButton";
import {useStore} from "react-redux";

interface Props extends RouteComponentProps {
}

const UserRouteApplicationCreate = (props: Props) => {
    document.title = "申請登録";
    const methods = useForm({mode: "onBlur"});
    const {trigger} = methods;

    //申請ID
    const [applicationId, setApplicationId] = useState("");
    //申請の送信開始フラグ
    const [runSendApplication, setRunSendApplication] = useState(false);


    //ダイアログ
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
        setIsLoaded(true);
    }
    const store = useStore()
    const state = store.getState()
    const query: string = state.globalSearchKey;
    const onCloseAndGotoList = () => {
        setDialogMessage("");
        setDialogWithClose("");
        let url = "/applicationList";
        if(query === "")
        {
            props.history.push(url);
        }
        else
        {
            props.history.push(url + "?" + query);
        }
    }


    const [isLoaded, setIsLoaded] = useState(false);


    const [isLoadedOrganization, setIsLoadedOrganization] = useState(false);

    const [organizationInfo, setOrganizationInfo] = useState<GetOrganizationInfo | undefined>();
    const [applicationRows, setApplicationRows] = useState<ApplicationFileInterface[] | undefined>();

    const [saveConfirm, setSaveConfirm] = useState("");
    const [sendConfirm, setSendConfirm] = useState("");

    const getOrganization = async () => {
        const conf = CommonConfiguration();
        const apiGet = await OrganizationApiFp(conf).apiOrganizationGet();
        try {

            const ret = await apiGet();

            if (ret.status === 200) {
                const org: GetOrganizationInfo = ret.data;
                setOrganizationData(org);
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }

    };
    const setOrganizationData = (org: GetOrganizationInfo) => {
        setOrganizationInfo(org);
        methods.setValue("corpNo", org.corpNo);
        methods.setValue("corpNameKanji", org.corpNameKanji);
        methods.setValue("corpNameKana", org.corpNameKana);
        methods.setValue("corpKind", org.corpKind);
        methods.setValue("corpKindEtc", org.corpKind === "B019_061" ? org.corpKindEtc : "");
        methods.setValue("corpKindBA", org.corpKindBA);
        // methods.setValue("//"insuranceList			, org.// insuranceList			);
        methods.setValue("corpYubin", org.corpYubin);
        methods.setValue("corpPref", org.corpPref);
        methods.setValue("corpShikuchouson", org.corpShikuchouson);
        methods.setValue("corpBanchi", org.corpBanchi);
        methods.setValue("corpIkou", org.corpIkou);
        methods.setValue("representativeFamilyName", org.representativeFamilyName);
        methods.setValue("representativeFirstName", org.representativeFirstName);
        methods.setValue("corpTelNo1", org.corpTelNo1);
        methods.setValue("corpTelNo2", org.corpTelNo2);
        methods.setValue("corpTelNo3", org.corpTelNo3);
        methods.setValue("representativeFamilyNameKana", org.representativeFamilyNameKana);
        methods.setValue("representativeFirstNameKana", org.representativeFirstNameKana);
        methods.setValue("representativeYubin", org.representativeYubin);
        methods.setValue("representativePref", org.representativePref);
        methods.setValue("representativeShikuchouson", org.representativeShikuchouson);
        methods.setValue("representativeBanchi", org.representativeBanchi);
        methods.setValue("representativeIkou", org.representativeIkou);
        methods.setValue("representativeTelNo1", org.representativeTelNo1);
        methods.setValue("representativeTelNo2", org.representativeTelNo2);
        methods.setValue("representativeTelNo3", org.representativeTelNo3);
        methods.setValue("representativeMailAddress", org.representativeMailAddress);
        // setApplicationInfo(row);
        setIsLoadedOrganization(true);
    }

    const getAccount = async () => {
        const conf = CommonConfiguration();
        const apiGet = await UserApiFp(conf).apiMeReloadGet();
        try {

            const ret = await apiGet();

            if (ret.status === 200) {
                const data: GetAccountInfo = ret.data;
                setAccountData(data);
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
        }
        setIsLoaded(true);

    };

    const setAccountData = (acc: GetAccountInfo) => {

        methods.setValue("ossMailAddress", acc.ossMailAddress);
        methods.setValue("ossName", acc.ossName);
        methods.setValue("ossAddress", acc.ossAddress);
        methods.setValue("ossSex", acc.ossSex);
        methods.setValue("ossBirthday", acc.ossBirthday);
        methods.setValue("ossTelNo1", acc.ossTelNo1);
        methods.setValue("ossTelNo2", acc.ossTelNo2);
        methods.setValue("ossTelNo3", acc.ossTelNo3);
        methods.setValue("ossCorpNameKanji", acc.ossCorpNameKanji);
        methods.setValue("ossCorpNameKana", acc.ossCorpNameKana);
        // methods.setValue("attachFileName", acc.attachFileName );
        // setApplicationInfo(row);

    }
    useEffect(() => {
        getOrganization().then();
    }, []);

    useEffect(() => {
        if (isLoadedOrganization) {
            getAccount().then();
        }
    }, [isLoadedOrganization]);

    const conf = CommonConfiguration();

    const saveTemporary = async (info: UpdateApplicationInfo) => {
        const conf = CommonConfiguration();
        info.id="00000000-0000-0000-0000-000000000000";
        const apiPost = await ApplicationApiFp(conf).apiApplicationPost(info);
        try {
            const ret = await apiPost();
            if (ret.status === 200) {
                setDialogWithClose("一時保存しました。");

            } else if (ret.status === 204) {
                setDialogWithClose("一時保存しました。");
            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
    };
    const saveTemporaryWithSend = async (postFlag, info) : Promise<string | undefined> => {
        if ( postFlag )
        {
            info.id="00000000-0000-0000-0000-000000000000";
            const apiPost = await ApplicationApiFp(conf).apiApplicationPost(info);
            try {
                const ret = await apiPost();
                if (ret.status === 200) {
                    return ret.data.id;
                }
            } catch (e) {
                //更新系例外
                if (e instanceof Error && e.message === "Network Error") {
                    setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                } else if (e.response.status === 400 || e.response.status === 500) {
                    setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
                } else if (e.response.status === 422) {
                    setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
                } else if (e.response.status === 404) {
                    setDialogWithClose("更新に失敗しました。");
                } else if (e.response.status === 405) {
                    window.location.href = "/timeout";
                } else {
                    setDialogWithClose("更新に失敗しました。");
                }
            }

        }else{
            const apiPut = await ApplicationApiFp(conf).apiApplicationIdPut(applicationId, info);
            try {
                const ret = await apiPut();
                if (ret.status === 200) {
                    return applicationId;
                }
            } catch (e) {
                //更新系例外
                if (e instanceof Error && e.message === "Network Error") {
                    setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
                } else if (e.response.status === 400 || e.response.status === 500) {
                    setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
                } else if (e.response.status === 422) {
                    setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
                } else if (e.response.status === 404) {
                    setDialogWithClose("更新に失敗しました。");
                } else if (e.response.status === 405) {
                    window.location.href = "/timeout";
                } else {
                    setDialogWithClose("更新に失敗しました。");
                }
            }
            return undefined;
            
        }





    };

    const onMakeInfo = (data): UpdateApplicationInfo => {
        const sendData: UpdateApplicationInfo =
            {
                id : applicationId,
                // sendFlag                                    : data.sendFlag,
                tempApplicationNo: data.tempApplicationNo?.substr(0, 20),
                applicationNo: data.applicationNo?.substr(0, 17),
                teishutumotoId: data.teishutumotoId?.substr(0, 9),
                applicationDate: data.applicationDate,
                tsuban: "1",
                // tsuban: data.tsuban?.substr(0, 3),
                sikibetuJouhouY: formatHaiphongDateToY(data.sikibetuJouhouYMD),
                sikibetuJouhouM: formatHaiphongDateToM(data.sikibetuJouhouYMD),
                sikibetuJouhouD: formatHaiphongDateToD(data.sikibetuJouhouYMD),
                jigyousyoSeirikigouPref: data.jigyousyoSeirikigouPref?.substr(0, 2),
                jigyousyoSeirikigouGunshiku: data.jigyousyoSeirikigouGunshiku?.substr(0, 2),
                jigyousyoSeirikigouKigou: data.jigyousyoSeirikigouKigou?.substr(0, 4),
                jigyousyoSeirikigouBangou: data.jigyousyoSeirikigouBangou?.substr(0, 5),
                kenpoSikakuShutoku: data.kenpoSikakuShutoku?.substr(0, 5),
                kenpoHihuyousha: data.kenpoHihuyousha?.substr(0, 5),
                kenpoSikakuSoushitsu: data.kenpoSikakuSoushitsu?.substr(0, 5),
                kenpoGetsugakuHenkou: data.kenpoGetsugakuHenkou?.substr(0, 5),
                kenpoSanteiKiso: data.kenpoSanteiKiso?.substr(0, 5),
                kenpoShouyoShiharai: data.kenpoShouyoShiharai?.substr(0, 5),
                kenpoGoukei: data.kenpoGoukei?.substr(0, 5),
                kokuminNenkin3gou: data.kokuminNenkin3gou?.substr(0, 5),
                kokuminNenkinGoukei: data.kokuminNenkinGoukei?.substr(0, 5),
                bikou: data.bikou?.substr(0, 117),
                jigyoushoYubin: data.jigyoushoYubin?.substr(0, 7),
                jigyoushoPref: data.jigyoushoPref?.substr(0, 50),
                jigyoushoShikuchouson: data.jigyoushoShikuchouson?.substr(0, 50),
                jigyoushoBanchi: data.jigyoushoBanchi?.substr(0, 50),
                jigyoushoIkou: data.jigyoushoIkou?.substr(0, 50),
                jigyoushoName: data.jigyoushoName?.substr(0, 25),
                jigyounushiFamilyName: data.jigyounushiFamilyName?.substr(0, 25),
                jigyounushiFirstName: data.jigyounushiFirstName?.substr(0, 25),
                telNo1: data.telNo1?.substr(0, 5),
                telNo2: data.telNo2?.substr(0, 4),
                telNo3: data.telNo3?.substr(0, 5),
                applicantsY: formatHaiphongDateToY(data.applicantsYMD),
                applicantsM: formatHaiphongDateToM(data.applicantsYMD),
                applicantsD: formatHaiphongDateToD(data.applicantsYMD),
                sharoushiFamilyName: data.sharoushiFamilyName?.substr(0, 40),
                sharoushiFirstName: data.sharoushiFirstName?.substr(0, 40),
                tsuuchisyoKibou: data.tsuuchisyoKibou != null && data.tsuuchisyoKibou === false ? "" : "ON",
                corpName: data.corpName?.substr(0, 13),
                destHokenKumiaiNo: data.destHokenKumiaiNo?.substr(0, 8),
                destHokenKumiaiName: data.destHokenKumiaiName?.substr(0, 40),
                attachedPost: data.attachedPost != null && data.attachedPost === false ? "" : "ON",
                attachedE: data.attachedE != null && data.attachedE === false ? "" : "ON",
                attachedNone: data.attachedNone != null && data.attachedNone === false ? "" : "ON",
                cancelAvailability: data.cancelAvailability?.substr(0, 2),
                cancelCauseAvailability: data.cancelCauseAvailability?.substr(0, 2),
                cancelReason: data.cancelReason?.substr(0, 768),

                destinationId: data.destinationId?.substr(0, 10),
                destinationName: data.destinationName?.substr(0, 100),
                destinationExtra1: data.destinationExtra1?.substr(0, 100),
                corpNo: data.corpNo?.substr(0, 13),
                corpNameKanji: data.corpNameKanji?.substr(0, 25),
                corpNameKana: data.corpNameKana?.substr(0, 60),
                corpKind: data.corpKind?.substr(0, 8),
                corpKindEtc: data.corpKind === "B019_061" ? data.corpKindEtc?.substr(0, 60) : "",
                corpKindBA: data.corpKindBA?.substr(0, 8),
                corpYubin: data.corpYubin?.substr(0, 7),
                corpPref: data.corpPref?.substr(0, 50),
                corpShikuchouson: data.corpShikuchouson?.substr(0, 50),
                corpBanchi: data.corpBanchi?.substr(0, 50),
                corpIkou: data.corpIkou?.substr(0, 50),
                representativeFamilyName: data.representativeFamilyName?.substr(0, 12),
                representativeFirstName: data.representativeFirstName?.substr(0, 12),
                corpTelNo1: data.corpTelNo1?.substr(0, 5),
                corpTelNo2: data.corpTelNo2?.substr(0, 4),
                corpTelNo3: data.corpTelNo3?.substr(0, 4),
                representativeFamilyNameKana: data.representativeFamilyNameKana?.substr(0, 24),
                representativeFirstNameKana: data.representativeFirstNameKana?.substr(0, 24),
                representativeYubin: data.representativeYubin?.substr(0, 7),
                representativePref: data.representativePref?.substr(0, 40),
                representativeShikuchouson: data.representativeShikuchouson?.substr(0, 40),
                representativeBanchi: data.representativeBanchi?.substr(0, 40),
                representativeIkou: data.representativeIkou?.substr(0, 40),
                representativeTelNo1: data.representativeTelNo1?.substr(0, 5),
                representativeTelNo2: data.representativeTelNo2?.substr(0, 4),
                representativeTelNo3: data.representativeTelNo3?.substr(0, 4),
                representativeMailAddress: data.representativeMailAddress?.substr(0, 50),
                ossName: data.ossName?.substr(0, 50),
                ossAddress: data.ossAddress?.substr(0, 128),
                ossSex: data.ossSex?.substr(0, 1),
                ossBirthday: formatHaiphongDateTo8(data.ossBirthday),
                ossMailAddress: data.ossMailAddress?.substr(0, 50),
                ossTelNo1: data.ossTelNo1?.substr(0, 5),
                ossTelNo2: data.ossTelNo2?.substr(0, 4),
                ossTelNo3: data.ossTelNo3?.substr(0, 4),
                ossCorpNameKanji: data.ossCorpNameKanji?.substr(0, 25),
                ossCorpNameKana: data.ossCorpNameKana?.substr(0, 60),
                sendAttachFile: data.sendAttachFile != null && data.sendAttachFile === true ? 1 : 0,
                searchTitle: data.searchTitle?.substr(0, 100),
                searchBikou: data.searchBikou,
            };
        return sendData;
    }

    const onSaveDialog = () => {
        setSaveConfirm("一時保存しますか？");
    }

    //本当のonSave
    const [onSaveFlag, setOnSaveFlag] = useState(false);

    const onSave = () => {
        setSendConfirm("");
        setOnSaveFlag(true);
    };
    const onSave2 = () => {

        const data = methods.getValues();
        const info = onMakeInfo(data);
        saveTemporary(info).then();
    };

    useEffect(() => {
        if ( onSaveFlag === true ) {
            setOnSaveFlag(false);
            onSave2();
        }
    }, [onSaveFlag])

    
    // const onSave = async () => {
    //     const data = methods.getValues();
    //     const info = onMakeInfo(data);
    //     const resultId = await saveTemporaryWithSend(info);
    //     if ( resultId == null ){
    //         setDialogWithClose("申請の保存に失敗しました");
    //         return;
    //     }
    //     setApplicationId(resultId);
    //     setRunSendApplication(true);
    // };
    const cancelSave = () => {
        setSaveConfirm("");
    }

    const onSendDialog = async () => {
        const result = await trigger();
        const errorCount = Object.keys(methods.formState.errors).length;
        if ( errorCount > 0) {

            methods.setFocus(Object.keys(methods.formState.errors)[0]);
            setDialogMessage("項目の入力に誤りがあります。");
            return;
        }
        if(result) setSendConfirm("送信しますか？");
    }

    const cancelSend = () => {
        setSendConfirm("");
    }

    const [onSubmitFlag, setOnSubmitFlag] = useState(false);

    const onSubmit = async () => {
        setSendConfirm("");
        setOnSubmitFlag(true);
    }
    useEffect(() => {
        if ( onSubmitFlag === true ) {
            setOnSubmitFlag(false);
            onSubmit2().then();
        }
    }, [onSubmitFlag])

    const onSubmit2 = async () => {

        const data = methods.getValues();
        
        const info = onMakeInfo(data);
        const resultId = await saveTemporaryWithSend(applicationId==="", info);
        if ( resultId == null ){
            return;
        }
        setApplicationId(resultId);
        setRunSendApplication(true);
    };
    useEffect(() => {
        if ( runSendApplication === true ) {
            setRunSendApplication(false);
            onSendApplication(false).then();
        }
    }, [runSendApplication])


    //ポップアップ
    const [isAuthenticateRetry, setIsAuthenticateRetry] = useState(false);

    useEffect(() => {
        const handler = event => {
            if ( isMynaPopupResult(event.data)) {
                const result = event.data as MynaPopupResult
                if ( result.result === true ){
                    setIsAuthenticateRetry(true);
                    return;
                }else{
                    setDialogWithClose("マイナポータルでの認証に失敗しました。");
                    return;
                }
            }
        };

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    }, []) // empty array => run only once
    //リトライ系機構
    useEffect(() => {
        if ( isAuthenticateRetry ) {
            onSendApplication(true).then();
        }
        setIsAuthenticateRetry(false);
    }, [isAuthenticateRetry])

    const onSendApplication = async (isRetry : boolean) => {
        setIsLoaded(false);
        if ( applicationRows == null || applicationRows.length == 0 ){
            setIsAuthenticateRetry(false);
            setDialogMessage("ファイルが添付されていません。");
            return;
        }
        const apiStatusUpdate = await ApplicationApiFp(conf).apiApplicationIdSendPost(applicationId, applicationRows);
        try {
            const ret = await apiStatusUpdate();
            if (ret.status === 200) {
                setDialogWithClose("送信が完了しました");
            }
        } catch
            (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 401 || e.response.status === 403) {
                if ( isRetry ) {
                    setDialogWithClose("マイナポータル認証に失敗しました。");
                }else{
                    window.open("/oidc?detail=first", "MynaPopup", "top=100, left=100, width=400, height=400, rel=opener");
                    return;
                }
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
        setRunSendApplication(false);
        setIsLoaded(true);
    }



    const onFilesUpdate = (v :  ApplicationFileInterface[]) => {
        setApplicationRows(v);

    }

    const nowDate = new Date(Date.now());

    const newCreateApplication: GetApplicationInfo = {
        id: "00000000-0000-0000-0000-000000000000",
        applicantsD: "",
        applicantsM: "",
        applicationDate: FormatDate(nowDate, "YYYY-MM-DD"),
        applicationKind: 0,
        applicationNo: "",
        attachedNone: "",
        attachedPost: "",
        bikou: "",
        cancelAvailability: "",
        cancelCauseAvailability: "",
        corpIkou: "",
        corpKind: "",
        corpKindBA: "",
        corpKindEtc: "",
        corpNameKana: "",
        corpNameKanji: "",
        corpNo: "",
        corpShikuchouson: "",
        corpTelNo1: "",
        corpTelNo2: "",
        corpTelNo3: "",
        corpYubin: "",
        destHokenKumiaiNo: "",
        destinationExtra1: "",
        destinationName: "",
        destinationId:"",
        jigyounushiFirstName: "",
        jigyoushoBanchi: "",
        jigyoushoIkou: "",
        jigyoushoName: "",
        jigyoushoShikuchouson: "",
        jigyoushoYubin: "",
        jigyousyoSeirikigouKigou: "",
        jigyousyoSeirikigouPref: "",
        kenpoGetsugakuHenkou: "",
        kenpoGoukei: "",
        kenpoHihuyousha: "",
        kenpoSanteiKiso: "",
        kenpoShouyoShiharai: "",
        kokuminNenkin3gou: "",
        kokuminNenkinGoukei: "",
        logs: undefined,
        ossAddress: "",
        ossBirthday: "",
        ossCorpNameKanji: "",
        ossMailAddress: "",
        ossTelNo1: "",
        ossTelNo2: "",
        ossTelNo3: "",
        representativeBanchi: "",
        representativeFamilyName: "",
        representativeFamilyNameKana: "",
        representativeFirstName: "",
        representativePref: "",
        representativeYubin: "",
        rowCount: 0,
        sendAttachFile: 0,
        sharoushiFirstName: "",
        sikibetuJouhouM: "",
        sikibetuJouhouY: "",
        teishutumotoId: "",
        telNo2: "",
        telNo3: "",
        tempApplicationNo: "",
        tsuuchisyoKibou: "",
        statusKind: -1,
        tsuban: "1",
        searchTitle: "",
        searchBikou: "",
    }

    return (
        <main role="main" className="flex-shrink-0">
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            <Container fluid={"xl"}>
                <h1>申請登録</h1>
                <FormProvider {...methods}>
                    <Form noValidate>

                        {!isLoaded && <span><CommonSpinner/></span>}
                        {isLoaded &&
                        <span>
                            <ApplicationForms value={newCreateApplication} organization={organizationInfo}/>
                            <ApplicationFilesForm disabled={false} onUpdate={onFilesUpdate} value={applicationRows}/>
                            
                            <Form.Group controlId="TextArea">
                                <Form.Control readOnly={true} type="textarea" placeholder="取り下げ理由" className="cancelReason" />
                            </Form.Group>
                                </span>
                        }

                        
                        {isLoaded &&
                        <Form.Group as={Row}>
                            <Col md={4} style={{marginTop: 5}}>
                                <ThrottleButton variant="secondary" onClick={onCloseAndGotoList}><span className={"buttonLabel"}>申請一覧に戻る</span></ThrottleButton></Col>
                            <Col md={{span: 8}} style={{textAlign: "right", marginTop: 5}}>
                                <ThrottleButton style={{marginLeft: 5, marginRight: 5}} onClick={onSaveDialog}><span className={"buttonLabel"}>一時保存</span></ThrottleButton>
                                {/*<Button type={"submit"} style={{margin: 5}}><FontAwesomeIcon icon={faPaperPlane}/><span className={"buttonLabel"}>データの送信</span></Button>*/}
                                <ThrottleButton onClick={onSendDialog} style={{marginLeft: 5, marginRight: 5}}><span className={"buttonLabel"}>データの送信</span></ThrottleButton>
                                <ThrottleButton disabled={true}><span className={"buttonLabel"}>取り下げの送信</span></ThrottleButton>
                                {sendConfirm !== "" && <CommonDialogBox value={sendConfirm} style={DialogStyle.yesNo} onClickYes={onSubmit} onClickNo={cancelSend} />}
                                {saveConfirm !== "" && <CommonDialogBox value={saveConfirm} style={DialogStyle.yesNo} onClickYes={onSave} onClickNo={cancelSave} />}
                            </Col>
                        </Form.Group>
                        } 

                    </Form>
                </FormProvider>
            </Container>
        </main>);


};

export default withRouter(UserRouteApplicationCreate);



//react 
import React, {useEffect, useState} from "react";
import {GetAccountInfo, UserApiFp} from "../client-axios";
import {Row} from "react-bootstrap";
import ThrottleButton from "../control/ThrottleButton";
import {CommonConfiguration} from "../common/CommonSetting";


//common


//local



interface Props {
}


const UserCommonLogo = (props: Props) => {

    const url = "/menu";

    const [account, setAccount] = useState<GetAccountInfo | undefined>(undefined);

    const onSignOutClick = () => {
        window.location.href = "/SignOut";
    }

    const getMeAsync = async () =>{
        const conf = CommonConfiguration();
        const d = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await d();
            if (ret.status === 200) {
                const x: GetAccountInfo = ret.data;
                setAccount(x);
            } else {
                window.location.href = "/timeout";
            }
        }
        catch(e){
            window.location.href = "/timeout";
        }
    }

    useEffect(() => {
        getMeAsync().then();
    }, [])

    return(
        <>
            <header>
                <div className="container-xl topLogo">
                    <a href={url}>
                        <img className="logo" src="/logo.png" alt={"logo"} />
                    </a>
                    <Row className={"account"}>{account?.ossDispName}　{account?.ossName}　(前回サインイン日時：{account?.lastSigninDate === "" ? "初回ログイン" : account?.lastSigninDate})　
                        <ThrottleButton className={"account"} onClick={onSignOutClick}>サインアウト</ThrottleButton>
                    </Row>
                </div>
            </header>
        </>
    );

}

export default (UserCommonLogo);


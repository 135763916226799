//react 
import React, { useState} from "react";
import {generatePath, RouteComponentProps, withRouter} from "react-router-dom";


//common
import {faCopy, faEdit, faTrashAlt, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CopyToClipboard} from 'react-copy-to-clipboard';

//local
import {AdminApiFp, GetOrganizationInfo} from "../client-axios";
import {basePath, CommonConfiguration} from "../common/CommonSetting";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import OrganizationEditBox from "./OrganizationEditBox";
import {ProviderEnumZeem} from "../common/CommonDefine";


interface Props extends RouteComponentProps {
    value : GetOrganizationInfo,
    onUpdate : () => void
}


const OrganizationRow =  (props: Props) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [copied, setCopied] = useState(false);


    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
        props.onUpdate();
    }
    
    
    const onEdit = async() =>     {
        setIsEdit(true);
    };

    const onEditUpdated = () => {
        setIsEdit(false);
        props.onUpdate();

    };

    const onMember = async() => {
        props.history.push(generatePath("/admin/member/:id",{id: props.value.id}));
    };
    
    const onDelete = ()=>{
        setIsDelete(true);
    }
    const onDeleteYes = async() => {
        const conf = CommonConfiguration();
        const apiDelete = await AdminApiFp(conf).apiAdminOrganizationIdDelete(props.value.id);
        try {
            const ret = await apiDelete();
            if (ret.status === 200) {
                setDialogWithClose("削除しました。");
            } else if (ret.status === 204) {
                setDialogWithClose("削除しました。");
            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("削除に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("削除に失敗しました。");
            }
        }

        setIsDelete(false);
    }

    const onDeleteNo = ()=>{
        setIsDelete(false);
    }

    const onCopyClip = ()=>{
        setCopied(true);
        setTimeout(()=>setCopied(false), 3000);

    }

    return(
        <tr key={props.value.id}>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            
            <td>{props.value.corpNameKanji}</td>
            <td>{props.value.corpNameKana}</td>
            {
                props.value.provider === ProviderEnumZeem
                ?<td><CopyToClipboard text={basePath + "/ZeemSignIn/" + props.value.id} onCopy={onCopyClip}>
                    <span>
                        {copied
                        ? <span className={"copyFade"}>コピーしました</span>
                        : <FontAwesomeIcon icon={faCopy} style={{cursor: "pointer"}}/>
                    }
                    </span>
                </CopyToClipboard></td>
                : <td><CopyToClipboard text={basePath + "/signin2"} onCopy={onCopyClip}>
                    <span>
                        {copied
                            ? <span className={"copyFade"}>コピーしました</span>
                            : <FontAwesomeIcon icon={faCopy} style={{cursor: "pointer"}}/>
                        }
                    </span>
                </CopyToClipboard></td>
            }
            <td><FontAwesomeIcon icon={faEdit} onClick={onEdit} style={{cursor: "pointer"}} /></td>
            <td><FontAwesomeIcon icon={faUsers} onClick={onMember} style={{cursor: "pointer"}} /></td>
            <td><FontAwesomeIcon icon={faTrashAlt} onClick={onDelete} style={{cursor: "pointer"}} /></td>
            {isEdit && <OrganizationEditBox value={props.value.id} onUpdate={onEditUpdated} />}
            {isDelete && <CommonDialogBox value={"削除しますか？"} style={DialogStyle.yesNo} onClickYes={onDeleteYes} onClickNo={onDeleteNo} />}
        </tr>
    );
};


export default withRouter(OrganizationRow);


//react 
import React, {SyntheticEvent, useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import { KeyboardEvent } from "react";
import ThrottleButton from "../control/ThrottleButton";

//common

//local
import {
    GetAccountInfo,
    GetApplicationInfo, GetApplicationLog,
    GetOrganizationInfo,
} from "../client-axios";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import {
    format422DateToHaiphongDate,
    FormatDate, formatHaiphongDateToD, formatHaiphongDateToM,
    formatHaiphongDateToY,
    minus100year,
    minus5year,
    plus10year,
    plus5year,
    ymdMixing
} from "../common/CommonFormat";
import OrganizationFormBase from "../control/OrganizationBaseForms";
import AccountForms from "../control/AccountForms";
import RequiredImage from "../control/RequiredImage";
import CommonFileDropRow from "../control/CommonFileDropRow";
import ApplicationLogTable from "./ApplicationLogTable";


interface Props extends RouteComponentProps {
    value?:GetApplicationInfo,
    organization ?: GetOrganizationInfo
}

const ApplicationForms = (props: Props) => {

    const {register, setValue, getValues, formState: {errors}} = useFormContext();

    const [filename, setFileName] = useState<string | undefined>("");
    const [binary, setBinary] = useState<string | undefined>("");





    //ダイアログ
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogMessage("");
        setDialogWithClose("");
        props.history.push("/menu");
    }

    const insuranceOnChange = (e) =>
    {
        //提出先情報ID
        let destinationId = e.target.value;

        const insuranceRow = props.organization?.insuranceList?.find(x => x.destinationId === destinationId);
        if (insuranceRow != null)
        {
            //提出先名
            let destinationName = insuranceRow.destinationName;

            setValue('destinationId', destinationId, { shouldValidate: true })

            //提出先健康保険組合名称
            setValue('destHokenKumiaiName', destinationName, { shouldValidate: true })
            // 提出先名
            setValue('destinationName', destinationName, { shouldValidate: true })


            // 拡張コード1
            setValue('destinationExtra1', insuranceRow.destinationExtra1, { shouldValidate: true })
            // 都道府県コード

            setValue('jigyousyoSeirikigouPref', insuranceRow.jigyousyoSeirikigouPref, { shouldValidate: true })
            // 群市区記号
            setValue('jigyousyoSeirikigouGunshiku', insuranceRow.jigyousyoSeirikigouGunshiku, { shouldValidate: true })
            // 事業所記号
            setValue('jigyousyoSeirikigouKigou', insuranceRow.jigyousyoSeirikigouKigou, { shouldValidate: true })
            // 事業所番号
            setValue('jigyousyoSeirikigouBangou', insuranceRow.jigyousyoSeirikigouBangou, { shouldValidate: true })
        }
        else
        {
            // 空白行を選択
            setValue('destinationId', destinationId, { shouldValidate: true })

            let loc = props.location.pathname;
            if (loc === '/application/' || loc === '/application')
            {
                //登録
                // 補間先項目は空白に戻さなくて良い
            }
            else
            {
                //編集

            }

        }
    }

    
    const StatusKindMap = new Map([
        [-1, "作成中"],
        [0, "受付処理中"],
        [1, "送信待ち"],
        [2, "処理中"],
        [3, "終了"],
        [4, "要再申請(補正可)"],
        [5, "要ダウンロード"],
        [6, "取下げ済み"],
        [8, "要再申請(補正不可)"],
        [10, "取下げ済み"],
        [11, "要確認（取下げ不可）"],
        [12, "要確認（取下げ不可）"],
        [13, "取下げ処理中"],
        [14, "取下げ処理中"],

    ]);

    const getStringValueFunc = <K, >(map: Map<K, string>) =>
        (code: K) =>{
return            (map.has(code)) ? map.get(code) : "";
        
        }

    const daikouDisable = ((props.value?.statusKind === -1) || (props.value?.statusKind === 4))?false:true;
    const readOnly = ((props.value?.statusKind !== -1) && (props.value?.statusKind !== 4))?true:false;

    const onTabKey = ( e: KeyboardEvent ) => {
        if(e.key !== "Tab"){ return; }
        
        e.preventDefault();
        
        const textareaElement = e.target;
        // @ts-ignore
        const cursorPos = textareaElement.selectionStart;
        // @ts-ignore
        const cursorLeft = textareaElement.value.substr(0, cursorPos );
        // @ts-ignore
        const cursorRight = textareaElement.value.substr(cursorPos, textareaElement.textLength);

        // @ts-ignore
        textareaElement.value = cursorLeft + "\t" + cursorRight;

        // @ts-ignore
        textareaElement.selectionEnd = cursorPos + 1;
    };

    const CopyOfficeAddress = () =>
    {
        let org = props.organization;

        let yubin = getValues("corpYubin"); // 郵便番号
        let pref = getValues("corpPref");  // 都道府県
        let shikuchouson = getValues("corpShikuchouson"); // 市区町村
        let banchi = getValues("corpBanchi"); // 番地以下
        let ikou = getValues("corpIkou"); // それ以外の住所
        //let nameKanji = getValues("corpNameKanji");//商号（法人名） 

        setValue('jigyoushoYubin', yubin, { shouldValidate: true });
        setValue('jigyoushoPref', pref, { shouldValidate: true });
        setValue('jigyoushoShikuchouson', shikuchouson, { shouldValidate: true });
        setValue('jigyoushoBanchi', banchi, { shouldValidate: true });
        setValue('jigyoushoIkou', ikou, { shouldValidate: true });
        //setValue('jigyoushoName', nameKanji, { shouldValidate: true }); 商号→事業所名のコピーは行わない


        // props.value.jigyoushoYubin = props.value?.corpYubin;
        //props.value?.jigyoushoYubin = props.value?.corpYubin;
        // props.jigyoushoYubin = porps.corpYubin;
    };


    const CopyTeishutumotoId = () =>
    {
        
        let seirikigouGunshiku = getValues("jigyousyoSeirikigouGunshiku"); // 群市区記号
        let seirikigou = getValues("jigyousyoSeirikigouKigou");  // 整理記号

        let id = seirikigouGunshiku + '-' +seirikigou
        setValue('teishutumotoId', id, { shouldValidate: true });

    };

    return (
        <div>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

                <Form.Group as={Row}>
                    <Col md={5}><Form.Label>タイトル<br />
                        <span className="subLabel">(最大100文字)</span></Form.Label></Col>
                    <Col md={6}><Form.Control readOnly={readOnly} type="text" placeholder="タイトル"
                                              {...register("searchTitle", {required: false, maxLength: 100})}
                                              isInvalid={!!errors.searchTitle}
                    />
                        {errors.searchTitle && errors.searchTitle.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は100文字までです。
                        </Form.Control.Feedback>
                        }

                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col md={5}><Form.Label>備考</Form.Label></Col>
                    <Col md={6}><Form.Control readOnly={readOnly} type="text" placeholder="備考"
                                              {...register("searchBikou", {required: false})}
                                              isInvalid={!!errors.searchBikou}
                    />
                        
                    </Col>
                </Form.Group>
            
                <OrganizationFormBase value={props.value} readOnly={readOnly} />
                <AccountForms value={props.value} readOnly={readOnly} isNotossDispName={true} />


                <Form.Group as={Row}>
                    <Col md={5}><Form.Label>申請日<RequiredImage /><br/>
                        <span className="subLabel">(現在の西暦-5年から+5年の範囲)</span></Form.Label></Col>
                    <Col md={6}><Form.Control readOnly={readOnly} type="date" defaultValue={props.value?.applicationDate} placeholder="申請日" max={plus5year()} min={minus5year()}
                                              {...register("applicationDate", {required: true, maxLength: 13, max: plus5year(), min: minus5year()})}
                                              isInvalid={!!errors.applicationDate}
                    />
                        {errors.applicationDate && errors.applicationDate.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.applicationDate && errors.applicationDate.type === "max" &&
                        <Form.Control.Feedback type="invalid">
                            設定可能範囲外です。
                        </Form.Control.Feedback>
                        }
                        {errors.applicationDate && errors.applicationDate.type === "min" &&
                        <Form.Control.Feedback type="invalid">
                            設定可能範囲外です。
                        </Form.Control.Feedback>
                        }

                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Form.Label column md={5}>
                        提出先保険組合
                    </Form.Label>
                <Col md={6}>
                    <Form.Control disabled={readOnly} as="select" onChange={insuranceOnChange} defaultValue={readOnly? "": props.value?.destinationId}  >
                            <option key={"insuranceSpace"} value={""}> </option>
                            {props.organization?.insuranceList != null && props.organization?.insuranceList.map(row => {
                                return <option key={row.destinationId} value={row.destinationId}>{row.destinationName} (提出先情報ID：{row.destinationId}　事業所都道府県：{row.jigyousyoSeirikigouPref}　群市区：{row.jigyousyoSeirikigouGunshiku}　記号：{row.jigyousyoSeirikigouKigou}　番号：{row.jigyousyoSeirikigouBangou}）</option>
                            })}
                        </Form.Control>

                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column md={12}>
                        識別情報
                    </Form.Label>
                </Form.Group>


                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            提出先名<RequiredImage /><br/>
                                <span className="subLabel">(全角、最大100文字)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="提出先名"
                                      className={"form-control"}
                                      {...register("destinationName", {required: true, maxLength: 100, pattern: /^[^ -~]+$/})}
                                      isInvalid={!!errors.destinationName}/>
                        {errors.destinationName && errors.destinationName.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.destinationName && errors.destinationName.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は100文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.destinationName && errors.destinationName.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            全角で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            拡張コード１<RequiredImage /><br/>
                                <span className="subLabel">(最大100文字)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="拡張コード１"
                                      className={"form-control"}
                                      {...register("destinationExtra1", {required: true, maxLength: 100})}
                                      isInvalid={!!errors.destinationExtra1}/>
                        {errors.destinationExtra1 && errors.destinationExtra1.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.destinationExtra1 && errors.destinationExtra1.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は100文字までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
    
                </Form.Group>
                

                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            提出元ID<RequiredImage /><br/>
                                <span className="subLabel">(半角(カナ除く)、全角、最大9桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                    <Row>{!readOnly && <ThrottleButton style={{ margin: '3px 0px' }} disabled={readOnly} onClick={CopyTeishutumotoId}>提出元ID（郡市区+記号）をコピー</ThrottleButton>}</Row>
                    <Row><Form.Control style={{ margin: '3px 0px' }}  readOnly={readOnly} type="text" placeholder="提出元ID"
                            className={"form-control"}
                            {...register("teishutumotoId", { required: true, maxLength: 9, pattern: /^[^ｦ-ﾝ]+$/ })}
                            isInvalid={!!errors.teishutumotoId} />
                            {errors.teishutumotoId && errors.teishutumotoId.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                            }
                            {errors.teishutumotoId && errors.teishutumotoId.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は9桁までです。
                                </Form.Control.Feedback>
                            }
                            {errors.teishutumotoId && errors.teishutumotoId.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    半角(カナ除く)、全角で入力してください。
                                </Form.Control.Feedback>
                            }</Row>
                        
                        
                    </Col>
                
                </Form.Group>




                {/*<Form.Group as={Row}>*/}
                {/*    <Col md={{span: 4, offset: 1}}>*/}
                {/*        <Form.Label>*/}
                {/*            <p>通番<RequiredImage /><br/>*/}
                {/*                <span className="subLabel">(半角数字、最大3桁)</span></p>*/}
                {/*        </Form.Label>*/}
                {/*    </Col>*/}
                {/*    <Col md={6}>*/}
                {/*        <Form.Control readOnly={readOnly} type="text" placeholder="通番" */}
                {/*                      className={"form-control"}*/}
                {/*                      {...register("tsuban", {required: true, maxLength: 3, pattern: /^\d+$/})}*/}
                {/*                      isInvalid={!!errors.tsuban}/>*/}
                {/*        {errors.tsuban && errors.tsuban.type === "required" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は必須です。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.tsuban && errors.tsuban.type === "maxLength" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は3桁までです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.tsuban && errors.tsuban.type === "pattern" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            半角数字で入力してください。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*</Form.Group>*/}


                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>作成年月日<RequiredImage /><br/>
                            <span className="subLabel">(現在の西暦-100年から+10年の範囲)</span></Form.Label></Col>
                    <Col md={6}><Form.Control readOnly={readOnly} type="date" defaultValue={props.value?.applicationDate} placeholder="作成年月日" max={plus10year()} min={minus100year()}
                                              {...register("sikibetuJouhouYMD", {required: true, maxLength: 13, max: plus10year(), min: minus100year()})}
                                              isInvalid={!!errors.sikibetuJouhouYMD}
                    />
                        {errors.sikibetuJouhouYMD && errors.sikibetuJouhouYMD.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.sikibetuJouhouYMD && errors.sikibetuJouhouYMD.type === "max" &&
                        <Form.Control.Feedback type="invalid">
                            設定可能範囲外です。
                        </Form.Control.Feedback>
                        }
                        {errors.sikibetuJouhouYMD && errors.sikibetuJouhouYMD.type === "min" &&
                        <Form.Control.Feedback type="invalid">
                            設定可能範囲外です。
                        </Form.Control.Feedback>
                        }

                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Form.Label column md={12}>
                        事業所整理記号
                    </Form.Label>
                </Form.Group>


                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            都道府県コード<RequiredImage /><br/>
                                <span className="subLabel">(半角(カナ除く)、全角、2文字)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="都道府県コード" 
                                      className={"form-control"}
                                      {...register("jigyousyoSeirikigouPref", {required: true, maxLength: 2, pattern: /^[^ｦ-ﾝ]{2}$/})}
                                      isInvalid={!!errors.jigyousyoSeirikigouPref}/>
                        {errors.jigyousyoSeirikigouPref && errors.jigyousyoSeirikigouPref.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyousyoSeirikigouPref && errors.jigyousyoSeirikigouPref.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は2文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyousyoSeirikigouPref && errors.jigyousyoSeirikigouPref.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角(カナ除く)、全角、2文字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            群市区記号<RequiredImage /><br/>
                                <span className="subLabel">(半角(カナ除く)、全角、2文字)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="群市区記号"
                                      className={"form-control"}
                                      {...register("jigyousyoSeirikigouGunshiku", {required: true, maxLength: 2, pattern: /^[^ｦ-ﾝ]{2}$/})}
                                      isInvalid={!!errors.jigyousyoSeirikigouGunshiku}/>
                        {errors.jigyousyoSeirikigouGunshiku && errors.jigyousyoSeirikigouGunshiku.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyousyoSeirikigouGunshiku && errors.jigyousyoSeirikigouGunshiku.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は2文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyousyoSeirikigouGunshiku && errors.jigyousyoSeirikigouGunshiku.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角(カナ除く)、全角、2文字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            事業所記号<RequiredImage /><br/>
                                <span className="subLabel">(半角(カナ除く)、全角、最大4文字)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="事業所記号" 
                                      className={"form-control"}
                                      {...register("jigyousyoSeirikigouKigou", {required: true, maxLength: 4, pattern: /^[^ｦ-ﾝ]+$/})}
                                      isInvalid={!!errors.jigyousyoSeirikigouKigou}/>
                        {errors.jigyousyoSeirikigouKigou && errors.jigyousyoSeirikigouKigou.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyousyoSeirikigouKigou && errors.jigyousyoSeirikigouKigou.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は4文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyousyoSeirikigouKigou && errors.jigyousyoSeirikigouKigou.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角(カナ除く)、全角で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            事業所番号<RequiredImage /><br/>
                                <span className="subLabel">(半角数字、最大5文字)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} required type="text" placeholder="事業所番号"
                                      className={"form-control"}
                                      {...register("jigyousyoSeirikigouBangou", {required: true, maxLength: 5, pattern: /^\d+$/})}
                                      isInvalid={!!errors.jigyousyoSeirikigouBangou}/>
                        {errors.jigyousyoSeirikigouBangou && errors.jigyousyoSeirikigouBangou.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyousyoSeirikigouBangou && errors.jigyousyoSeirikigouBangou.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyousyoSeirikigouBangou && errors.jigyousyoSeirikigouBangou.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Form.Label column md={12}>
                        健康保険・厚生年金保険 届書総件数
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            資格取得届/70歳以上被用者該当届（件）
                                <br/>
                                <span className="subLabel">(半角数字、最大5桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="資格取得届/70歳以上被用者該当届（件）"
                                      className={"form-control"}
                                      {...register("kenpoSikakuShutoku", {maxLength: 5, pattern: /^\d+$/})} isInvalid={!!errors.kenpoSikakuShutoku}/>
                        {errors.kenpoSikakuShutoku && errors.kenpoSikakuShutoku.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                        {errors.kenpoSikakuShutoku && errors.kenpoSikakuShutoku.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            被扶養者異動届/国民年金第3号被保険者関係届（件）

                                <br/>
                                <span className="subLabel">(半角数字、最大5桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="被扶養者異動届/国民年金第3号被保険者関係届（件）"
                                      className={"form-control"}
                                      {...register("kenpoHihuyousha", {maxLength: 5, pattern: /^\d+$/})} isInvalid={!!errors.kenpoHihuyousha}/>
                        {errors.kenpoHihuyousha && errors.kenpoHihuyousha.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                        {errors.kenpoHihuyousha && errors.kenpoHihuyousha.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            資格喪失届/70歳以上被用者不該当届（件）


                                <br/>
                                <span className="subLabel">(半角数字、最大5桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="資格喪失届/70歳以上被用者不該当届（件）"
                                      className={"form-control"}
                                      {...register("kenpoSikakuSoushitsu", {maxLength: 5, pattern: /^\d+$/})}
                                      isInvalid={!!errors.kenpoSikakuSoushitsu}/>
                        {errors.kenpoSikakuSoushitsu && errors.kenpoSikakuSoushitsu.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                        {errors.kenpoSikakuSoushitsu && errors.kenpoSikakuSoushitsu.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            月額変更届/70歳以上被用者月額変更届（件）
                                <br/>
                                <span className="subLabel">(半角数字、最大5桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="月額変更届/70歳以上被用者月額変更届（件）"
                                      className={"form-control"}
                                      {...register("kenpoGetsugakuHenkou", {maxLength: 5, pattern: /^\d+$/})}
                                      isInvalid={!!errors.kenpoGetsugakuHenkou}/>
                        {errors.kenpoGetsugakuHenkou && errors.kenpoGetsugakuHenkou.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                        {errors.kenpoGetsugakuHenkou && errors.kenpoGetsugakuHenkou.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            算定基礎届/70歳以上被用者算定基礎届（件）
                                <br/>
                                <span className="subLabel">(半角数字、最大5桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="算定基礎届/70歳以上被用者算定基礎届（件）"
                                      className={"form-control"}
                                      {...register("kenpoSanteiKiso", {maxLength: 5, pattern: /^\d+$/})} isInvalid={!!errors.kenpoSanteiKiso}/>
                        {errors.kenpoSanteiKiso && errors.kenpoSanteiKiso.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                        {errors.kenpoSanteiKiso && errors.kenpoSanteiKiso.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            賞与支払届/70歳以上被用者賞与支払届（件）

                                <br/>
                                <span className="subLabel">(半角数字、最大5桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="賞与支払届/70歳以上被用者賞与支払届（件）"
                                      className={"form-control"}
                                      {...register("kenpoShouyoShiharai", {maxLength: 5, pattern: /^\d+$/})}
                                      isInvalid={!!errors.kenpoShouyoShiharai}/>
                        {errors.kenpoShouyoShiharai && errors.kenpoShouyoShiharai.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                        {errors.kenpoShouyoShiharai && errors.kenpoShouyoShiharai.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            届書合計（件）

                                <br/>
                                <span className="subLabel">(半角数字、最大5桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="届書合計（件）" 
                                      className={"form-control"}
                                      {...register("kenpoGoukei", {maxLength: 5, pattern: /^\d+$/})} isInvalid={!!errors.kenpoGoukei}/>
                        {errors.kenpoGoukei && errors.kenpoGoukei.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                        {errors.kenpoGoukei && errors.kenpoGoukei.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Form.Label column md={12}>
                        国民年金 届書総件数
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            国民年金第3号被保険者関係届（件）

                                <br/>
                                <span className="subLabel">(半角数字、最大5桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="国民年金第3号被保険者関係届（件）"
                                      className={"form-control"}
                                      {...register("kokuminNenkin3gou", {maxLength: 5, pattern: /^\d+$/})} isInvalid={!!errors.kokuminNenkin3gou}/>
                        {errors.kokuminNenkin3gou && errors.kokuminNenkin3gou.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                        {errors.kokuminNenkin3gou && errors.kokuminNenkin3gou.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col md={{span: 4, offset: 1}}>
                        <Form.Label>
                            届書合計（件）


                                <br/>
                                <span className="subLabel">(半角数字、最大5桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="届書合計（件）" 
                                      className={"form-control"}
                                      {...register("kokuminNenkinGoukei", {maxLength: 5, pattern: /^\d+$/})}
                                      isInvalid={!!errors.kokuminNenkinGoukei}/>
                        {errors.kokuminNenkinGoukei && errors.kokuminNenkinGoukei.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字で入力してください。
                        </Form.Control.Feedback>
                        }
                        {errors.kokuminNenkinGoukei && errors.kokuminNenkinGoukei.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は5桁までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Form.Label column md={2}>
                        備考<br/>
                            <span className="subLabel">(半角(カナ除く)、全角、改行、タブ、最大117文字)</span>
                    </Form.Label>
                    <Col md={10}><div onKeyDown={onTabKey}>
                        <Form.Control readOnly={readOnly} as="textarea" rows={4} placeholder="備考" 
                                      className={"form-control"}
                                      {...register("bikou", {maxLength: 117, pattern: /^[^ｦ-ﾝ]+$/})} isInvalid={!!errors.bikou}/>
                        {errors.bikou && errors.bikou.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は117文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.bikou && errors.bikou.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角(カナ除く)、全角で入力してください。
                        </Form.Control.Feedback>
                        }
                        </div>
                    </Col>
                </Form.Group>


                
                

                <Form.Group as={Row}>
                    <Form.Label column md={5}>
                        事業所情報
                </Form.Label>
                
                <Col md={7} >
                    {!readOnly && 
                    <ThrottleButton  disabled={readOnly} onClick={CopyOfficeAddress }>本店又は主たる事務所の所在地をコピー</ThrottleButton>
                    }
                </Col>
                

                
                </Form.Group>


                <Form.Group as={Row}>
                    <Form.Label column md={{span: 4, offset: 1}}>
                        郵便番号<RequiredImage /><br/>
                            <span className="subLabel">(半角数字、7桁 ハイフン無し)</span>
                    </Form.Label>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} required type="text" placeholder="郵便番号" 
                                      className={"form-control"}
                                      {...register("jigyoushoYubin", {required: true, maxLength: 7, pattern: /^\d{7}$/})}
                                      isInvalid={!!errors.jigyoushoYubin}/>
                        {errors.jigyoushoYubin && errors.jigyoushoYubin.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyoushoYubin && errors.jigyoushoYubin.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は7桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyoushoYubin && errors.jigyoushoYubin.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字、7桁で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Form.Label column md={{span: 10, offset: 1}}>
                            <span style={{fontSize:"smaller", color:"red"}}>※ 「都道府県」「市区町村」「番地以下」「それ以降の住所」は合わせて最大50文字で入力してください。</span>
                    </Form.Label>
                </Form.Group>


                <Form.Group as={Row}>
                    <Form.Label column md={{span: 4, offset: 1}}>
                        都道府県<sup style={{color:"red"}}> ※</sup><RequiredImage />
                    </Form.Label>
                    <Col md={6}>
                        <Form.Control disabled={readOnly} required as="select" placeholder="都道府県" 
                                      {...register("jigyoushoPref", {required: true, maxLength: 8})}
                                      isInvalid={!!errors.jigyoushoPref}>
                            <option value=""/>

                            <option value="北海道">北海道</option>
                            <option value="青森県">青森県</option>
                            <option value="岩手県">岩手県</option>
                            <option value="宮城県">宮城県</option>
                            <option value="秋田県">秋田県</option>
                            <option value="山形県">山形県</option>
                            <option value="福島県">福島県</option>
                            <option value="茨城県">茨城県</option>
                            <option value="栃木県">栃木県</option>
                            <option value="群馬県">群馬県</option>
                            <option value="埼玉県">埼玉県</option>
                            <option value="千葉県">千葉県</option>
                            <option value="東京都">東京都</option>
                            <option value="神奈川県">神奈川県</option>
                            <option value="新潟県">新潟県</option>
                            <option value="富山県">富山県</option>
                            <option value="石川県">石川県</option>
                            <option value="福井県">福井県</option>
                            <option value="山梨県">山梨県</option>
                            <option value="長野県">長野県</option>
                            <option value="岐阜県">岐阜県</option>
                            <option value="静岡県">静岡県</option>
                            <option value="愛知県">愛知県</option>
                            <option value="三重県">三重県</option>
                            <option value="滋賀県">滋賀県</option>
                            <option value="京都府">京都府</option>
                            <option value="大阪府">大阪府</option>
                            <option value="兵庫県">兵庫県</option>
                            <option value="奈良県">奈良県</option>
                            <option value="和歌山県">和歌山県</option>
                            <option value="鳥取県">鳥取県</option>
                            <option value="島根県">島根県</option>
                            <option value="岡山県">岡山県</option>
                            <option value="広島県">広島県</option>
                            <option value="山口県">山口県</option>
                            <option value="徳島県">徳島県</option>
                            <option value="香川県">香川県</option>
                            <option value="愛媛県">愛媛県</option>
                            <option value="高知県">高知県</option>
                            <option value="福岡県">福岡県</option>
                            <option value="佐賀県">佐賀県</option>
                            <option value="長崎県">長崎県</option>
                            <option value="熊本県">熊本県</option>
                            <option value="大分県">大分県</option>
                            <option value="宮崎県">宮崎県</option>
                            <option value="鹿児島県">鹿児島県</option>
                            <option value="沖縄県">沖縄県</option>
                        </Form.Control>
                        {errors.jigyoushoPref && errors.jigyoushoPref.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyoushoPref && errors.jigyoushoPref.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は8文字までです。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>


                <Form.Group as={Row}>
                    <Form.Label column md={{span: 4, offset: 1}}>
                        市区町村<sup style={{color:"red"}}> ※</sup>
                            <RequiredImage /><br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} required type="text" placeholder=" 市区町村" 
                                      className={"form-control"}
                                      {...register("jigyoushoShikuchouson", {required: true, maxLength: 10, pattern: /^[^ -~]+$/})}
                                      isInvalid={!!errors.jigyoushoShikuchouson}/>
                        {errors.jigyoushoShikuchouson && errors.jigyoushoShikuchouson.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyoushoShikuchouson && errors.jigyoushoShikuchouson.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は10文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyoushoShikuchouson && errors.jigyoushoShikuchouson.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            全角で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column md={{span: 4, offset: 1}}>
                        番地以下<sup style={{color:"red"}}> ※</sup>
                            <RequiredImage /><br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} required type="text" placeholder=" 番地以下" 
                                      className={"form-control"}
                                      {...register("jigyoushoBanchi", {required: true, maxLength: 50, pattern: /^[^ -~]+$/})}
                                      isInvalid={!!errors.jigyoushoBanchi}/>
                        {errors.jigyoushoBanchi && errors.jigyoushoBanchi.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyoushoBanchi && errors.jigyoushoBanchi.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は50文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyoushoBanchi && errors.jigyoushoBanchi.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            全角で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column md={{span: 4, offset: 1}}>
                        それ以降の住所<sup style={{color:"red"}}> ※</sup>
                            <br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} required type="text" placeholder="それ以降の住所" 
                                      className={"form-control"}
                                      {...register("jigyoushoIkou", {required: false, maxLength: 50, pattern: /^[^ -~]+$/})}
                                      isInvalid={!!errors.jigyoushoIkou}/>
                        {errors.jigyoushoIkou && errors.jigyoushoIkou.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は50文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.jigyoushoIkou && errors.jigyoushoIkou.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            全角で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>



            <Form.Group as={Row}>
                <Col md={{span: 5}}>
                    <Form.Label>
                        事業所名称<RequiredImage /><br/>
                            <span className="subLabel">(半角(カナ除く)、全角、最大25文字)</span>
                    </Form.Label>
                </Col>
                <Col md={6}>
                    <Form.Control readOnly={readOnly} type="text" placeholder="事業所名称"
                                  className={"form-control"}
                                  {...register("jigyoushoName", {required: true, maxLength: 25, pattern: /^[^ｦ-ﾝ]+$/})}
                                  isInvalid={!!errors.jigyoushoName}/>
                    {errors.jigyoushoName && errors.jigyoushoName.type === "required" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は必須です。
                    </Form.Control.Feedback>
                    }
                    {errors.jigyoushoName && errors.jigyoushoName.type === "maxLength" &&
                    <Form.Control.Feedback type="invalid">
                        この項目は25文字までです。
                    </Form.Control.Feedback>
                    }
                    {errors.jigyoushoName && errors.jigyoushoName.type === "pattern" &&
                    <Form.Control.Feedback type="invalid">
                        半角(カナ除く)、全角で入力してください。
                    </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>





                {/*<Form.Group as={Row}>*/}
                {/*    <Form.Label column md={12}>*/}
                {/*        事業主氏名*/}
                {/*    </Form.Label>*/}
                {/*</Form.Group>*/}
                
                
                {/*<Form.Group as={Row}>*/}
                {/*    <Col md={{span: 4, offset: 1}}>*/}
                {/*        <Form.Label column md={4}>*/}
                {/*            <p>氏<RequiredImage /><br/>*/}
                {/*                <span className="subLabel">(全角25文字)</span></p>*/}
                {/*        </Form.Label>*/}
                {/*    </Col>*/}
                {/*    <Col md={6}>*/}
                {/*        <Form.Control readOnly={true} required type="text" placeholder="氏" */}
                {/*                      className={"form-control"}*/}
                {/*                      {...register("jigyounushiFamilyName", {required: true, maxLength: 25, pattern: /^[^ -~]+$/})}*/}
                {/*                      isInvalid={!!errors.jigyounushiFamilyName}/>*/}
                {/*        {errors.jigyounushiFamilyName && errors.jigyounushiFamilyName.type === "required" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は必須です。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.jigyounushiFamilyName && errors.jigyounushiFamilyName.type === "maxLength" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は25桁までです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.jigyounushiFamilyName && errors.jigyounushiFamilyName.type === "pattern" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は全角のみです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*</Form.Group>*/}
                {/*<Form.Group as={Row}>*/}
                {/*    <Col md={{span: 4, offset: 1}}>*/}
                {/*        <Form.Label column md={4}>*/}
                {/*            <p>名<RequiredImage /><br/>*/}
                {/*                <span className="subLabel">(全角25文字)</span></p>*/}
                {/*        </Form.Label>*/}
                {/*    </Col>*/}
                {/*    <Col md={6}>*/}
                {/*        <Form.Control readOnly={true} required type="text" placeholder="名" */}
                {/*                      className={"form-control"}*/}
                {/*                      {...register("jigyounushiFirstName", {required: true, maxLength: 25, pattern: /^[^ -~]+$/})}*/}
                {/*                      isInvalid={!!errors.jigyounushiFirstName}/>*/}
                {/*        {errors.jigyounushiFirstName && errors.jigyounushiFirstName.type === "required" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は必須です。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.jigyounushiFirstName && errors.jigyounushiFirstName.type === "maxLength" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は25桁までです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.jigyounushiFirstName && errors.jigyounushiFirstName.type === "pattern" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は全角のみです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*</Form.Group>*/}


                {/*<Form.Group as={Row}>*/}
                {/*    <Form.Label column md={{span: 5}}>*/}
                {/*        <p>電話番号<br/>*/}
                {/*            <span className="subLabel">(半角数字、最大5桁 - 最大4桁 - 最大5桁)</span></p>*/}
                {/*    </Form.Label>*/}
                
                {/*    <Col md={2} style={{textAlign: "center"}}>*/}
                {/*        <Form.Control readOnly={true} type="text" placeholder="" style={{width: 75}} */}
                {/*                      {...register("telNo1", {required: false, maxLength: 5, pattern: /^\d+$/})}*/}
                {/*                      isInvalid={!!errors.telNo1}*/}
                {/*        />*/}
                {/*        {errors.telNo1 && errors.telNo1.type === "pattern" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は数字のみです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.telNo1 && errors.telNo1.type === "maxLength" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は5桁までです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*    -*/}
                {/*    <Col md={2} style={{textAlign: "center"}}>*/}
                {/*        <Form.Control readOnly={true} type="text" placeholder="" style={{width: 75}} */}
                {/*                      {...register("telNo2", {required: false, maxLength: 4, pattern: /^\d+$/})}*/}
                {/*                      isInvalid={!!errors.telNo2}*/}
                {/*        />*/}
                {/*        {errors.telNo2 && errors.telNo2.type === "pattern" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は数字のみです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.telNo2 && errors.telNo2.type === "maxLength" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は4桁までです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*    -*/}
                {/*    <Col md={2} style={{textAlign: "center"}}>*/}
                {/*        <Form.Control readOnly={true} type="text" placeholder="" style={{width: 75}}  */}
                {/*                      {...register("telNo3", {required: false, maxLength: 5, pattern: /^\d+$/})}*/}
                {/*                      isInvalid={!!errors.telNo3}*/}
                {/*        />*/}
                {/*        {errors.telNo3 && errors.telNo3.type === "pattern" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は数字のみです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.telNo3 && errors.telNo3.type === "maxLength" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は5桁までです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*</Form.Group>*/}



                {/*<Form.Group as={Row}>*/}
                {/*    <Col md={{span: 4, offset: 1}}>*/}
                {/*        <Form.Label><p>提出年月日<RequiredImage /><br/>*/}
                {/*            <span className="subLabel">(現在の西暦-100年から+10年の範囲)</span></p></Form.Label></Col>*/}
                {/*    <Col><Form.Control disabled={readOnly} type="date" placeholder="申請日" max={plus10year()} min={minus100year()}*/}
                {/*                       defaultValue={FormatDate(ymdMixing(props.value?.applicantsY, props.value?.applicantsM, props.value?.applicantsD), "YYYY-MM-DD")}*/}
                {/*                       {...register("applicantsYMD", {required: true, maxLength: 13})}*/}
                {/*                       isInvalid={!!errors.applicantsYMD}*/}
                {/*    />*/}
                {/*        {errors.applicantsYMD && errors.applicantsYMD.type === "required" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は必須です。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                
                {/*    </Col>*/}
                {/*</Form.Group>*/}




                <Form.Group as={Row}>
                    <Form.Label column md={12}>
                        社会保険労務士の提出代行者名
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column md={{span: 10, offset: 1}}>
                            <span style={{fontSize:"smaller", color:"red"}}>※ 「氏」「名」は合わせて最大40文字で入力してください。</span>
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column md={{span: 4, offset: 1}}>
                        氏<sup style={{color:"red"}}> ※</sup><br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} required type="text" placeholder="氏" 
                                      className={"form-control"}
                                      {...register("sharoushiFamilyName", {required: false, maxLength: 40, pattern: /^[^ -~]+$/})}
                                      isInvalid={!!errors.sharoushiFamilyName}/>
            
                        {errors.sharoushiFamilyName && errors.sharoushiFamilyName.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は40文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.sharoushiFamilyName && errors.sharoushiFamilyName.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            全角で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column md={{span: 4, offset: 1}}>
                        名<sup style={{color:"red"}}> ※</sup><br/>
                            <span className="subLabel">(全角)</span>
                    </Form.Label>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} required type="text" placeholder="名" 
                                      className={"form-control"}
                                      {...register("sharoushiFirstName", {required: false, maxLength: 40, pattern: /^[^ -~]+$/})}
                                      isInvalid={!!errors.sharoushiFirstName}/>

                        {errors.sharoushiFirstName && errors.sharoushiFirstName.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は40文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.sharoushiFirstName && errors.sharoushiFirstName.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            全角で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>



                <Form.Group as={Row}>
                    <Col md={5}><Form.Label>通知書希望形式</Form.Label></Col>
                    <Col>
                        <Form.Check disabled={readOnly} type={"checkbox"} {...register("tsuuchisyoKibou", {})} defaultValue={props.value?.sharoushiFirstName==="ON"?"checked":""} label={"通知書希望形式"} />
                    </Col>
                </Form.Group>






                {/*<Form.Group as={Row}>*/}
                {/*    <Col md={{span: 5}}>*/}
                {/*        <Form.Label>*/}
                {/*            <p>提出元事業所法人番号<RequiredImage />*/}
                
                {/*                <br/>*/}
                {/*                <span className="subLabel">(半角数字、最大13桁)</span></p>*/}
                {/*        </Form.Label>*/}
                {/*    </Col>*/}
                {/*    <Col md={6}>*/}
                {/*        <Form.Control readOnly={true} type="text" placeholder="提出元事業所法人番号" defaultValue={props.value?.corpName}*/}
                {/*                      className={"form-control"}*/}
                {/*                      {...register("corpName", {required: true, minLength:13, maxLength: 13, pattern: /^\d+$/})} isInvalid={!!errors.corpName}/>*/}
                {/*        {errors.corpName && errors.corpName.type === "required" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は必須です。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.corpName && errors.corpName.type === "minLength" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は13桁です。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.corpName && errors.corpName.type === "maxLength" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は13桁です。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*        {errors.corpName && errors.corpName.type === "pattern" &&*/}
                {/*        <Form.Control.Feedback type="invalid">*/}
                {/*            この項目は数値のみです。*/}
                {/*        </Form.Control.Feedback>*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*</Form.Group>*/}


                <Form.Group as={Row}>
                    <Col md={{span: 5}}>
                        <Form.Label>
                            提出先健康保険組合保険者番号<RequiredImage />
                                <br/>
                                <span className="subLabel">(半角数字、8桁)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="提出先健康保険組合保険者番号" 
                                      className={"form-control"}
                                      {...register("destHokenKumiaiNo", {required: true,maxLength: 8, pattern: /^\d{8}$/})} isInvalid={!!errors.destHokenKumiaiNo}/>
                        {errors.destHokenKumiaiNo && errors.destHokenKumiaiNo.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.destHokenKumiaiNo && errors.destHokenKumiaiNo.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は8桁までです。
                        </Form.Control.Feedback>
                        }
                        {errors.destHokenKumiaiNo && errors.destHokenKumiaiNo.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            半角数字、8桁で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>



                <Form.Group as={Row}>
                    <Col md={{span: 5}}>
                        <Form.Label>
                            提出先健康保険組合名称<RequiredImage />
                                <br/>
                                <span className="subLabel">(全角、最大40文字)</span>
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={readOnly} type="text" placeholder="提出先健康保険組合名称" 
                                      className={"form-control"}
                                      {...register("destHokenKumiaiName", {required: true,maxLength: 40, pattern: /^[^ -~]+$/})} isInvalid={!!errors.destHokenKumiaiName}/>
                        {errors.destHokenKumiaiName && errors.destHokenKumiaiName.type === "required" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は必須です。
                        </Form.Control.Feedback>
                        }
                        {errors.destHokenKumiaiName && errors.destHokenKumiaiName.type === "maxLength" &&
                        <Form.Control.Feedback type="invalid">
                            この項目は40文字までです。
                        </Form.Control.Feedback>
                        }
                        {errors.destHokenKumiaiName && errors.destHokenKumiaiName.type === "pattern" &&
                        <Form.Control.Feedback type="invalid">
                            全角で入力してください。
                        </Form.Control.Feedback>
                        }
                    </Col>
                </Form.Group>




                <Form.Group as={Row}>
                    <Col md={5}><Form.Label>添付書類</Form.Label></Col>
                    <Col>
                        <Form.Check disabled={readOnly} type={"checkbox"} {...register("attachedPost", {})} defaultValue={props.value?.attachedPost==="ON"?"checked":""} label={"郵送"} />
                        <Form.Check disabled={readOnly} type={"checkbox"} {...register("attachedE", {})} defaultValue={props.value?.attachedE==="ON"?"checked":""} label={"電子"} />
                        <Form.Check disabled={readOnly} type={"checkbox"} {...register("attachedNone", {})} defaultValue={props.value?.attachedNone==="ON"?"checked":""} label={"なし"} />
                    </Col>
                </Form.Group>

            
            
                <Form.Group as={Row}>
                    <Col md={{span: 5}}>
                        <Form.Label>
                            ステータス
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control readOnly={true} type="text" placeholder={getStringValueFunc(StatusKindMap)(props.value?.statusKind ?? -1)} disabled />
                    </Col>
                </Form.Group>


            <Col md={5}>
                送信ファイル
            </Col>







            <Form.Group as={Row}>
                <Col md={5}><Form.Label>添付書類</Form.Label></Col>
                <Col>
                    <Form.Check disabled={daikouDisable} type="checkbox" {...register("sendAttachFile", {})} label="提出代行証明書の添付を追加"/>
                </Col>
            </Form.Group>

        </div>
    );

}

export default withRouter(ApplicationForms);



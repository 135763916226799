//react 
import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {Col, Container, Form, Row} from "react-bootstrap";


//common
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome, faSave} from "@fortawesome/free-solid-svg-icons";


//local
import {CommonConfiguration} from "../common/CommonSetting";
import {
    GetAccountInfo,
    GetOrganizationInfo,
    InsuranceItem,
    OrganizationApiFp,
    PutOrganizationInfo,
    UserApiFp,
} from "../client-axios";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import OrganizationEdit from "../admin/OrganizationEdit";
import CommonSpinner from "../common/CommonSpinner";
import {levelKindEnumManager, levelKindEnumNotSet} from "../common/CommonDefine";
import ThrottleButton from "../control/ThrottleButton";

interface Props extends RouteComponentProps {
}

const UserRouteOrganization = (props: Props) => {
    document.title = "企業情報編集";

    const { register, handleSubmit, watch, formState: { errors } } = useForm({mode: "onBlur"});
    const methods = useForm({mode: "onBlur"});
    const {trigger} = methods;

    //ダイアログ
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogWithGotoMenu, setDialogWithGotoMenu] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");


    const [isLoadedAccount, setIsLoadedAccount] = useState(false);

    const [insuranceItems, setInsuranceItems] = useState<InsuranceItem[] | undefined>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadData, setLoadData] = useState<GetOrganizationInfo | undefined>();
    
    const [saveConfirm, setSaveConfirm] = useState("");

    const getAccount = async () :Promise<boolean> => {
        const conf = CommonConfiguration();
        const apiGet = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const x : GetAccountInfo = ret.data;
                if (x !== undefined) {
                    //種類ガード
                    if ( (x.levelKind ?? levelKindEnumNotSet) < levelKindEnumManager ) {
                        props.history.push("/menu");
                    }
                }
            }
            return true;
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithGotoMenu("権限がありません");
                return false;
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
            setIsLoadedAccount(true);
        }
        return true;
    }

    
    const getData = async () => {
        const conf = CommonConfiguration();
        const apiGet = await OrganizationApiFp(conf).apiOrganizationGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const org: GetOrganizationInfo = ret.data;
                setLoadData(org);
                setInsuranceItems(org.insuranceList);
                setIsLoaded(true);
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
            setLoadData(undefined);
            setIsLoaded(true);
        }

    };

    useEffect(() => {
        getAccount().then();
    },[]);
    
    useEffect(() => {
        getData().then();
    }, [isLoadedAccount, dialogWithClose]);

    

    const Send = async (data) => {
        const conf = CommonConfiguration();
        const sendData: PutOrganizationInfo =
            {
                id                                 : data.id                              ,
                corpNo                             : data.corpNo                          ,
                corpNameKanji                      : data.corpNameKanji                   ,
                corpNameKana                       : data.corpNameKana                    ,
                corpKind                           : data.corpKind                        ,
                corpKindEtc                        : data.corpKind === "B019_061" ? data.corpKindEtc : "",
                corpKindBA                         : data.corpKindBA                      ,
                insuranceList                      : insuranceItems                   ,
                corpYubin                          : data.corpYubin                       ,
                corpPref                           : data.corpPref                        ,
                corpShikuchouson                   : data.corpShikuchouson                ,
                corpBanchi                         : data.corpBanchi                      ,
                corpIkou                           : data.corpIkou                        ,
                representativeFamilyName           : data.representativeFamilyName        ,
                representativeFirstName            : data.representativeFirstName         ,
                corpTelNo1                         : data.corpTelNo1                      ,
                corpTelNo2                         : data.corpTelNo2                      ,
                corpTelNo3                         : data.corpTelNo3                      ,
                representativeFamilyNameKana       : data.representativeFamilyNameKana    ,
                representativeFirstNameKana        : data.representativeFirstNameKana     ,
                representativeYubin                : data.representativeYubin             ,
                representativePref                 : data.representativePref              ,
                representativeShikuchouson         : data.representativeShikuchouson      ,
                representativeBanchi               : data.representativeBanchi            ,
                representativeIkou                 : data.representativeIkou              ,
                representativeTelNo1               : data.representativeTelNo1            ,
                representativeTelNo2               : data.representativeTelNo2            ,
                representativeTelNo3               : data.representativeTelNo3            ,
                representativeMailAddress          : data.representativeMailAddress       ,

            };
        const apiPut = await OrganizationApiFp(conf).apiOrganizationPut(sendData);
        try {
            const ret = await apiPut();
            if (ret.status === 200) {
                setDialogWithClose("登録しました。");

            } else if (ret.status === 204) {
                setDialogWithClose("登録しました。");
            } else {
            }
        } catch (e) {
            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("更新に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("更新に失敗しました。");
            }
        }
    };

    const Loading = (<CommonSpinner />)

    useEffect(() => {
        register("organizationNumber", { required: true });
    }, []);
    
    
    const onSubmit = (data) => {
        Send(data).then();
    };

    const onSaveDialog = async () :Promise<boolean> => {
        const conf = CommonConfiguration();
        const apiGet = await UserApiFp(conf).apiMeGet();
        try {
            const ret = await apiGet();
            if (ret.status === 200) {
                const x : GetAccountInfo = ret.data;
                if (x !== undefined) {
                    //種類ガード
                    if ( (x.levelKind ?? levelKindEnumNotSet) < levelKindEnumManager ) {
                        setDialogWithClose("権限がありません");
                        return false;
                    }
                }
            }
        } catch (e) {
            //読み取り系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithGotoMenu("権限がありません");
                return false;
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            }
            setIsLoadedAccount(true);
        }
        
        const result = await trigger();
        const errorCount = Object.keys(methods.formState.errors).length;
        if ( errorCount > 0) {

            methods.setFocus(Object.keys(methods.formState.errors)[0]);
            setDialogMessage("項目の入力に誤りがあります。");
            return true;
        }
        if(result) setSaveConfirm("保存しますか？");

        return true;
    }

    const onSave = () => {
        const data = methods.getValues();
        onSubmit(data);
        setSaveConfirm("");
    }

    const onCancel = () => {
        setSaveConfirm("");
    }
    
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogMessage("");
        setDialogWithClose("");
        props.history.push("/menu");
    }

    const onCloseAndGotoMenu = () => {
        setDialogMessage("");
        setDialogWithGotoMenu("");
        props.history.push("/menu");
    }

    const loaded = (
        <main role="main" className="flex-shrink-0">
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}
            {dialogWithGotoMenu !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoMenu} value={dialogWithGotoMenu} style={DialogStyle.okOnly}/>}

            <Container fluid={"xl"}>
                <h1>企業情報編集</h1>
                <FormProvider {...methods}>
                    <Form noValidate>
                        <OrganizationEdit isHiddenDisplayProvider={true} onGotoMenu={onCloseAndGotoList}
                                           value={loadData}
                                          insuranceItems={insuranceItems}
                                          setInsuranceItems={setInsuranceItems}
                        />

                        <Form.Group as={Row}>
                            <Col xs={12}>
                                <ThrottleButton variant="secondary" onClick={onCloseAndGotoList}>メニューに戻る</ThrottleButton>
                                <ThrottleButton onClick={onSaveDialog} style={{position: "absolute", right: 0, width: 200}}>保存</ThrottleButton>
                            </Col>
                        </Form.Group>
                        {saveConfirm !== "" && <CommonDialogBox value={saveConfirm} style={DialogStyle.yesNo} onClickYes={onSave} onClickNo={onCancel} />}

                    </Form>
                </FormProvider>
            </Container>
        </main>
    )

    return (
        <div role="main" className="flex-shrink-0">
            {(isLoaded ? loaded : Loading)}
        </div>
    )

}

export default withRouter(UserRouteOrganization);


// node
import {Dispatch} from "redux";

// common


export interface GlobalState{
    globalLanguage:string
    globalAceessToken:string,
    globalAuthorizationLevel?:number,
    globalSearchKey?:string
}
interface Action<T>{
    type:string,
    value?:T
}
export function setGlobalLanguage(text :string) :Action<string> {
    console.log("SET_LANGUAGE:" + text);
    return {
        type: "SET_LANGUAGE",
        value: text
    };
}
export function setAccessToken(token? :string) :Action<string> {
    console.log("SET_ACCESSTOKEN:" + token);
    return {
        type: "SET_ACCESSTOKEN",
        value: token
    };
}

export function setAuthorizationLevel(v? :number) :Action<number> {
    console.log("SET_AUTHORIZATION_LEVEL:" + v);
    return {
        type: "SET_AUTHORIZATION_LEVEL",
        value: v
    };
}

export function setSearchKey(searchKey?: string) :Action<string> {
    console.log("SET_SEARCHKEY" + searchKey);
    return {
        type: "SET_SEARCHKEY",
        value: searchKey
    };
}

export interface IStateToProps{
    globalLanguage: string,
    globalAceessToken?:string,
    globalAuthorizationLevel?:number,
    globalSearchKey?: string
}
export interface IDispatchToProps{
    setGlobalLanguage: (v: string) => void;
    setAccessToken: (v?: string) => void;
    setAuthorizationLevel: (v?: number) => void;
    setSearchKey: (v?: string) => void;
}

export const mapStateToProps = (state: GlobalState) : IStateToProps => {
    return {
        globalLanguage: state.globalLanguage,
        globalAceessToken: state.globalAceessToken,
        globalAuthorizationLevel: state.globalAuthorizationLevel,
        globalSearchKey: state.globalSearchKey
    };
};
 

export const mapDispatchToProps = (dispatch: Dispatch) :IDispatchToProps => {
    return {
        setGlobalLanguage: (v: string) => dispatch(setGlobalLanguage(v)),
        setAccessToken: (v?: string) => dispatch(setAccessToken(v)),
        setAuthorizationLevel: (v?: number) => dispatch(setAuthorizationLevel(v)),
        setSearchKey: (v?: string) => dispatch(setSearchKey(v))
    };
};

//react 
import React, {useEffect, useState} from "react";


//common
import {v4 as uuid} from "uuid";


//local
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import ApplicationFileRow from "./ApplicationFileRow";
import CommonFileDropRow, {kindFile} from "../control/CommonFileDropRow";
import {Button, Col, Row} from "react-bootstrap";
import {getExtension} from "../common/CommonValidation";
import ThrottleButton from "../control/ThrottleButton";


export interface ApplicationFileInterface {
    id: string,
    fileName: string,
    extension: string,
    binary: string,

}

interface Props {
    value? : ApplicationFileInterface[],
    onUpdate: (v :  ApplicationFileInterface[]) => void,
    disabled: boolean,
}


const ApplicationFilesForm = (props: Props) => {

    const [filename, setFileName] = useState<string | undefined>();
    const [binary, setBinary] = useState<string | undefined>();
    const [itemCount, setItemCount] = useState<number>(0);


    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogWithClose, setDialogWithClose] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogWithClose("");
    }


    const onDelete = (id) => {
        if (props.value == null) {
            return;
        }
        const newItems = props.value.filter(x => x.id !== id);
        props.onUpdate(newItems);
    }

    const onAddClick = () => {
        if (filename == null || binary == null) {
            setDialogMessage("ファイルが選択されていません。");
            return;
        }
        const item: ApplicationFileInterface = {
            id: uuid(),
            fileName: filename,
            binary: binary,
            extension: getExtension(filename),
        }
        let newItems = new Array<ApplicationFileInterface>();

        if (props.value != null) {
            newItems = props.value;
        }
        let double = false;
        if(itemCount !== 0)
        {
            newItems.find((x) => {
                if (x.fileName.toLocaleLowerCase() === item.fileName.toLocaleLowerCase()) {
                    setDialogMessage("ファイル名が重複しています。");
                    double = true;
                }
            });
        }
        if(!double)
        {
            newItems.push(item);
            setItemCount(itemCount + 1);
        }
        props.onUpdate(newItems);
        setBinary(undefined);
        setFileName(undefined);
    }
    
    return (
        <div className="sendingFile">
            <h3>送信ファイル</h3>
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}

            <table className="table">
                <thead>
                <tr>
                    <th>ファイル名</th>
                    <th>種類</th>
                    {/*<th>編集</th>*/}
                    <th>削除</th>
                </tr>
                </thead>
                <tbody>
                {props?.value != null && props?.value.map(x => {
                    return <ApplicationFileRow key={x.id} value={x} onDelete={onDelete}/>
                })}

                </tbody>
            </table>
            <CommonFileDropRow disabled={props.disabled} fileName={filename} accept={".pdf,.xlsx,.xls,.docx,.doc,.pptx,.ppt,.xml,.csv,.txt,.jpeg,.jpg,.gif,.png,.tiff,.tif"} kind={kindFile.SendFile} OnSetFileBinary={setBinary} OnSetFileName={setFileName} />
            <Row style={{textAlign: "center"}}>
                <Col xs={12}>
                    <Button onClick={onAddClick} disabled={(binary == null || binary === "" || props.disabled)}>追加</Button>
                </Col></Row>
        </div>
    )


}

export default (ApplicationFilesForm);


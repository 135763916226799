//react
import React, {useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Col, Container, Form, Row} from "react-bootstrap";

import {FormProvider, useForm} from "react-hook-form";
//common
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";


//local
import {CommonConfiguration} from "../common/CommonSetting";
import {AdminApiFp, GetOrganizationInfo, InsuranceItem, PostAdminOrganizationInfo} from "../client-axios";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import OrganizationEdit from "./OrganizationEdit";
import {faCaretSquareUp} from "@fortawesome/free-solid-svg-icons/faCaretSquareUp";
import {ProviderEnumAad} from "../common/CommonDefine";
import ThrottleButton from "../control/ThrottleButton";


interface Props extends RouteComponentProps {
}

const AdminRouteCreateOrganization = (props: Props) => {
    document.title = "企業情報登録"
    const methods = useForm({mode: "onBlur"});
    const {trigger} = methods;

    //ダイアログ
    const [dialogWithClose, setDialogWithClose] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");


    const [insuranceItems, setInsuranceItems] = useState<InsuranceItem[] | undefined>();
    const [confirmDialog, setConfirmDialog] = useState("");

    const onSaveDialog = async () => {
        const result = await trigger()
        const errorCount = Object.keys(methods.formState.errors).length;
        if ( errorCount > 0) {

            methods.setFocus(Object.keys(methods.formState.errors)[0]);
            setDialogMessage("項目の入力に誤りがあります。");
            return;
        }
        if(result) setConfirmDialog("保存しますか？");
    }
    
    const onCancel = () => {
        setConfirmDialog("");
    }
    
    const onSubmit = async () => {

        const data = methods.getValues();
        const conf = CommonConfiguration();
        const sendData: PostAdminOrganizationInfo =
            {
                corpNo: data.corpNo,
                corpNameKanji: data.corpNameKanji,
                corpNameKana: data.corpNameKana,
                corpKind: data.corpKind,
                corpKindEtc: data.corpKind === "B019_061" ? data.corpKindEtc : "",
                corpKindBA: data.corpKindBA,
                provider: data.provider,
                clientId: data.clientId,
                signInUrl: data.signInUrl,
                corpYubin: data.corpYubin,
                corpPref: data.corpPref,
                corpShikuchouson: data.corpShikuchouson,
                corpBanchi: data.corpBanchi,
                corpIkou: data.corpIkou,
                representativeFamilyName: data.representativeFamilyName,
                representativeFirstName: data.representativeFirstName,
                corpTelNo1: data.corpTelNo1,
                corpTelNo2: data.corpTelNo2,
                corpTelNo3: data.corpTelNo3,
                representativeFamilyNameKana: data.representativeFamilyNameKana,
                representativeFirstNameKana: data.representativeFirstNameKana,
                representativeYubin: data.representativeYubin,
                representativePref: data.representativePref,
                representativeShikuchouson: data.representativeShikuchouson,
                representativeBanchi: data.representativeBanchi,
                representativeIkou: data.representativeIkou,
                representativeTelNo1: data.representativeTelNo1,
                representativeTelNo2: data.representativeTelNo2,
                representativeTelNo3: data.representativeTelNo3,
                representativeMailAddress: data.representativeMailAddress,
                insuranceList: insuranceItems

            };
        const apiPost = await AdminApiFp(conf).apiAdminOrganizationPost(sendData);
        try {
            const ret = await apiPost();
            setDialogWithClose("登録しました。");
        } catch (e) {

            //更新系例外
            if (e instanceof Error && e.message === "Network Error") {
                setDialogWithClose("通信タイムアウトが発生しました。しばらくして再度実行してください。");
            } else if (e.response.status === 400 || e.response.status === 500) {
                setDialogWithClose(`サーバーエラーが発生しました（${e.response.data}）。システム担当者へご連絡ください。`);
            } else if (e.response.status === 422) {
                setDialogMessage("項目の入力に誤りがあります。" + e.response.data);
            } else if (e.response.status === 404) {
                setDialogWithClose("登録に失敗しました。");
            } else if (e.response.status === 405) {
                window.location.href = "/timeout";
            } else {
                setDialogWithClose("登録に失敗しました。");
            }

        }
        setConfirmDialog("");
    };
    const onDialogOk = () => {
        setDialogMessage("");
    }
    const onCloseAndGotoList = () => {
        setDialogMessage("");
        setDialogWithClose("");
        props.history.push("/admin/organizationlist");
    }
    
    const defaultValue: GetOrganizationInfo = {
        corpBanchi: "",
        corpIkou: "",
        corpKind: "",
        corpKindBA: "B020_001",
        corpKindEtc: "",
        corpNameKana: "",
        corpNameKanji: "",
        corpNo: "",
        corpPref: "",
        corpShikuchouson: "",
        corpTelNo1: "",
        corpTelNo2: "",
        corpTelNo3: "",
        corpYubin: "",
        id: "",
        insuranceList: undefined,
        representativeBanchi: "",
        representativeFamilyName: "",
        representativeFamilyNameKana: "",
        representativeFirstName: "",
        representativeFirstNameKana: "",
        representativeIkou: "",
        representativeMailAddress: "",
        representativePref: "",
        representativeShikuchouson: "",
        representativeTelNo1: "",
        representativeTelNo2: "",
        representativeTelNo3: "",
        representativeYubin: "",
        signInUrl: "",
        provider: ProviderEnumAad
    }
    
    return (
        <main role="main" className="flex-shrink-0">
            {dialogMessage !== "" && <CommonDialogBox value={dialogMessage} onClickOK={onDialogOk}/>}
            {dialogWithClose !== "" &&
            <CommonDialogBox onClickOK={onCloseAndGotoList} value={dialogWithClose} style={DialogStyle.okOnly}/>}


            <Container fluid={"xl"}>
                <h1>企業情報登録</h1>
                <FormProvider {...methods}>
                    <Form noValidate>
                        
                        <OrganizationEdit isHiddenDisplayProvider={false} onGotoMenu={onCloseAndGotoList}
                                          value={defaultValue}
                                          insuranceItems={insuranceItems}
                                          setInsuranceItems={setInsuranceItems}
                        />

                        <Form.Group as={Row}>
                            <Col xs={12}>
                                <ThrottleButton variant="secondary" onClick={onCloseAndGotoList}>企業一覧に戻る</ThrottleButton>
                                <ThrottleButton onClick={onSaveDialog} style={{position: "absolute", right: 0, width: 200}}>保存</ThrottleButton>
                            </Col>
                        </Form.Group>


                    </Form>
                    {confirmDialog !== "" && <CommonDialogBox value={confirmDialog} style={DialogStyle.yesNo} onClickYes={onSubmit} onClickNo={onCancel} />}
                </FormProvider>
            </Container>
        </main>
    )
}

export default withRouter(AdminRouteCreateOrganization)

//react 
import React, {useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";

//common
import {v4 as uuid} from "uuid";
//local
import {
    GetInsuranceMasterInfo,
    InsuranceItem
} from "../client-axios";
import CommonDialogBox, {DialogStyle} from "../common/CommonDialogBox";
import InsuranceSelectBox from "./InsuranceSelectBox";
import RequiredImage from "../control/RequiredImage";
import ThrottleButton from "../control/ThrottleButton";


interface Props {
    onUpdate: (item : InsuranceItem) => void
    onClose: () => void,
    value?:InsuranceItem
}


const InsuranceEditBox = (props: Props) => {


    //ダイアログ
    const [dialogMessage, setDialogMessage] = useState("");
    const onDialogOk = () => {
        setDialogMessage("");
    }
    
    const methods = useForm({mode: "onBlur"});
    const {register, trigger, formState: {errors}, getValues, setValue } = methods;

    //編集項目
    const [editInsuranceRow, setEditInsuranceRow] = useState<GetInsuranceMasterInfo | undefined>(undefined);


    const onSaveInsurance = async (data) => {
        const result = await trigger();
        if(result) {
            const row = methods.getValues("row");
            setEditInsuranceRow(row);
            if (row == null) {
                setDialogMessage("保険組合を選択して下さい");
            } else {
                const i: InsuranceItem = {
                    id : props.value ?.id == null ? uuid() : props.value.id,
                    jigyousyoSeirikigouPref: methods.getValues("jigyousyoSeirikigouPref"),
                    jigyousyoSeirikigouGunshiku: methods.getValues("jigyousyoSeirikigouGunshiku"),
                    jigyousyoSeirikigouKigou: methods.getValues("jigyousyoSeirikigouKigou"),
                    jigyousyoSeirikigouBangou: methods.getValues("jigyousyoSeirikigouBangou"),
                    destinationId: row.destinationId,
                    destinationName: row.destinationName,
                    destinationExtra1: row.destinationExtra1,
                }
                props.onUpdate(i)
            }
            return false;
        }
    }


    const onInsuranceSelected = (row: GetInsuranceMasterInfo | undefined) => {
        setEditInsuranceRow(row);
        methods.setValue("row", row);
    }

    const onHide = () => {
        return false
    }

    const Loaded = (

        <Modal size={"xl"} show={true} onHide={onHide} className={"CommonDialogBox"} style={{height: "100%"}}>


            <Form noValidate>
                {dialogMessage !== "" &&
                <CommonDialogBox key="InsuranceEditBoxA" onClickOK={onDialogOk} value={dialogMessage}
                                 style={DialogStyle.okOnly}/>}

                {/*<Modal.Header>*/}
                {/*    <Modal.Title>新規作成</Modal.Title>*/}
                {/*</Modal.Header>*/}
                <Modal.Body className="dialogStyle">
                    <div className="bodyStyle" style={{height: "80%", maxHeight: "600px", overflowY: "scroll", overflowX: "hidden"}}>
                        <InsuranceSelectBox onSelected={onInsuranceSelected}/>

                        <Form.Group as={Row}>
                            <p></p>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                事業所整理記号都道府県コード<RequiredImage /><br/>
                                    <span className="subLabel">(半角(カナ除く)、全角、2文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control required type="text" placeholder="事業所整理記号都道府県コード"
                                              className={"form-control"} defaultValue={props.value?.jigyousyoSeirikigouPref}
                                              {...register("jigyousyoSeirikigouPref", {
                                                  required: true,
                                                  maxLength: 2,
                                                  pattern: /^[^ｦ-ﾝ]{2}$/
                                              })}
                                              isInvalid={!!errors.jigyousyoSeirikigouPref}/>
                                {errors.jigyousyoSeirikigouPref && errors.jigyousyoSeirikigouPref.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                                {errors.jigyousyoSeirikigouPref && errors.jigyousyoSeirikigouPref.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は2文字までです。
                                </Form.Control.Feedback>
                                }
                                {errors.jigyousyoSeirikigouPref && errors.jigyousyoSeirikigouPref.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    半角(カナ除く)、全角、2文字で入力してください。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                事業所整理記号郡市区記号<RequiredImage /><br/>
                                    <span className="subLabel">(半角(カナ除く)、全角、2文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control required type="text" placeholder="事業所整理記号郡市区記号"
                                              className={"form-control"}  defaultValue={props.value?.jigyousyoSeirikigouGunshiku}
                                              {...register("jigyousyoSeirikigouGunshiku", {
                                                  required: true,
                                                  maxLength: 2,
                                                  pattern: /^[^ｦ-ﾝ]{2}$/
                                              })}
                                              isInvalid={!!errors.jigyousyoSeirikigouGunshiku}/>
                                {errors.jigyousyoSeirikigouGunshiku && errors.jigyousyoSeirikigouGunshiku.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                                {errors.jigyousyoSeirikigouGunshiku && errors.jigyousyoSeirikigouGunshiku.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は2文字までです。
                                </Form.Control.Feedback>
                                }
                                {errors.jigyousyoSeirikigouGunshiku && errors.jigyousyoSeirikigouGunshiku.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    半角(カナ除く)、全角、2文字で入力してください。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                事業所整理記号事業所記号<RequiredImage /><br/>
                                    <span className="subLabel">(半角(カナ除く)、全角、最大4文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control required type="text" placeholder="事業所整理記号事業所記号"
                                              className={"form-control"}  defaultValue={props.value?.jigyousyoSeirikigouKigou}
                                              {...register("jigyousyoSeirikigouKigou", {
                                                  required: true,
                                                  maxLength: 4,
                                                  pattern: /^[^ｦ-ﾝ]+$/
                                              })}
                                              isInvalid={!!errors.jigyousyoSeirikigouKigou}/>
                                {errors.jigyousyoSeirikigouKigou && errors.jigyousyoSeirikigouKigou.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                                {errors.jigyousyoSeirikigouKigou && errors.jigyousyoSeirikigouKigou.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は4文字までです。
                                </Form.Control.Feedback>
                                }
                                {errors.jigyousyoSeirikigouKigou && errors.jigyousyoSeirikigouKigou.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    半角(カナ除く)、全角で入力してください。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row}>
                            <Form.Label column md={5}>
                                事業所整理記号事業所番号<RequiredImage /><br/>
                                    <span className="subLabel">(半角数値、最大5文字)</span>
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control required type="text" placeholder="事業所整理記号事業所番号"
                                              className={"form-control"}  defaultValue={props.value?.jigyousyoSeirikigouBangou}
                                              {...register("jigyousyoSeirikigouBangou", {
                                                  required: true,
                                                  maxLength: 5,
                                                  pattern: /^\d+$/
                                              })}
                                              isInvalid={!!errors.jigyousyoSeirikigouBangou}/>
                                {errors.jigyousyoSeirikigouBangou && errors.jigyousyoSeirikigouBangou.type === "required" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は必須です。
                                </Form.Control.Feedback>
                                }
                                {errors.jigyousyoSeirikigouBangou && errors.jigyousyoSeirikigouBangou.type === "maxLength" &&
                                <Form.Control.Feedback type="invalid">
                                    この項目は5文字までです。
                                </Form.Control.Feedback>
                                }
                                {errors.jigyousyoSeirikigouBangou && errors.jigyousyoSeirikigouBangou.type === "pattern" &&
                                <Form.Control.Feedback type="invalid">
                                    半角数字で入力してください。
                                </Form.Control.Feedback>
                                }
                            </Col>
                        </Form.Group>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <footer className="footStyle">
                        <Row>
                            <Col xs={12}>
                                <Button variant={"secondary"} onClick={props.onClose}>戻る</Button>
                                <ThrottleButton style={{position: "absolute", right: 0}} onClick={onSaveInsurance} variant={"primary"}>OK</ThrottleButton>
                            </Col>
                        </Row>
                    </footer>
                </Modal.Footer>
            </Form>
        </Modal>
    );

    return (Loaded);


}


export default (InsuranceEditBox);

